import { Reseller } from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { Moment } from 'moment';
import { getApiv14 } from './apiv14Setup';

export interface ResellerAddRequest {
  name: string;
  apiKey: string;
  passwordResetSubjectTemplate?: string;
  passwordResetMessageTemplate?: string;
}
export const bulkPurchase = (
  body: {
    institution: string;
    forceRepurchase: boolean;
    allClouds?: boolean;
    clouds?: string[];
    currencyCode: string;
    priceIncGst: number;
    purchaseDate: string | Moment;
    bookExpireDate: string | Moment;
  },
  apiKey: string
) =>
  getApiv14().post('/reseller/bulk_purchase', body, {
    timeout: 60000,
    params: {
      apiKey,
    },
  });

export const addReseller = (req: ResellerAddRequest) =>
  getApiv14().post('/add/reseller', req);
export const deleteReseller = (name: string) =>
  getApiv14().delete('/delete/reseller', { data: { name } });

export const getReseller = (resellerId: string, config?: AxiosRequestConfig) =>
  getApiv14().get('/reseller/' + resellerId, config);

export const addReseller2 = (
  req: ResellerAddRequest,
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/reseller', [req], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const updateReseller = (
  reseller: Partial<Reseller>,
  config?: AxiosRequestConfig
) =>
  getApiv14().put('/reseller', [reseller], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const deleteReseller2 = (
  resellerId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().delete('/reseller/' + resellerId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
