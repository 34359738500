import { DTO } from '@readcloud/common';
import { getApiv14 } from './apiv14Setup';

export const addBookmark = (req: DTO.CreateBookmark) =>
  getApiv14().post('/add/bookmark', req);

export const listBookmarks = () => getApiv14().get('/list/bookmark.json');

export const deleteBookmark = (id: string) =>
  getApiv14().delete('/delete/bookmark', { params: { id } });

export const deltaBookmarks = (millisec: number) =>
  getApiv14().get('/delta/bookmarks', { params: millisec });
