import C from 'classnames';
import * as React from 'react';
import { TestingProps } from '../../Types/Testing';

interface IModalBodyProps extends TestingProps {
  className?: string;
}

export const ModalBody: React.FunctionComponent<IModalBodyProps> = (props) => {
  return (
    <div
      className={C('modal-body', props.className)}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  );
};
