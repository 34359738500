import C from 'classnames';
import * as React from 'react';
import { TestingProps } from '../../Types/Testing';

interface ILabelProps extends TestingProps {
  className?: string;
  htmlFor?: string;
  required?: boolean;
}

export const Label: React.FunctionComponent<ILabelProps> = ({
  children,
  className,
  testId,
  htmlFor,
  required,
}) => {
  return (
    <label
      className={C(className && className, 'font-weight-bold', {
        required: required,
      })}
      htmlFor={htmlFor}
      data-testid={testId}
    >
      {children}
    </label>
  );
};
