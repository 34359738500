import { Tag } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetConnectedTagsThunkAction } from './thunk';
import { TagsState } from './types';

const initialState: TagsState = {
  tags: [],
};

const name = 'tags';

const asyncActions = {
  getTags: createAsyncThunk(`${name}/getTags`, GetConnectedTagsThunkAction),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setTags(state, action: PayloadAction<Tag[]>) {
      state.tags = action.payload;
    },
    addTags(state, action: PayloadAction<Tag[]>) {
      state.tags.push(...action.payload);
    },
    deltaTags(state, action: PayloadAction<Tag[]>) {
      //keep track of tag we're updating.
      const updatedTagIds = [];

      action.payload.forEach((newUpdatedTag) => {
        //find and replace.
        const index = state.tags.findIndex(
          (tag) => tag.id === newUpdatedTag.id
        );
        if (index >= 0) {
          if (!newUpdatedTag.deleted) {
            //replace tag
            state.tags[index] = newUpdatedTag;
          } else {
            //delete tag
            state.tags.splice(index, 1);
          }
          updatedTagIds.push(newUpdatedTag.id);
        }
      });

      //filter the annos we have already updated.
      const newTags = action.payload.filter(
        (tag) => !updatedTagIds.includes(tag.id) && !tag.deleted
      );

      //add the rest to state.
      state.tags.unshift(...newTags);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getTags.fulfilled, (state, action) => {
      state.tags = action.payload;
    });
  },
});

const { actions, reducer } = slice;

export const tagsReducer = reducer;

export const tagsActions = { ...actions, asyncActions };
