import { captureException } from '@sentry/react';
/**
 * Use this to do something that might fail, and if it does, record the error to AWS.
 * This will catch all errors then continue on, so if you want to do something with the error that is caught AFTER it is sent to aws, use the `catcher` param.
 */
export function tryCatchAndNotify<ReturnType>(
  tryer: () => ReturnType,
  catcher?: (e: unknown) => ReturnType
) {
  try {
    return tryer();
  } catch (e) {
    recordError(e);
    if (catcher) {
      return catcher(e);
    }
  }
}

export function recordError(e: unknown) {
  captureException(e);
}
