import {
  UserAddOrUpdateRequest,
  UserAddRequest,
  UserUpdateRequest,
} from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

/*export interface UserAddRequest {
  email: string;
  firstName: string;
  institution: string;
  lastName: string;
  parentEmail?: string;
  password: string;
  role: UserRole;
  yearLevel?: string;
}*/

export const resellerUpdateUser = (
  user: UserUpdateRequest,
  apiKey: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().post(`/reseller/update/user`, user, {
    ...config,
    params: {
      ...(config ? config.params : {}),
      apiKey,
    },
  });

export const resellerCreateUser = (
  user: UserUpdateRequest,
  apiKey: string,
  config?: AxiosRequestConfig
) => resellerUpdateUser({ ...user, contentAction: 'add' }, apiKey, config);

export const resellerPermDeleteUser = (
  params: {
    email: string;
    apiKey: string;
    institution: string;
  },
  config?: AxiosRequestConfig
) =>
  getApiv14().post(
    '/reseller/update/user',
    {
      email: params.email,
      institution: params.institution,
      deleted: 'permanent',
    },
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
        apiKey: params.apiKey,
      },
    }
  );

export const addUser = (req: UserAddRequest, config?: AxiosRequestConfig) =>
  getApiv14().post('/add/user', req, config);

export const addUser2 = (
  req: UserAddOrUpdateRequest,
  config?: AxiosRequestConfig
) => getApiv14().post('/user', [req], config);

export const updateUser = (
  req: UserAddOrUpdateRequest,
  config?: AxiosRequestConfig
) => getApiv14().patch('/user/' + req.id, req, config);

export const deleteUser2 = (uid: string, config?: AxiosRequestConfig) =>
  getApiv14().delete('/user/' + uid, config);

export const resetPollingData = (uid: string, config?: AxiosRequestConfig) =>
  getApiv14().post('/user/' + uid + '/reset_polling_data', null, config);

export const updateUserEmail = (
  userId: string,
  newEmail: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().post(
    '/user/' + userId + '/change_email?newEmail=' + newEmail,
    {},
    config
  );

export const updateUserInstitution = (
  userId: string,
  oldInstitutionId: string,
  newInstitutionId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().post(
    '/user/' +
      userId +
      '/change_institution?oldInstitutionId=' +
      oldInstitutionId +
      '&newInstitutionId=' +
      newInstitutionId,
    {},
    config
  );

export const listUsers = (config?: AxiosRequestConfig) =>
  getApiv14().get('/list/user.json', config);

export const deleteUser = (
  email: string,
  permanent: boolean = false,
  config?: AxiosRequestConfig
) =>
  getApiv14().delete('/delete/user', { data: { email, permanent }, ...config });

export const searchUser = (
  institution: string,
  searchKey: string,
  searchString: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/search/user', {
    params: { institution, searchKey, searchString },
    ...config,
  });

export const searchUserAdvanced = (
  req: {
    limit: number;
    offset: number;
    order: string;
    root: any;
  },
  config?: AxiosRequestConfig,
  async?: boolean
) =>
  getApiv14().post(
    '/search/advanced/user' + (async ? '?async=true' : ''),
    req,
    config
  );

export const getUserInstitutions = (config?: AxiosRequestConfig) =>
  getApiv14().get('/institution/all', config);

export const searchUserActivity = (
  req: {
    limit: number;
    offset: number;
    order: string;
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/user_activity', req, config);

export const searchUserDevices = (
  institutionId: string,
  userId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/user_device/all', {
    params: { institutionId, userId },
    ...config,
  });

export const deleteUserDevices = (
  deviceIds: { id: string }[],
  config?: AxiosRequestConfig
) => getApiv14().delete('/user_device', { data: deviceIds, ...config });

export const searchEnrolmentAdvanced = (
  req: {
    limit: number;
    offset: number;
    order: string;
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/enrolment', req, config);
