import {
  CreateReactivation,
  Reactivation,
  UpdateReactivation,
} from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export const getReactivations = (
  institutionId?: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get(
    `/reactivation/all${institutionId ? '?institutionId' + institutionId : ''}`,
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
      },
    }
  );
export const getReactivation = (
  reactivationId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/reactivation/' + reactivationId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const createReactivations = (
  reactivations: CreateReactivation[],
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/reactivation', reactivations, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const createReactivation = (
  reactivation: Reactivation,
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/reactivation', [reactivation], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const updateReactivations = (
  reactivations: UpdateReactivation[],
  config?: AxiosRequestConfig
) =>
  getApiv14().patch('/reactivation', reactivations, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const updateReactivation = (
  reactivation: Reactivation,
  config?: AxiosRequestConfig
) =>
  getApiv14().patch('/reactivation', [reactivation], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const removeReactivation = (
  reactivationsId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().delete('/reactivation/' + reactivationsId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
