import { QuotableItem } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { QuotableItemState } from './types';

import {
  AddQuotableItemThunkAction,
  DeleteQuotableItemThunkAction,
  GetQuotableItemsThunkAction,
  UpdateQuotableItemThunkAction,
} from './thunk';

const initialState: QuotableItemState = {
  items: {},
};

const name = 'quotable_item';

const asyncActions = {
  getQuotableItems: createAsyncThunk(
    `${name}/get`,
    GetQuotableItemsThunkAction
  ),
  addQuotableItem: createAsyncThunk(`${name}/add`, AddQuotableItemThunkAction),
  updateQuotableItem: createAsyncThunk(
    `${name}/update`,
    UpdateQuotableItemThunkAction
  ),
  deleteQuotableItem: createAsyncThunk(
    `${name}/delete`,
    DeleteQuotableItemThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setQuotableItems(
      state,
      action: PayloadAction<Record<string, QuotableItem>>
    ) {
      state.items = action.payload;
    },
    addQuotableItems(
      state,
      action: PayloadAction<Record<string, QuotableItem>>
    ) {
      state.items = {
        ...state.items,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.addQuotableItem.fulfilled, (state, action) => {
      state.items = { ...state.items, [action.payload.id]: action.payload };
    });
    builder.addCase(
      asyncActions.getQuotableItems.fulfilled,
      (state, action) => {
        state.items = action.payload;
      }
    );
    builder.addCase(
      asyncActions.deleteQuotableItem.fulfilled,
      (state, action) => {
        delete state.items[action.payload];
      }
    );
    builder.addCase(
      asyncActions.updateQuotableItem.fulfilled,
      (state, action) => {
        state.items[action.payload.id] = {
          ...state.items[action.payload.id],
          ...action.payload,
        };
      }
    );
  },
});

const { actions, reducer } = slice;

export const quotableItemsReducer = reducer;

export const quotableItemsActions = { ...actions, asyncActions };
