const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

export const GetEffectiveOrderItemsThunkAction = async (
  payload: string,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/v14/grouped_order_items/' + payload, {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response.json().then(({ result }) => result));
};

export const GetTransactionOrderItemsThunkAction = async (
  payload: string,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(
    absolutePath + '/api/v14/grouped_order_items_ex/' + payload,
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then((response) => response.json().then(({ result }) => result));
};
