import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export const processProductsAPI = (
  resellerId?: string,
  config?: AxiosRequestConfig
) => {
  return getApiv14().post(
    '/product/process',
    { resellerId: resellerId },
    config
  );
};

export const getProductsAPI = (
  offset?: number,
  limit?: number,
  config?: AxiosRequestConfig
) => {
  const buildRequest = {
    limit: limit,
    offset: offset * limit,
    order: '-updated',
    root: {
      joinMethod: 'and',
      children: [
        {
          key: 'mainVariantId',
          op: 'undefined',
          negate: true,
        },
      ],
    },
  };
  return getApiv14().post('/search/advanced/product', buildRequest, config);
};

export const getProductWithQuotableItemAPI = (
  id: string,

  config?: AxiosRequestConfig
) => {
  const buildRequest = {
    limit: 1000,
    offset: 0,
    order: '-updated',
    root: {
      joinMethod: 'and',
      children: [
        {
          key: 'variants.quotableItem',
          op: 'Equals',
          valueType: 'ObjectId',
          value: id,
        },
      ],
    },
  };
  return getApiv14().post('/search/advanced/product', buildRequest, config);
};

export const getProducts = (config?: AxiosRequestConfig) => {
  return getApiv14().get('/products/all', config);
};
