import { applicationActions } from '../../reader-features';

const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

export const GetConnectedAppStatesThunkAction = async (
  payload: {
    filterBookIds?: string[];
    userEmail?: string;
  },
  { dispatch, getState }
) => {
  /* replace the options of the required resource with following: 
    {
        get: true,
        offset: payload.offset,
        limit: payload.limit,
        order: '-updated',
    }
    */

  const getAllQueryOpts = {
    upToVersion: '3',
    userOpts: { get: false },
    bookOpts: { get: false },
    annotationOpts: { get: false },
    bookmarkOpts: { get: false },
    cloudOpts: { get: false },
    commentOpts: { get: false },
    tagOpts: { get: false },
    appStateOpts: { get: true },
    filterBookIds: payload.filterBookIds,
    userEmail: payload.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/rc/v14/get/all', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(getAllQueryOpts),
  }).then((response) =>
    response.json().then(({ result }) => {
      dispatch(applicationActions.setAllServerMilliSec(result?.serverMillisec));
      return result?.appStates;
    })
  );
};
