import { PlatformType } from '../types/models/bridge';

export function isTouchDevice() {
  return (
    !!(
      typeof window !== 'undefined' &&
      ('ontouchstart' in window ||
        ((window as any).DocumentTouch &&
          typeof document !== 'undefined' &&
          document instanceof (window as any).DocumentTouch))
    ) || !!(typeof navigator !== 'undefined' && navigator.maxTouchPoints)
  );
}

export const isIOS = () =>
  !!navigator.platform && /ipad|iphone|ipod/i.test(navigator.platform);

export const isAndroid = () => /android/i.test(navigator.platform);

export const isChromebook = () =>
  /(chromebook| cros )/i.test(navigator.userAgent);

export const isWebApp = (platform: PlatformType) => {
  return ['web', 'lti', 'office'].includes(platform);
};

export const isNativeApp = (platform: PlatformType) => {
  return ['windows', 'osx', 'ios', 'android'].includes(platform);
};

export const isFirefox = () =>
  navigator.userAgent.toLowerCase().indexOf('firefox') > -1;

export const isSafari = () =>
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') == -1 &&
  navigator.userAgent.indexOf('FxiOS') == -1;

export const isEdge = () =>
  navigator.userAgent.toLowerCase().indexOf('edge') > -1 ||
  navigator.userAgent.toLowerCase().indexOf('edg') > -1;
