import { DTO } from '@readcloud/common';
import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export type UserActivityResult =
  | 'Success'
  | 'Failure'
  | 'Undefined'
  | 'Unknown';

export interface ActivityDataTypes {
  Logout: null;
  Login: {
    IP: string;
    'App Name': string;
  };
  CloudUpdate: Partial<DTO.CloudUpdate> & { id: string };
  BookUpload: {
    url: string;
    fileSize: number;
  };
  BookAdd: Partial<DTO.Book> & {
    id: string;
    clouds: Array<{ name: string; id: string }>;
  };
  BookDelete: Partial<DTO.Book> & {
    id: string;
  };
  BookDownload: Partial<DTO.Book> & {
    id: string;
  };
  BookUpdate: Partial<DTO.Book> & {
    id: string;
    clouds: Array<{ name: string; id: string }>;
  };
  CloudAdd: { name: string; id: string };
  Open: {
    bookId: string;
    title: string;
  };
}

export type UserActivityRequest<
  Type extends keyof ActivityDataTypes = keyof ActivityDataTypes,
  Data = ActivityDataTypes[Type]
> = {
  date: Date;
  sessionId: string;
  deviceId: string;
  deviceIdSource?: string;
  osName: string;
  osVersion: string;
  appVersion: string;
  type: Type;
  description?: string;
  result: UserActivityResult;
} & { data: Data | { error: { message: string } } };

export const userActivity = (
  userActivities: UserActivityRequest[],
  config?: AxiosRequestConfig
) => getApiv14().post('/user_activity', userActivities, config);
