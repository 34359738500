import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  AddStreamImportRuleThunkAction,
  DeleteStreamImportRuleThunkAction,
  GetStreamImportDataThunkAction,
  SearchStreamImportRulesThunkAction,
  UpdateStreamImportRuleThunkAction,
} from './thunk';
import { LogsState } from './types';

const initialState: LogsState = {
  streamImportSearchResult: {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
    },
    addToPrevResult: false,
    serverMillisec: 0,
  },
  streamImportRulesSearchResult: {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: 0,
    },
  },
};

const name = 'logs';

const asyncActions = {
  getStreamImportData: createAsyncThunk(
    `${name}/getStreamImportData`,
    GetStreamImportDataThunkAction
  ),
  addStreamImportRule: createAsyncThunk(
    `${name}/addStreamImportRule`,
    AddStreamImportRuleThunkAction
  ),
  updateStreamImportRule: createAsyncThunk(
    `${name}/updateStreamImportRule`,
    UpdateStreamImportRuleThunkAction
  ),
  deleteStreamImportRule: createAsyncThunk(
    `${name}/deleteStreamImportRule`,
    DeleteStreamImportRuleThunkAction
  ),
  searchStreamImportRules: createAsyncThunk(
    `${name}/searchStreamImportRules`,
    SearchStreamImportRulesThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      asyncActions.getStreamImportData.fulfilled,
      (state, action) => {
        if (action.payload.addToPrevResult) {
          state.streamImportSearchResult.result.items.push(
            ...action.payload.result.items
          );
        } else {
          state.streamImportSearchResult = action.payload;
        }
      }
    );
    builder.addCase(
      asyncActions.addStreamImportRule.fulfilled,
      (state, action) => {
        if (action.payload !== null) {
          state.streamImportRulesSearchResult.result.items.push(
            action.payload.result.items
          );
        }
      }
    );
    builder.addCase(
      asyncActions.updateStreamImportRule.fulfilled,
      (state, action) => {
        if (action.payload !== null) {
          const index =
            state.streamImportRulesSearchResult.result.items.findIndex(
              (item) => item.id === action.payload.id
            );
          state.streamImportRulesSearchResult.result.items[index] =
            action.payload;
        }
      }
    );
    builder.addCase(
      asyncActions.deleteStreamImportRule.fulfilled,
      (state, action) => {
        if (action.payload !== null) {
          state.streamImportRulesSearchResult.result.items.filter(
            (item) => item.id !== action.payload
          );
        }
      }
    );
    builder.addCase(
      asyncActions.searchStreamImportRules.fulfilled,
      (state, action) => {
        if (action.payload !== null) {
          state.streamImportRulesSearchResult = action.payload;
        }
      }
    );
  },
});

const { actions, reducer } = slice;

export const logsReducer = reducer;

export const logsActions = { ...actions, asyncActions };
