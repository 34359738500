import { AxiosResponse } from 'axios';
import { resetPassword, resetPasswordGen } from './app';

interface ResetPasswordGenResponseBody {
  result: {
    status: string;
  };
  error?: string;
}

const handleResetPasswordGenResponse = (
  response: AxiosResponse<ResetPasswordGenResponseBody>
) => {
  if (response.data.error) {
    throw new Error(response.data.error);
  }
  return response.data.result;
};

export const performResetPasswordGen = (email: string, config?: any) => {
  return resetPasswordGen(email, config).then(handleResetPasswordGenResponse);
};

const handleResetPasswordResponse = (
  response: AxiosResponse<ResetPasswordGenResponseBody>
) => {
  if (response.data.error) {
    throw new Error(response.data.error);
  }
  return response.data.result;
};

export const performResetPassword = (
  email: string,
  tmpPwd: string,
  newPwd: string,
  config?: any
) => {
  return resetPassword(email, tmpPwd, newPwd, config).then(
    handleResetPasswordResponse
  );
};
