import {
  BooksStatsRequest,
  IntLoginCredUpdateRequest,
  PublisherBook,
  PublisherInstitution,
} from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { UserBookCreate } from '../models';
import { getApiv14 } from './apiv14Setup';
type BookPurchaseRequest = { email: string; institution: string } & {
  books: UserBookCreate[];
};

export interface BookAddRequest {
  authorName: string;
  authorURI?: string;
  categories?: string[];
  cloudIds: string[];
  dcExtent?: string;
  dcIssued?: string;
  dcLanguage?: string;
  dcSource?: string;
  description: string;
  downloadURI: string;
  epubURI?: string;
  imageThumbnailURI?: string;
  imageURI?: string;
  isbn?: string;
  pdfURI?: string;
  published?: string;
  title: string;
  bookbytesUploadId: string;
  thumbnailUploadId: string;
  type: 'Media' | 'EBook';
}

export interface BooksPageCountRequest {
  bookId: string;
  pageCount: number;
}

export const resellerPurchase = (
  userBooks: BookPurchaseRequest,
  apiKey: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/reseller/update/user', userBooks, {
    ...config,
    params: {
      ...(config ? config.params : {}),
      apiKey,
    },
  });

export const resellerDeletePurchases = (
  userBooks: {
    email: string;
    institution: string;
    books: Array<{
      isbn: string;
    }>;
  },
  apiKey: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().post(
    '/reseller/update/user',
    {
      ...userBooks,
      contentAction: 'delete',
    },
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
        apiKey,
      },
    }
  );

export const addBook = (req: BookAddRequest, config?: AxiosRequestConfig) =>
  getApiv14().post('/add/book', req, config);

export const listBooks = (userEmail = '', config?: AxiosRequestConfig) =>
  /*getApiv14().get<DTO.Book>('/list/book.json', {
    ...config,
    params: { userEmail }
  });*/
  getApiv14().get('/list/book', config);

export const deltaBooks = (millisec: number, config?: AxiosRequestConfig) =>
  getApiv14().get('/delta/books', { params: { millisec }, ...config });

export const searchBook = (
  searchKey: string,
  searchString: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/search/book', {
    params: { searchKey, searchString },
    ...config,
  });

export const searchBookAdvanced = (
  req: {
    collection: string;
    limit: number;
    offset: number;
    order: string;
    user?: string;
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/book', req, config);

export const getPublishers = (userEmail = '', config?: AxiosRequestConfig) =>
  getApiv14().get('/get_publishers', {
    ...config,
    params: { userEmail },
  });

export const getPublisherExtract = (
  publisher: string,
  institutionId: string,
  //jobId: string,
  dateFrom: string,
  dateTo: string,
  origJobId: string,
  maxReportsToProcess: number,
  op_access: string /*add_access, remove_access, full*/,
  async: boolean,
  config?: AxiosRequestConfig
) =>
  getApiv14().get(
    '/publisher_extract/' +
      publisher +
      (op_access !== 'full' ? '/access/' : '/') +
      op_access +
      '/' +
      institutionId,
    {
      ...config,
      params: {
        /*jobId: jobId, dateFrom: dateFrom, dateTo: dateTo,*/ origJobId,
        maxReportsToProcess: maxReportsToProcess,
        async: async,
      },
    }
  );

export const getPublisherExtractMultiple = (
  publisher: string,
  institutionId: string,
  origJobIds: string,
  maxReportsToProcess: number,
  op_access: string /*add_access, remove_access, full*/,
  async: boolean,
  config?: AxiosRequestConfig
) =>
  getApiv14().get(
    '/publisher_extract/' +
      publisher +
      (op_access !== 'full' ? '/access/' : '/') +
      op_access +
      '/' +
      institutionId,
    {
      ...config,
      params: {
        origJobIds: origJobIds,
        /*maxReportsToProcess: maxReportsToProcess,*/ async: async,
      },
    }
  );

export const getPublisherExtractOxford = (
  books_or_classes: string,
  institutionId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get(
    '/publisher_extract/oxford/' /*+ books_or_classes + "/"*/ + institutionId,
    {
      ...config,
      params: {},
    }
  );

export const getPublisherExtractEducationPerfect = (
  institutionId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/publisher_extract/education_perfect/' + institutionId, {
    ...config,
    params: {},
  });

export const getPublisherExtractCamNelPear = (
  publisher: 'pearson' | 'nelson' | 'cambridge',
  variant: 'additional' | 'replacement',
  role: 'student' | 'teacher' | 'any',
  institutionId: string,
  origJobIds: string,
  async: boolean,
  config?: AxiosRequestConfig
) =>
  getApiv14().get(
    '/generic_publisher_extract/' +
      publisher +
      '/' +
      variant +
      '/' +
      role +
      '/' +
      institutionId,
    {
      ...config,
      params: { origJobIds: origJobIds, async: async },
    }
  );

export const getResellers = (userEmail = '', config?: AxiosRequestConfig) =>
  getApiv14().get('/get_resellers', {
    ...config,
    params: { userEmail },
  });

export const updateBookPages = (
  req: BooksPageCountRequest,
  config?: AxiosRequestConfig
) => {
  getApiv14().put(
    '/update/book_page_count/wc',
    {},
    { ...config, params: { bookId: req.bookId, pageCount: req.pageCount } }
  );
};

export const getResellerAll = (userEmail = '', config?: AxiosRequestConfig) =>
  getApiv14().get('/reseller/all', {
    ...config,
    params: { userEmail },
  });

export const getBookStats = (
  req: BooksStatsRequest,
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/book_page_statistics', req, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const updateIntLoginCred = (
  req: IntLoginCredUpdateRequest[],
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/intlogin_cred', req, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const importIntLogins = (
  req: IntLoginCredUpdateRequest[],
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/intlogin_cred/import', req, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const searchAdvancedPublisherInstitution = (
  req: {
    limit: number;
    offset: number;
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/publisher_institution', req, config);

export const editPublisherInstitution = (
  pi: PublisherInstitution[],
  config?: AxiosRequestConfig
) => getApiv14().post('/publisher_institution', pi, config);

export const updatePublisherInstitution = (
  uid: string,
  req: Partial<PublisherInstitution>,
  config?: AxiosRequestConfig
) => getApiv14().put('/publisher_institution/' + uid, req, config);

export const deletePublisherInstitution = (
  uid: string,
  config?: AxiosRequestConfig
) => getApiv14().delete('/publisher_institution/' + uid, config);

export const searchAdvancedPublisherBook = (
  req: {
    limit: number;
    offset: number;
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/publisher_book', req, config);

export const addPublisherBook = (
  pi: PublisherBook[],
  config?: AxiosRequestConfig
) => getApiv14().post('/publisher_book', pi, config);

export const updatePublisherBook = (
  uid: string,
  req: Partial<PublisherBook>,
  config?: AxiosRequestConfig
) => getApiv14().put('/publisher_book/' + uid, req, config);

export const deletePublisherBook = (uid: string, config?: AxiosRequestConfig) =>
  getApiv14().delete('/publisher_book/' + uid, config);
