export const scrollModes = {
  HORIZONTAL: 'HORIZONTAL',
  VERTICAL: 'VERTICAL',
  GRID: 'GRID',
};

export const scrollModeValues = Object.values(scrollModes);

export const qualityOptions = {
  HIGHEST: 16777216,
  MIDDLE: 1048576,
  LOWEST: 262144,
};

export const qualityOptionValues = Object.values(qualityOptions);

export const scaleModes = {
  '100': 1,
  '125': 1.25,
  '150': 1.5,
  '175': 1.75,
  '200': 2,
  '250': 2.5,
  '300': 3,
  '400': 4,
};

export const maxScale = 4;
export const minScale = 1;
export const scaleStep = 0.1;

export const renderingStates = {
  INITIAL: 0,
  RUNNING: 1,
  PAUSED: 2,
  FINISHED: 3,
};

export const domCursors = {
  help: 'help',
  wait: 'wait',
  crosshair: 'crosshair',
  notAllowed: 'not-allowed',
  pointer: 'pointer',
  initial: 'initial',
  auto: 'auto',
  text: 'text',
};

export const CursorMode = {
  RECTANGLE: 'RECTANGLE',
  OVAL: 'OVAL',
  FREE_DRAW: 'FREE_DRAW',
  LINE: 'LINE',
  ANNOTATE_TEXT: 'ANNOTATE_TEXT',
  EDIT: 'EDIT_ANNOTATION',
};

export const orientations = {
  PORTRAIT: 'PORTRAIT',
  LANDSCAPE: 'LANDSCAPE',
};

export const bookTypes = {
  PDF: 'pdf',
  EPUB: 'epub',
};

export const bookTypeExtensions = {
  PDF: '.pdf',
  EPUB: '.epub',
};

export const fontFamilies = {
  Arial: 'Arial',
  Courier: 'Courier',
  Helvetica: 'Helvetica',
  TimesNewRoman: 'Times New Roman',
};

export const fontSizes = {
  '8': 8,
  '9': 9,
  '10': 10,
  '11': 11,
  '12': 12,
  '14': 14,
  '16': 16,
  '18': 18,
  '20': 20,
  '24': 24,
  '28': 28,
  '32': 32,
  '36': 36,
};

export const colorOptions = {
  yellow: '#FFF100',
  orange: '#FF6900',
  green: '#00D084',
  blue: '#0693E3',
  red: '#EB144C',
  purple: '#9900EF',
};

export const textAlignments = {
  left: 'left',
  center: 'center',
  right: 'right',
  justify: 'justify',
};

export const scrollDirections = {
  forwards: 'forwards',
  backwards: 'backwards',
};

export const ENC_METHODS = ['None', 'aes-256-ctr', 'aes-256-cbc'];

export const findStates = {
  FOUND: 0,
  NOT_FOUND: 1,
  WRAPPED: 2,
  PENDING: 3,
};

export const documentProcessStates = {
  Initial: 'Initial',
  Downloading: 'Loading',
  Processing: 'Processing',
  Loaded: 'Loaded',
};

export const errorTypes = {
  InstantiationError: 'InstantiationError',
  ProcessError: 'ProcessError',
  DownloadError: 'DownloadError',
  DocumentError: 'DocumentError',
};

export const errorMessages = {
  InstantiationError: 'Could not instantiate the reader',
  DocumentError: 'Could not open the document',
  DownloadError: 'Could not read the document',
  ProcessError: 'An error occurred processing the document',
  DocumentNotFound: 'Document not found',
};

export const contactEmail = 'contact@readcloud.com';

export const pageViewModes = {
  OnePage: 'OnePage',
  TwoPages: 'TwoPages',
};

export const pageViewModeValues = Object.values(pageViewModes);
