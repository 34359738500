import { LicenceSearchResult } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SearchSoftwareLicencesThunkAction } from './thunk';
import { LicencesState } from './types';

const initialState: LicencesState = {
  licenceSearchResult: {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: 0,
    },
  },
  licenceSearchCsvResult: {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: 0,
    },
  },
  searchResultCsvLoading: false,
  searchResultLoading: false,
};

const name = 'licences';

const otherActions = {
  searchSoftwareLicences: createAsyncThunk(
    `${name}/searchSoftwareLicences`,
    SearchSoftwareLicencesThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setSearchResultCsvLoading(state, action: PayloadAction<boolean>) {
      state.searchResultCsvLoading = action.payload;
    },
    setSearchResultLoading(state, action: PayloadAction<boolean>) {
      state.searchResultLoading = action.payload;
    },
    clearSoftwareLicences(state) {
      state.licenceSearchResult = {
        result: {
          items: [],
          moreAvailable: false,
          nextOffset: 0,
          timeMs: Date.now(),
        },
      };
    },
    clearSoftwareLicencesCsv(state) {
      state.licenceSearchCsvResult = {
        result: {
          items: [],
          moreAvailable: false,
          nextOffset: 0,
          timeMs: Date.now(),
        },
      };
    },
    setSoftwareLicences(state, action: PayloadAction<LicenceSearchResult>) {
      state.licenceSearchResult = action.payload;
    },
    setSoftwareLicencesCsv(state, action: PayloadAction<LicenceSearchResult>) {
      state.licenceSearchCsvResult = action.payload;
    },
    addSoftwareLicences(state, action: PayloadAction<LicenceSearchResult>) {
      state.licenceSearchResult = {
        result: {
          items: [
            ...state.licenceSearchResult.result.items,
            ...action.payload.result.items,
          ],
          moreAvailable: action.payload.result
            ? action.payload.result.moreAvailable
            : false,
          nextOffset: action.payload.result
            ? action.payload.result.nextOffset
            : 0,
          timeMs: Date.now(),
        },
      };
    },
    addSoftwareLicencesCsv(state, action: PayloadAction<LicenceSearchResult>) {
      state.licenceSearchCsvResult = {
        result: {
          items: [
            ...state.licenceSearchResult.result.items,
            ...action.payload.result.items,
          ],
          moreAvailable: action.payload.result
            ? action.payload.result.moreAvailable
            : false,
          nextOffset: action.payload.result
            ? action.payload.result.nextOffset
            : 0,
          timeMs: Date.now(),
        },
      };
    },
  },
});

const { actions, reducer } = slice;

export const licencesReducer = reducer;

export const licencesActions = { ...actions, ...otherActions };
