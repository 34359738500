import {
  trackEvent,
  useAnalytics,
  useReadCloudAuth,
  useHasWindowFocus,
} from '@readcloud/common';
import { LoadingIcon } from '@readcloud/component-library';
import { FeedbackUI } from '@readcloud/feedback-ui/FeedbackUI';
import {
  applicationActions,
  applicationSelectors,
  authActions,
  authSelectors,
  booksActions,
  cloudsActions,
  useAppDispatch,
  usersActions,
} from '@readcloud/state';
import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';

const ReaderPageLazy = React.lazy(() => import('./ReaderPage'));
const ContentPageLazy = React.lazy(() => import('./ContentPage'));
const CloudsAndContentPageLazy = React.lazy(
  () => import('./CloudsAndContentPage')
);

import { Page } from './Page';
import { useTauriServiceWorkerListener } from '../hooks/useTauriServiceWorkerListener';

export const App: React.FC = (props) => {
  const analytics = useAnalytics();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { user } = useReadCloudAuth();

  useTauriServiceWorkerListener();

  const deltaIntervalHandle = React.useRef(undefined);

  const [isBootstrapped, setIsBootstrapped] = React.useState<boolean>(false);
  const access_token = useSelector(authSelectors.getAccessToken);
  const config = useSelector(applicationSelectors.getAppConfig);
  const appState = useSelector(applicationSelectors.getApplcationState);
  //get all the necessary data and store it in redux for Rreader

  const [refreshing, setRefreshing] = React.useState(false);
  const refresh = async (withoutRefreshingState = false) => {
    if (!withoutRefreshingState) setRefreshing(true);
    try {
      await Promise.all([
        dispatch(usersActions.asyncActions.getConnectedUsers({})),
        dispatch(cloudsActions.asyncActions.getClouds({})),
        dispatch(booksActions.asyncActions.getConnectedBooks({})),
        dispatch(applicationActions.asyncActions.getAppConfig({})),
      ]);
    } finally {
      if (!withoutRefreshingState) setRefreshing(false);
    }
  };

  const windowIsFocused = useHasWindowFocus((focussed) => {
    if (focussed) {
      refresh(true);
    }
  });

  //get delta for everything.
  React.useEffect(() => {
    let cancelled = false;
    if (
      windowIsFocused &&
      config?.deltaPollingIntervalSec &&
      appState.lastPoll.allServerMillisec
    ) {
      clearInterval(deltaIntervalHandle.current);
      deltaIntervalHandle.current = setInterval(() => {
        if (cancelled) return;
        dispatch(applicationActions.asyncActions.getDeltaAll({}));
      }, config?.deltaPollingIntervalSec * 1000 || 300000);
    }
    return () => {
      cancelled = true;
      clearInterval(deltaIntervalHandle.current);
    };
  }, [
    config?.deltaPollingIntervalSec,
    appState.lastPoll.allServerMillisec,
    windowIsFocused,
  ]);

  React.useEffect(() => {
    if (access_token) {
      refresh();
      setIsBootstrapped(true);
    }
  }, [access_token]);

  //##############################################
  //WEBAPP USER AUTH

  React.useEffect(() => {
    if (user) {
      dispatch(
        authActions.asyncActions.updateAuth({
          user: {
            access_token: user.accessToken,
            email: user.email,
            ...user,
          },
        })
      ).then(() => {
        //this requires the access_token to be in state, hence the .then()
        dispatch(authActions.asyncActions.getUserScopeParams());
      });
    }
  }, [user]);

  return isBootstrapped ? (
    <>
      <Switch>
        <Route path="/authentication/callback">
          <Redirect
            to={window.location.pathname + window.location.search || '/'}
          />
        </Route>
        <Route
          path="/reader/:cloudId/:bookId"
          render={({ match }) => (
            <Redirect
              to={`/read/${match.params.cloudId}/${match.params.bookId}`}
            />
          )}
        />
        <Route path="/read/:cloudId/:bookId">
          <Page title="Reader">
            <ReaderPageLazy />
          </Page>
        </Route>
        <Route path="/content">
          <Page title="Content">
            <ContentPageLazy
              navigateToCloudsView={() => {
                refresh();
                return history.push('/');
              }}
            />
          </Page>
        </Route>
        <Route path="/">
          <Page title="Home">
            <CloudsAndContentPageLazy
              refreshing={refreshing}
              onRefresh={refresh}
            />
          </Page>
        </Route>
      </Switch>
      {analytics.isFeatureEnabled('beta-feedback-button') && user && (
        <FeedbackUI
          userEmail={user.email}
          getOpenReplaySessionURL={() => {
            const result = trackEvent('Webapp/FeedbackSent');
            if (result) {
              return `https://app.posthog.com/events/${
                result.uuid
              }/${result.timestamp.toISOString()}`;
            }
          }}
          appName="WebApp"
        />
      )}
    </>
  ) : (
    <LoadingIcon loading position="fixed" />
  );
};

export default App;
