import { Collapse } from '@readcloud/component-library';
import { ProgressBar } from '@readcloud/component-library/components/Base/Progress';
import C from 'classnames';
import * as React from 'react';
import { ReactComponent as Icon } from './icon.svg';
import { Fade } from '../../Utilities/Fade';

export interface ILoadingIconProps {
  loading: boolean;
  className?: string;
  position?: 'absolute' | 'relative' | 'fixed';
  backdrop?: boolean;
  size?: string;
  progress?: number;
}

const positionMapper: { [index: string]: string } = {
  absolute: 'position-absolute',
  relative: 'position-relatie',
  fixed: 'position-fixed',
};
const sizeMapper: { [index: string]: string } = {
  xs: '20px',
  sm: '50px',
  lg: '120px',
};

export const LoadingIcon: React.FunctionComponent<ILoadingIconProps> = (
  props
) => {
  const [showProgress, setShowProgress] = React.useState<boolean>(false);
  const showProgressRef = React.useRef(undefined);

  React.useEffect(() => {
    showProgressRef.current = setTimeout(() => setShowProgress(true), 4000);
    return () => clearTimeout(showProgressRef.current);
  }, []);

  return (
    <Fade remove appear in={props.loading} timeout={150}>
      <div
        data-testid="loadingIcon"
        className={C(
          props.className,
          'loading-icon-container',
          'd-flex',
          'justify-content-center',
          'align-items-center',
          props.position && positionMapper[props.position],
          'absolute-full-width',
          'absolute-full-height'
        )}
      >
        {props.backdrop && (
          <div
            className={C(
              'position-absolute',
              'absolute-full-width',
              'absolute-full-height',
              'bg-dark',
              'backdrop',
              'rounded'
            )}
          />
        )}
        <div className="d-flex flex-column">
          <Icon
            className={C('loading-icon')}
            height={props.size ? sizeMapper[props.size] : '80px'}
            width={props.size ? sizeMapper[props.size] : '80px'}
          />

          {props.progress >= 0 && (
            <Collapse isOpen={showProgress} timeout={500}>
              <div>
                <Fade appear in={showProgress} timeout={500}>
                  <ProgressBar className={'progress-sm'} now={props.progress} />
                </Fade>
              </div>
            </Collapse>
          )}
        </div>
      </div>
    </Fade>
  );
};
