import { jwtDecode } from 'jwt-decode';
import { recordError } from './rum';
const wwindow = typeof window !== 'undefined' ? window : undefined;
const atob = wwindow
  ? window.atob
  : Buffer
  ? (str: string) => Buffer.from(str, 'base64').toString()
  : null;
if (!atob) {
  throw new Error("Invalid environment, could not make an 'atob' function");
}

// Test: JWT's that contain all the characters in the base64 alphabet should be able to be decoded. Note: the deprectated `atob` function doesn't do this.
export const decodeJwt = (jwt: string) => {
  try {
    const payload = jwtDecode(jwt);
    const header = jwtDecode(jwt);

    return {
      header,
      payload,
    };
  } catch (e) {
    recordError(e);
    return null;
  }
};
