import C from 'classnames';
import * as React from 'react';
import { TestingProps } from '../../Types/Testing';

interface IModalFooterProps extends TestingProps {
  className?: string;
}

export const ModalFooter: React.FunctionComponent<IModalFooterProps> = (
  props
) => {
  return (
    <div
      className={C('modal-footer', props.className)}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  );
};
