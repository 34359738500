import {
  annotationsReducer,
  applicationReducer,
  appModulesReducer,
  appStatesReducer,
  authReducer,
  bookmarksReducer,
  booksReducer,
  cloudsReducer,
  commentsReducer,
  homeSettingsReducer,
  institutionsReducer,
  jobsReducer,
  licencesReducer,
  logsReducer,
  pdfReducer,
  publishersReducer,
  readerReducer,
  reportReducer,
  resellersReducer,
  searchReducer,
  tagsReducer,
  textSelectionReducer,
  transactionsReducer,
  usersReducer,
  cloudsAndContentReducer,
} from '@readcloud/state';
import { combineReducers } from '@reduxjs/toolkit';

const RootReducerCombined = combineReducers({
  AnnotationsState: annotationsReducer,
  AppModulesState: appModulesReducer,
  AppStatesState: appStatesReducer,
  AuthState: authReducer,
  BookmarksState: bookmarksReducer,
  BooksState: booksReducer,
  CloudsState: cloudsReducer,
  CommentsState: commentsReducer,
  InstitutionsState: institutionsReducer,
  LicencesState: licencesReducer,
  LogsState: logsReducer,
  PublishersState: publishersReducer,
  ResellersState: resellersReducer,
  TagsState: tagsReducer,
  TransactionsState: transactionsReducer,
  UserState: usersReducer,
  ReaderState: readerReducer,
  ApplicationState: applicationReducer,
  PDFState: pdfReducer,
  ReportState: reportReducer,
  SearchState: searchReducer,
  TextSelectionState: textSelectionReducer,
  JobsState: jobsReducer,
  HomeSettingsState: homeSettingsReducer,
  CloudsAndContentState: cloudsAndContentReducer,
});

export type AppState = ReturnType<typeof RootReducerCombined>;

export default RootReducerCombined;
