import { createSlice } from '@reduxjs/toolkit';
import { AnnouncementState } from './types';

const initialState: AnnouncementState = {
  announcements: [],
};

const name = 'announcement';

const slice = createSlice({
  name,
  initialState,
  reducers: {
    createAnnouncement(state, action) {
      state.announcements.push(action.payload);
    },
    updateAnnouncement(state, action) {
      const index = state.announcements.findIndex(
        (announcement) => announcement.id === action.payload.id
      );
      state.announcements[index] = action.payload;
    },
    deleteAnnouncement(state, action) {
      state.announcements.filter(
        (announcement) => announcement.id != action.payload
      );
    },
  },
});

const { actions, reducer } = slice;
export const announcementActions = actions;
export const announcementReducer = reducer;
