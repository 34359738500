import { AxiosInstance } from 'axios';
import { getRefreshToken } from './client';

const shouldIntercept = (url: string) => !/\/token$/.test(url);

export const makeAuthAssertionInterceptor = (
  reAuthenticate?: (
    refreshToken: string
  ) => Promise<{ accessToken: string; refreshToken: string }>
) => {
  /** Validation Functions */
  let currentState: State = {
    failedToken: false,
    refreshPromise: undefined,
    failQueue: [],
    newAccessToken: null,
  };

  type State = {
    failedToken: boolean;
    refreshPromise?: Promise<{} | undefined>;
    failQueue: { resolve: () => void; reject: () => void }[];
    newAccessToken: string | null;
  };
  return (client: AxiosInstance) => {
    client.interceptors.request.use(async (config) => {
      //Custom code for local dev

      if (!shouldIntercept(config.url || '')) {
        return config;
      } else {
        if (currentState.refreshPromise !== undefined) {
          await currentState.refreshPromise;
          return config;
        } else {
          return config;
        }
      }
    });

    client.interceptors.response.use(
      (response) => response,
      async (error) => {
        let ignoreRefresh = true;
        let responseToCheck;

        //console.log('ShouldIntercept', shouldIntercept(error.config.url));

        if (
          error?.response?.status >= 400 &&
          shouldIntercept(error.config.url)
        ) {
          if (error?.response?.data?.result?.error) {
            responseToCheck = error.response.data.result.error;
          }

          if (!responseToCheck) {
            responseToCheck = error?.response?.data?.error;
          }

          //Check if the error should be ignored
          switch (responseToCheck) {
            case 'Token is absent':
              //Check token in backend.
              ignoreRefresh = false;
              break;

            case 'Invalid token':
              //Check token in backend.
              ignoreRefresh = false;
              break;

            case 'Unknown refresh token':
              //Check token in backend.
              ignoreRefresh = true;
              break;

            case 'Invalid request': //Not sure if needed here
              ignoreRefresh = true;
              break;

            case 'Operation is not permitted': //Not sure if needed here
              ignoreRefresh = true;
              break;

            case 'Invalid username or password': //Not sure if needed here
              ignoreRefresh = true;
              break;

            case 'Your refresh token is either invalid or expired': //Not sure if needed here
              ignoreRefresh = true;
              break;

            default:
              ignoreRefresh = true;
          }

          /*
          Shouldn't exist according to Vlad
          if (error.response.data && error.response.data == 'Invalid token') {
            ignoreRefresh = true;
          }

          if (
            error.response.data.error.message &&
            error.response.data.error.message ==
              'Your refresh token is either invalid or expired'
          ) {
            ignoreRefresh = true;
          }
          */

          const currentRefreshToken = getRefreshToken();

          if (!ignoreRefresh && currentRefreshToken && reAuthenticate) {
            {
              await reAuthenticate(getRefreshToken())
                .then((res) => {
                  //This is added because RefreshToken error still returns 200
                  //console.log('Result', res);
                  if (res && res.accessToken) {
                    currentState.newAccessToken = res.accessToken;
                  } else {
                    ignoreRefresh = true;
                  }
                })
                .catch((err) => {
                  console.log(err);
                  ignoreRefresh = true;
                  return Promise.reject(error);
                });

              error.config.headers.Authorization =
                'Bearer ' + currentState.newAccessToken;

              ignoreRefresh = true;

              return client(error.config);
            }
          }
        }

        // Reject promise if usual error
        return Promise.reject(error);
      }
    );
    return client;
  };
};
