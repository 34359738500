import { DTO } from "../types";
import { tryCatchAndNotify } from "./rum";

export const defaultContentHost = "content.readcloud.com";
const testContentHost = "content.readcloud-test.com";

// This is where all book and image URLs are rewritten to point to the correct place.
export const rewriteResourceURL = (url: string | null | undefined) => {
  const parsed = url
    ? tryCatchAndNotify<URL>(
      () => new URL(url),
      (e) => null,
    )
    : null;
  if (parsed) {
    const originalHost = parsed.host;
    if (parsed.protocol === "http:") {
      parsed.protocol = "https:";
    }

    if (/^(rcs|api|acs)\.readcloud\.com$/.test(originalHost)) {
      parsed.host = defaultContentHost;
    }

    if (/^(rcs|api|acs)\.readcloud-test\.com$/.test(originalHost)) {
      parsed.host = testContentHost;
    }

    return parsed.href;
  }
  return undefined;
};

/**
 * Use this to get the URL for an RCS resource within a book.
 */
export const buildRcsResourceUrl = (resource: DTO.RCSResource) => {
  return rewriteResourceURL(
    `https://rcs.readcloud.com/rcs/${resource.type.toLowerCase()}/${resource.fileName}.${resource.fileExt}`,
  );
};

/** Use this to get the latest RCS bookbytes and thumbnail for the `rcs` property in a book */
export const getLatestRcsResources = (rcs: DTO.RCSBookData) => {
  if (rcs && rcs.resources && rcs.resources.length) {
    const latest = rcs.resources.reduce(
      (acc, current) =>
        current.type === "Thumbnail"
          ? {
            ...acc,
            thumbnail: !acc.thumbnail
              ? current
              : current.version > acc.thumbnail.version
              ? current
              : acc.thumbnail,
          }
          : current.type === "Bookbytes"
          ? {
            ...acc,
            bookbytes: !acc.bookbytes
              ? current
              : current.version > acc.bookbytes.version
              ? current
              : acc.bookbytes,
          }
          : acc,
      {} as {
        thumbnail?: DTO.RCSResource;
        bookbytes?: DTO.RCSResource;
        thumbnailUrl?: string;
        bookbytesUrl?: string;
      },
    );

    latest.thumbnailUrl = latest.thumbnail
      ? buildRcsResourceUrl(latest.thumbnail)
      : undefined;
    latest.bookbytesUrl = latest.bookbytes
      ? buildRcsResourceUrl(latest.bookbytes)
      : undefined;

    return latest;
  } else {
    return {};
  }
};
