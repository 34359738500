import { getApiv14 } from './apiv14Setup';
import { Blocklist } from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';

export const createBlocklist = (
  bl: Partial<Blocklist>[],
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/user_block_list_entry?skipExisting=true', bl, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const updateBlocklist = (
  bl: Partial<Blocklist>[],
  config?: AxiosRequestConfig
) =>
  getApiv14().put('/user_block_list_entry', bl, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const deleteBlocklist = (uid: string, config?: AxiosRequestConfig) =>
  getApiv14().delete('/user_block_list_entry/' + uid, config);

export const deleteMultipleBlocklist = (
  entries: { id: string }[],
  config?: AxiosRequestConfig
) =>
  getApiv14().delete('/user_block_list_entry/', { ...config, data: entries });

export const searchBlocklistAdvanced = (
  req: {
    limit: number;
    offset: number;
    order: string;
    root: any;
  },
  config?: AxiosRequestConfig,
  async?: boolean
) =>
  getApiv14().post(
    '/search/advanced/user_block_list_entry' + (async ? '?async=true' : ''),
    req,
    config
  );
