import axios, { AxiosInstance } from 'axios';
import { compose } from 'ramda';
import { addCommonAxiosConfig } from '../client';
import { makeAuthAssertionInterceptor } from '../makeAuthInterceptor';
let apiv14Url = '/api/v14';

const addApiv14UrlInterceptor = (client: AxiosInstance) => {
  client.interceptors.request.use((config) => ({
    baseURL: apiv14Url,
    ...config,
  }));
  return client;
};

const addApiKeyInterceptor = (client) => {
  client.interceptors.request.use((config) => {
    return {
      ...config,
      params: {
        apiKey: 'bed91270-fd9f-11e1-bb4b-84c9b273558d',
        ...config.params,
      },
    };
  });
  return client;
};

const makeApiv14Client: any = compose(
  addApiv14UrlInterceptor,
  addApiKeyInterceptor,
  makeAuthAssertionInterceptor(),
  addCommonAxiosConfig,
  axios.create
);

const apiv14Client = makeApiv14Client({});

export const addBearerToken = (token: string) => {
  apiv14Client.defaults.headers.common['Authorization'] = `Bearer ${token}`;
};

const ensureApiV14ClientReady = () => {
  if (!!apiv14Url) {
    return true;
  } else {
    throw new Error(
      'API V14 client was accessed before the base URL was set. Please configure this before accessing the client.'
    );
  }
};
export const getApiv14 = (): AxiosInstance => (
  ensureApiV14ClientReady(), apiv14Client
);

export const getStatus = () => getApiv14().get('/status');

export const getApiv14Url = () => apiv14Url;
