import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import {
  CreateReadStatThunkAction,
  CreateUserActivityThunkAction,
} from './thunk';
import { ReportState } from './types';

const initialState: ReportState = {
  readStats: [],
  userActivity: [],
};

const name = 'reader';

const asyncActions = {
  createReadStat: createAsyncThunk(
    `${name}/createReadStat`,
    CreateReadStatThunkAction
  ),
  createUserActivity: createAsyncThunk(
    `${name}/createUserActivity`,
    CreateUserActivityThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(asyncActions.createReadStat.fulfilled, (state, action) => {
      //probably unnecessary
      state.readStats.push(...action.payload);
    });
    builder.addCase(
      asyncActions.createUserActivity.fulfilled,
      (state, action) => {
        //probably unnecessary
        state.userActivity.push(action.payload);
      }
    );
  },
});

const { actions, reducer } = slice;

export const reportReducer = reducer;
export const reportActions = { ...actions, asyncActions };
