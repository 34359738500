import { useEffect } from 'react';
import {
  readFile,
  writeFile,
  BaseDirectory,
  exists,
  create,
  mkdir,
} from '@tauri-apps/plugin-fs';

function sanitizeUrlToFilename(url: string) {
  // Get the part of the URL after the last '/'
  const fileName = url.split('/').pop() || '';

  // Sanitize the fileName by replacing any unwanted characters
  return fileName.replace(/[\/:]/g, '_');
}

export const useTauriServiceWorkerListener = () => {
  useEffect(() => {
    // Check if running in a Tauri environment
    const isNative = !!(window as any).__TAURI__;

    // Send environment info to service worker when it's ready
    navigator.serviceWorker.ready.then((registration) => {
      registration.active?.postMessage({
        type: 'environment',
        isNative,
      });
    });

    // Check if the file exists in storage; if not, request the service worker to fetch it
    const GetFileFromStorageListener = async (event: MessageEvent) => {
      if (event.data && event.data.type === 'getFileFromStorage') {
        const { url, requestId } = event.data;
        try {
          const sanitizedFileName = sanitizeUrlToFilename(url);

          const fileContent = await readFile(`readcloud/${sanitizedFileName}`, {
            baseDir: BaseDirectory.AppData,
          });

          console.log(
            '[Service Worker Listener] Reading file from storage: ',
            sanitizedFileName
          );

          // Pass file back to the service worker with success flag
          navigator.serviceWorker.ready.then((registration) => {
            registration.active?.postMessage({
              type: 'bridgeResponse',
              requestId,
              success: true,
              content: fileContent,
            });
          });
        } catch (error) {
          console.error(`Error reading file from storage: ${url}`, error);
          // Send error response back to the service worker
          navigator.serviceWorker.ready.then((registration) => {
            registration.active?.postMessage({
              type: 'bridgeResponse',
              requestId,
              success: false,
              error: `Error: Could not fetch file ${url}`,
            });
          });
        }
      }
    };

    // Register the message listener for service worker messages
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener(
        'message',
        GetFileFromStorageListener
      );
    }

    const SaveFileToStorageListener = async (event: MessageEvent) => {
      if (event.data && event.data.type === 'saveFileToStorage') {
        const { url, content, requestId } = event.data;
        try {
          const sanitizedFileName = sanitizeUrlToFilename(url);

          await mkdir('readcloud', {
            baseDir: BaseDirectory.AppData,
            recursive: true,
          });

          // Convert ArrayBuffer to Uint8Array
          const contentAsUint8Array = new Uint8Array(content);

          await create(`readcloud/${sanitizedFileName}`, {
            baseDir: BaseDirectory.AppData, //should be BaseDirectory.AppData/com.readcloud.nativeapp/readcloud
          });

          console.log(
            '[Service Worker Listener] Saving file to storage: ',
            `readcloud/${sanitizedFileName}`
          );

          // Store the file in the filesystem for future requests
          await writeFile(
            `readcloud/${sanitizedFileName}`,
            contentAsUint8Array,
            {
              baseDir: BaseDirectory.AppData,
            }
          );

          // Send the fetched content back to the service worker
          event.source.postMessage({
            type: 'bridgeResponse',
            requestId,
            body: content,
          });
        } catch (error) {
          console.error(`Failed to write file: ${url}`, error);
          // You may send an error response back to the service worker if needed
          event.source.postMessage({
            type: 'bridgeResponse',
            requestId,
            body: `Error: Could not save file ${url}`,
          });
        }
      }
    };

    // Register the message listener for service worker messages
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.addEventListener(
        'message',
        SaveFileToStorageListener
      );
    }

    // Cleanup the event listener on component unmount
    return () => {
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.removeEventListener(
          'message',
          SaveFileToStorageListener
        );
        navigator.serviceWorker.removeEventListener(
          'message',
          GetFileFromStorageListener
        );
      }
    };
  }, []);
};
