import { AxiosRequestConfig } from 'axios';
//import { Cloud, SomeOf } from '../models';
import { Cloud, ResellerCloudUpdateRequest } from '@readcloud/data';
import { getApiv14 } from './apiv14Setup';
const cloudUpdatePath = '/reseller/update/cloud';

export interface CloudAddRequest {
  addBookAdminOnly: boolean;
  admins?: string[];
  archivedFor?: string[];
  books?: string[];
  description: string;
  external_id?: string;
  institution: string;
  members: string[];
  name: string;
  persistent?: boolean;
}

export interface CloudUpdateRequest {
  addBookAdminOnly: boolean;
  admins?: string[];
  books?: string[];
  description: string;
  members: string[];
  name: string;
}

/*export interface ResellerCloudUpdateRequest {
  institution: string;
  name: string;
  description?: string;
  isbns?: string[];
  admins?: string[];
  members?: string[];
  contentAction?: "add" | "delete";
  deleted?: boolean;
  multiOrders?: string[];
}*/

export const resellerUpdateCloud = (
  cloud: ResellerCloudUpdateRequest,
  apiKey: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().post(cloudUpdatePath, cloud, {
    ...config,
    params: {
      ...(config ? config.params : {}),
      apiKey,
    },
  });

export const resellerDeleteCloudBooks = (
  name: string,
  institution: string,
  isbns: string[],
  apiKey: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().post(
    cloudUpdatePath,
    {
      name,
      institution,
      isbns,
      contentAction: 'delete',
    },
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
        apiKey,
      },
    }
  );

export const resellerDeleteCloud = (
  { name, institution },
  apiKey: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().post(
    cloudUpdatePath,
    { name, institution, deleted: true },
    {
      ...config,
      params: {
        ...(config ? config.params : {}),
        apiKey,
      },
    }
  );

export const addCloud = (req: CloudAddRequest, config?: AxiosRequestConfig) =>
  getApiv14().post('/add/cloud', req, config);

export const addCloud2 = (req: Cloud, config?: AxiosRequestConfig) =>
  getApiv14().post('/cloud', [req], config);

export const updateCloud = (
  cloudId: string,
  req: CloudUpdateRequest,
  config?: AxiosRequestConfig
) => getApiv14().put('/update/cloud', req, { params: { cloudId }, ...config });

export const updateCloud2 = (
  cloudId: string,
  req: Partial<Cloud>,
  config?: AxiosRequestConfig
) => getApiv14().put('/cloud/' + cloudId, req, config);

export const updateCloudPatch = (
  cloudId: string,
  req: Partial<Cloud>,
  config?: AxiosRequestConfig
) => getApiv14().patch('/cloud/' + cloudId, req, config);

export const addMember = (
  req: { cloudID: string; userID: string },
  config?: AxiosRequestConfig
) => getApiv14().post('/add/membercloud', req, config);

export const archiveCloud = (
  cloudId: string,
  unarchive = false,
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/archive/cloud', null, {
    params: { cloudId, unarchive },
    ...config,
  });

export const listClouds = (userEmail: string, config?: AxiosRequestConfig) =>
  getApiv14().get('/list/cloud', config);
//getApiv14().get('/list/cloud', { params: { userEmail }, ...config });

export const deleteBook = (
  bookId: string,
  cloudId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().delete('/delete/book', {
    params: { bookId, cloudId },
    ...config,
  });

export const deleteCloud = (cloudId: string, config?: AxiosRequestConfig) =>
  getApiv14().delete('/delete/cloud', { params: { cloudId }, ...config });

export const removeMember = (
  cloudID: string,
  userID: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().delete('/delete/membercloud', {
    data: { cloudID, userID },
    ...config,
  });

export const deltaClouds = (millisec: number, config?: AxiosRequestConfig) =>
  getApiv14().get('/delta/clouds', { params: { millisec }, ...config });

export const searchCloud = (
  institution: string,
  searchKey: string,
  searchString: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/search/cloud', {
    params: { institution, searchKey, searchString },
    ...config,
  });

export const searchCloudAdvanced = (
  req: {
    limit: number;
    offset: number;
    order: string;
    root: any;
  },
  config?: AxiosRequestConfig,
  async?: boolean
) =>
  getApiv14().post(
    '/search/advanced/cloud' + (async ? '?async=true' : ''),
    req,
    config
  );
