import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { HomeSettings } from './types';

const initialState: HomeSettings = {
  tileSize: 'medium',
  myInteractiveLoginsPosition: 'bottom',
  myPurchasesCloudPosition: 'bottom',
  hideTitle: true,
  hideEmptyClouds: false,
  layout: 'grid',
};

const name = 'home';

const asyncActions = {};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateHomeSettings(state, action: PayloadAction<Partial<HomeSettings>>) {
      Object.keys(action.payload).forEach(
        (key) => (state[key] = action.payload[key])
      );
    },
  },
});

const { actions, reducer } = slice;

export const homeSettingsReducer = reducer;
export const homeSettingsActions = { ...actions, asyncActions };
