import { fabric } from 'fabric';
import * as t from 'io-ts';
import { OmitProperties } from 'ts-essentials';
import { UserRole } from '../Common';
import * as OverlayAnnotations from './OverlayAnnotations'; // import { OverlayAnnotations } from "./OverlayAnnotations";

export const annotationOrHighlight = OverlayAnnotations.annotationOrHighlight;

export type Version = '2';

export interface ImmutableBase {
  id: string;
  created: string;
  updated: string;
  deleted: boolean;
}

export interface User extends ImmutableBase {
  email: string;
  role: UserRole;
  firstName: string;
  lastName: string;
  thumbnailURI?: string;
  institution: string;
  loginDateTime: string;
  pollingDateTime: string;
  passwordRecoveryDateTime?: string;
}
export interface ReadStatEvent {
  pageStart: number;
  pageEnd: number;
}

export interface IntLoginData {
  injectableJS?: string;
  dialogText?: string;
  lti?: boolean;
}

export const productRights = t.partial({
  play: t.boolean,
  excerpt: t.boolean,
  display: t.boolean,
  print: t.boolean,
  printPages: t.string,
  copyPercentage: t.number,
});

export type ProductRights = t.TypeOf<typeof productRights>;

export const productTokenPayload = t.type({
  'rights.json': t.string,
});
export type ProductTokenPayload = t.TypeOf<typeof productTokenPayload>;

export const rcsResource = t.type({
  type: t.string,
  mimeType: t.string,
  version: t.number,
  fileName: t.string,
  fileExt: t.string,
  finalFileSize: t.number,
  srcChecksum: t.string,
  minor: t.number,
  longId: t.string,
});

export type RCSResource = t.TypeOf<typeof rcsResource>;

export const rcsBookData = t.intersection([
  t.type({
    resources: t.array(rcsResource),
  }),
  t.partial({
    licenceToken: t.union([t.null, t.string]),
    productToken: t.union([t.null, t.string]),
  }),
]);
export type RCSBookData = t.TypeOf<typeof rcsBookData>;

export const bookType = t.union([
  t.literal('Media'),
  t.literal('EBook'),
  t.literal('Unknown'),
]);

export type BookType = t.TypeOf<typeof bookType>;
export interface Book extends ImmutableBase {
  title: string;
  owner?: string | null;
  intLoginData?: IntLoginData | null;
  isbn?: string | null;
  downloadURI?: string | null;
  pdfURI?: string | null;
  description?: string | null;
  authorName?: string | null;
  authorURI?: string | null;
  imageThumbnailURI?: string | null;
  type?: string | null;
  fileSize?: number | null;
  rcsData?: RCSBookData | null;
  options?: {
    disableComments?: boolean;
    printable?: boolean;
    pageCount?: number;
  };
}

export interface BookX extends Book {
  idx?: number | null;
  amount?: number;
  contributors?: [] | null;
  currency?: string | null;
  imprint?: string | null;
  priceType?: string | null;
  publisher?: string | null;
  resourceSystems?: [] | null;
  thumbnailURL?: string | null;
  numberOfPages?: number | null;
  publicationDate?: Date | null;
  locked?: boolean;
  id1?: string;
  bookExpireDate?: string;
  price?: number;
}

export interface Cloud extends ImmutableBase {
  name: string;
  description: string;
  institution: string;
  type: string;
  members: string[];
  admins: string[];
  books: string[];
  addBookAdminOnly: boolean;
  persistent: boolean;
  customBookSortOrder?: boolean;
}

export type CloudUpdate = Omit<Cloud, keyof ImmutableBase>;

export type EpubTheme = 'day' | 'night' | 'sepia';
export type AnnotationBookType = 'EPUB' | 'PDF' | 'MEDIA';
export type AnnotationType = OverlayAnnotations.AnnotationType | 'Text';

export type RCFabricObjectData = {
  id: string;
  visibleToUsers: [];
  visibleInClouds: [];
};
type RCFabricObjectC = fabric.Object<RCFabricObjectData>;

export type RCFabricObject = OmitProperties<RCFabricObjectC, Function>;

export type Annotation = OverlayAnnotations.AnnotationOrHighlight;
export type TextHighlightAnnotation =
  OverlayAnnotations.TextHighlightAnnotation;
export interface CreateAnnotation {
  id: string;
  clientCreated?: Annotation['clientCreated'];
  clientUpdated?: Annotation['clientUpdated'];
  authorID: string;
  bookID: string;
  color: string;
  selectedText: string;
  text: string;
  textFormat: string;
  visibleInClouds: string[];
  visibleToUsers: string[];
  version: string;
  location?: Annotation['location'];
  type: AnnotationType;
  bookType?: AnnotationBookType | null;
  data?: Annotation['data'];
  options: NonNullable<Annotation['options']>;
}
export interface UpdateAnnotation {
  clientUpdated?: Annotation['clientUpdated'];
  authorID: string;
  bookID: string;
  color: string;
  selectedText: string;
  text: string;
  textFormat: string;
  visibleInClouds: string[];
  visibleToUsers: string[];
  version: string;
  location?: Annotation['location'];
  type: AnnotationType;
  bookType?: AnnotationBookType | null;
  data?: Annotation['data'];
  options: NonNullable<Annotation['options']>;
}
export type Comment = ImmutableBase & {
  id: string;
  subjectId: string;
  subjectType: 'Comment' | 'Annotation' | 'Book' | 'Unknown';
  text: string;
  version: Version;
  visibleInClouds: string[];
  authorId: string;
  clientCreated?: string;
  clientUpdated?: string;
};
export interface Bookmark extends ImmutableBase {
  id: string;
  bookId: string;
  cloudIds: string[];
  location: string;
  text: string;
  version: Version;
  authorId: string;
  clientCreated?: string;
  clientUpdated?: string;
}
export interface BookUpdatePayload {
  id?: string;
  title: string;
  downloadURI: string;
  description: string;
  authorName: string;
  imageThumbnailURI: string;
  owner: string;
  clouds: string[];
}
export interface BookUploadPayload {
  id: string;
}
export interface BookUploadResponse {
  result: {
    userUploadId: string;
  };
}
export interface BookAddRequest {
  authorName: string;
  authorURI?: string;
  categories?: string[];
  cloudIds?: string[];
  dcExtent?: string;
  dcIssued?: string;
  dcLanguage?: string;
  dcSource?: string;
  description: string;
  downloadURI?: string;
  epubURI?: string;
  imageThumbnailURI?: string;
  imageURI?: string;
  isbn?: string;
  pdfURI?: string;
  published?: string;
  title: string;
  type?: string;
  tagIds?: string[];
  options: {
    disableComments: boolean;
  };
  bookbytesUploadId?: string;
  thumbnailUploadId?: string;
}
export interface BookAddResponse {
  result: {
    id: string;
  };
}
export type ViewState = Partial<{
  pageViewMode: string;
  pageNumberStart: number;
  pageNumberEnd: number;
  location: string;
  scale: number;
  epubFontSize: number;
  epubMode: string;
  epubFont: string;
  annotationFilter: string | undefined;
  annotationFilterUsers: string[];
}>;

export interface UserPrefState {
  annoPrefs: {
    [index: string]: OverlayAnnotations.Annotation['data'];
  };
}

export type AppState = Partial<{
  id: string;
  tags: { realm: string; bookId?: string };
  state: ViewState | UserPrefState;
  version: string;
  date: string;
  created: string;
  updated: string;
  deleted: boolean;
}>;

/**
 * @deprecated
 * This is now just an alias for Tag
 */
export type TagState = Tag;

export const tag = t.type({
  id: t.string,
  owner: t.string,
  name: t.string,
  bookIds: t.array(t.string),
  version: t.string,
  created: t.string,
  updated: t.string,
  deleted: t.boolean,
});
export interface Tag {
  id: string;
  owner: string;
  name: string;
  bookIds: string[];
  version: string;
  created: string;
  updated: string;
  deleted: boolean;
}
export interface TagUpdate {
  id?: string;
  name: string;
  type: string;
  version: string;
  bookIds: string[];
}
export interface TagUpdateResponse {
  result: {
    id: string;
    status: string;
  };
}

export interface UploadFileRequest {
  fileType: string;
  fileSubType?: string;
  fileName: string;
  fileExtension?: string;
  institution?: string;
  file: Blob | File;
}
export interface DeltaAll {
  result: {
    serverMillisec: number;
    annotation?: Annotation[];
    cloud?: Cloud[];
    book?: Book[];
    comment?: Comment[];
    bookmark?: Bookmark[];
    tag?: TagState[];
  };
}
export interface GetAll {
  result: {
    serverMillisec: number;
    annotations?: Annotation[];
    clouds?: Cloud[];
    books?: Book[];
    comments?: Comment[];
    bookmarks?: Bookmark[];
    tags?: TagState[];
    users?: User[];
    schoolClouds?: Cloud[];
    appStates?: AppState[];
  };
}
export type AppModulePermission =
  | 'Drawing'
  | 'OpenExternalUrl'
  | 'WebReader'
  | 'Export Annotations'
  | 'Cloud Statistics'
  | 'Experimenter'
  | 'DisableCopyPDF'
  | 'TabbedBrowser'
  | 'Content'
  | 'AddUserClouds'
  | 'WebApp'
  | 'Media'
  | 'TTS'
  | 'HTML'
  | 'Immersive';
export interface Login {
  login: {
    adobeUuid: string;
    availableAppModules: AppModulePermission[];
    email: string;
    firstName: string;
    institution: string;
    lastName: string;
    myPurchasesCloud: string;
    role: UserRole;
    status: string;
    thumbnailURI?: null | string;
    userID: string;
  };
}
export interface CreateBookmark {
  id: string;
  bookId: string;
  cloudIds: string[];
  location: string;
  pageNum: number;
  text: string;
  clientCreated?: string;
  clientUpdated?: string;
}
export interface CreateComment {
  id: string;
  subjectId: string;
  subjectType: 'Comment' | 'Annotation' | 'Book' | 'Unknown';
  text: string;
  visibleInClouds?: string[];
  clientCreated?: string;
  clientUpdated?: string;
}

export interface TransactionSearchItem {
  idx?: number | null;
  user: {
    firstName?: string | null;
    lastName?: string | null;
    email: string;
    status: string;
    role: string;
  };
  institution: {
    name: string;
    status: string;
  };
  reseller: {
    name: string;
  };
  transaction: {
    id?: string;
    created: string;
    updated: string;
    deleted: boolean;
    purchaseDate: string;
    date: string;
    isbn: string;
    title?: string | null;
    publisher?: string | null;
    bookExpireDate: string;
    priceIncGst?: number;
    currencyCode?: string;
  };
  id1?: string;
}

export interface TransactionSearchResult {
  result: {
    items: TransactionSearchItem[];
    moreAvailable: boolean;
    nextOffset: number;
    timeMs: number;
  };
}

export interface BookSearchResult {
  result: {
    items: BookX[];
    moreAvailable: boolean;
    nextOffset: number;
    collection: string;
    timeMs: number;
  };
}

export interface UpdateComment {
  id: string;
  subjectId: string;
  subjectType: 'Comment' | 'Annotation' | 'Book' | 'Unknown';
  text: string;
  visibleInClouds?: string[];
  clientCreated?: string;
  clientUpdated?: string;
}

export interface Reseller extends ImmutableBase {
  apiKey?: string;
  institution?: string;
  name: string;
  passwordResetMessageTemplate?: string;
  passwordResetSubjectTemplate?: string;
}

export interface ResellerAllResult {
  result: Reseller[];
}
