import { SoftwareLicence } from '@readcloud/data';
import { licencesActions } from './slice';
const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';
export const AddSoftwareLicenceThunkAction = async (
  payload: SoftwareLicence,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(
    absolutePath + `/api/v14/search/advanced/software_licence`,
    {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(payload),
    }
  ).then(() => {
    return payload;
  });
};

// Thunk Action - Does some Async stuff (or data manipulation) then dispatch the action creator.
export const SearchSoftwareLicencesThunkAction = async (
  payload: {
    isCsvSearch: boolean;
    vchildren: any;
    voffset: number;
    vlimit: number;
    vresellerIds?: string[];
    pJoinMethod?: string;
  },
  { getState }
) => {
  console.log('SearchSoftwareLicencesThunkAction');

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  fetch(absolutePath + `/api/v14/search/advanced/software_licence`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify({
      disableLookup: {
        book: false,
        cloud: false,
        institution: false,
        reseller: false,
        user: false,
      },
      institutionIds: null,
      limit: payload.vlimit,
      offset: payload.voffset,
      order: '-created',
      resellerIds: payload.vresellerIds,
      root: {
        joinMethod: payload.pJoinMethod ? payload.pJoinMethod : 'and',
        children: payload.vchildren,
      },
    }),
  }).then((response) => {
    response
      .json()
      .then(({ result }) => {
        try {
          //console.log(response);
          const data = {
            result: {
              items: result.items || [],
              moreAvailable: result?.moreAvailable || false,
              nextOffset: result?.nextOffset || 0,
              timeMs: Date.now(),
            },
          };
          if (payload.isCsvSearch) {
            return payload.voffset
              ? licencesActions.addSoftwareLicencesCsv(data)
              : licencesActions.setSoftwareLicencesCsv(data);
          }
          return payload.voffset
            ? licencesActions.addSoftwareLicences(data)
            : licencesActions.setSoftwareLicences(data);
        } catch (e) {
          console.log('Exception in SearchSoftwareLicencesThunkAction ' + e);
        }
      })
      .catch((error: any) => {
        console.log('API call error: ' + error);
        const data = {
          result: {
            items: [],
            moreAvailable: false,
            nextOffset: 0,
            timeMs: Date.now(),
          },
        };
        if (payload.isCsvSearch) {
          return payload.voffset
            ? licencesActions.addSoftwareLicencesCsv(data)
            : licencesActions.setSoftwareLicencesCsv(data);
        }
        return payload.voffset
          ? licencesActions.addSoftwareLicences(data)
          : licencesActions.setSoftwareLicences(data);
      });
  });
};
