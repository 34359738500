import { getApiv14 } from './apiv14Setup';

export interface StatisticsAddRequest {
  bookID: string;
  userID: string;
  page: number;
  timeClosed: Date;
  timeOpened: Date;
}
export const addStatistics = (req: StatisticsAddRequest[]) =>
  getApiv14().post('/add/statistics', req);
