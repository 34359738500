import C from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { TestingProps } from '../../Types/Testing';

/**
 * Button properties.
 */
export interface IButtonProps extends TestingProps {
  /** This is an example comment that will show up in the docs. */
  variant?: string;
  transparent?: boolean;
  size?: 'sm' | 'lg';
  id?: string;
  rounded?: boolean;
  outline?: boolean;
  active?: boolean;
  title?: string;
  disabled?: boolean;
  block?: boolean;
  className?: string;
  close?: boolean;
  icon?: boolean;
  onClick?: (
    e?: React.MouseEvent<HTMLButtonElement> | undefined
  ) => void | undefined;
  onMouseEnter?: (
    e?: React.MouseEvent<HTMLButtonElement> | undefined
  ) => void | undefined;
  onMouseLeave?: (
    e?: React.MouseEvent<HTMLButtonElement> | undefined
  ) => void | undefined;
  children?: React.ReactNode;
  submit?: boolean;
  /** This prop will convert the Button to an \<Link\/\> tag with the button styles. */
  to?: string;
  /** This prop will convert the Button to an \<a\/\> tag with the button styles. */
  href?: string;
  /** This prop is to be used in conjunction with href to name the file that is downloaded if a data: url is given to href. */
  download?: string;
  style?: React.CSSProperties;
  color?: string;
}

const sizeMapper: { [index: string]: string } = {
  lg: 'btn-lg',
  sm: 'btn-sm',
};

const variantMapper = (
  outline: boolean | undefined
): { [index: string]: string } => {
  if (outline) {
    return {
      primary: 'btn-outline-primary',
      secondary: 'btn-outline-secondary',
      success: 'btn-outline-success',
      info: 'btn-outline-info',
      warning: 'btn-outline-warning',
      danger: 'btn-outline-danger',
      light: 'btn-outline-light',
      dark: 'btn-outline-dark',
      white: 'btn-outline-white',
    };
  } else {
    return {
      primary: 'btn-primary',
      secondary: 'btn-secondary',
      success: 'btn-success',
      info: 'btn-info',
      warning: 'btn-warning',
      danger: 'btn-danger',
      light: 'btn-light',
      dark: 'btn-dark',
      white: 'btn-white',
    };
  }
};

const colorMapper: { [color: string]: string } = {
  primary: 'text-primary',
  secondary: 'text-secondary',
  success: 'text-success',
  warning: 'text-warning',
  danger: 'text-danger',
  info: 'text-info',
  light: 'text-light',
  dark: 'text-dark',
};

/**
 * Button Something Something Something
 */
export const Button = React.forwardRef<HTMLButtonElement, IButtonProps>(
  (
    {
      variant = 'primary',
      icon,
      id,
      size,
      children,
      rounded,
      outline,
      block,
      active,
      title,
      disabled,
      onClick,
      onMouseEnter,
      onMouseLeave,
      className,
      close,
      submit,
      to,
      href,
      download,
      testId,
      style,
      color,
    },
    ref
  ) => {
    return href ? (
      <a
        href={href}
        download={download}
        className={C(
          'btn',
          className && className,
          variant && variantMapper(outline)[variant],
          size && sizeMapper[size],
          rounded && 'btn-rounded-circle',
          active && 'active',
          disabled && 'disabled',
          block && 'btn-block',
          icon && 'btn-icon'
        )}
        id={id}
        title={title}
        data-testid={testId}
        style={style}
      >
        {children}
      </a>
    ) : to ? (
      <Link
        to={to}
        id={id}
        className={C(
          'btn',
          className && className,
          variant && variantMapper(outline)[variant],
          size && sizeMapper[size],
          rounded && 'btn-rounded-circle',
          active && 'active',
          disabled && 'disabled',
          block && 'btn-block',
          icon && 'btn-icon'
        )}
        data-testid={testId}
        style={style}
      >
        {children}
      </Link>
    ) : !close ? (
      <button
        type={submit ? 'submit' : 'button'}
        id={id}
        className={C(
          'btn',
          className && className,
          variant && variantMapper(outline)[variant],
          size && sizeMapper[size],
          rounded && 'btn-rounded-circle',
          active && 'active',
          disabled && 'disabled',
          block && 'btn-block',
          icon && 'btn-icon',
          color && colorMapper[color]
        )}
        disabled={disabled}
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        data-testid={testId}
        style={style}
        ref={ref}
      >
        {children}
      </button>
    ) : (
      <button
        id={id}
        style={{
          ...style,
          fontSize: size === 'sm' && 'inherit',
          marginLeft: size === 'sm' && '2px',
        }}
        disabled={disabled}
        type={submit ? 'submit' : 'button'}
        className={C('close', className && className, size && sizeMapper[size])}
        aria-label="Close"
        onClick={onClick}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        ref={ref}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    );
  }
);
