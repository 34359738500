import {
  StreamImportRule,
  StreamImportRulePerInstitution,
} from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';
export interface StreamData {
  institution: string;
  persons: Array<{
    email: string;
    role: string;
    firstName: string;
    lastName: string;
    yearLevel?: string | null;
    parentEmail?: string | null;
  }>;
  classes: Array<{
    code: string;
    description?: string | null;
  }>;
  memberships: Array<{
    email: string;
    classCode: string;
  }>;
}
export const streamExport = <CSVOutput extends boolean>(params: {
  apiKey: string;
  institution: string;
  csvOutput: CSVOutput;
}) =>
  getApiv14().get<{
    result: (CSVOutput extends true ? string : StreamData) | { error: string };
  }>(`/stream/export`, {
    params,
  });

export const streamImport = (
  params: {
    apiKey: string;
    jobId?: string;
    canCreatePersons?: boolean;
    canCreateClasses?: boolean;
    canDeletePersons?: boolean;
    canDeleteClasses?: boolean;
    studentDeletionIsPermanent?: boolean;
    teacherDeletionIsPermanent?: boolean;
    defaultStudentPassword?: string;
    defaultTeacherPassword?: string;
  },
  body: StreamData
) =>
  getApiv14().post(`/stream/import`, body, {
    params,
  });

// all rules
export const searchAdvancedStreamImportRuleMeta = (
  req: {
    limit: number;
    offset: number;
    order: string;
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/stream_import_rule_meta', req, config);

// rules per institution
export const searchAdvancedStreamImportRule = (
  req: {
    limit: number;
    offset: number;
    order: string;
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/stream_import_rule', req, config);

export const addStreamImportRule = (
  req: Partial<StreamImportRule>,
  config?: AxiosRequestConfig
) => getApiv14().post('/stream_import_rule_meta', [req], config);

export const updateStreamImportRule = (
  ruleId: string,
  req: Partial<StreamImportRule>,
  config?: AxiosRequestConfig
) => getApiv14().patch('/stream_import_rule_meta/', [req], config);

export const deleteStreamImportRule = (
  ruleId: string,
  config?: AxiosRequestConfig
) => getApiv14().delete('/stream_import_rule_meta/' + ruleId, { ...config });

export const addStreamImportRulePerInstitution = (
  req: Partial<StreamImportRulePerInstitution>,
  config?: AxiosRequestConfig
) => getApiv14().post('/stream_import_rule', [req], config);

export const updateStreamImportRulePerInstitution = (
  req: Partial<StreamImportRulePerInstitution>,
  config?: AxiosRequestConfig
) => getApiv14().patch('/stream_import_rule', [req], config);

export const deleteStreamImportRulePerInstitution = (
  ruleId: string,
  config?: AxiosRequestConfig
) => getApiv14().delete('/stream_import_rule/' + ruleId, { ...config });
