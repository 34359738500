import { Comment } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddConnectedCommentThunkAction,
  DeleteConnectedCommentThunkAction,
  GetConnectedCommentsThunkAction,
  GetDeltaConnectedCommentsThunkAction,
  UpdateConnectedCommentThunkAction,
} from './thunk';
import { CommentsState } from './types';

const initialState: CommentsState = {
  comments: [],
};

const name = 'comment';

const asyncActions = {
  getComments: createAsyncThunk(`${name}/get`, GetConnectedCommentsThunkAction),
  getDeltaComments: createAsyncThunk(
    `${name}/delta`,
    GetDeltaConnectedCommentsThunkAction
  ),
  addComment: createAsyncThunk(`${name}/add`, AddConnectedCommentThunkAction),
  updateComment: createAsyncThunk(
    `${name}/update`,
    UpdateConnectedCommentThunkAction
  ),
  deleteComment: createAsyncThunk(
    `${name}/delete`,
    DeleteConnectedCommentThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setComments(state, action: PayloadAction<Comment[]>) {
      state.comments = action.payload;
    },
    addComments(state, action: PayloadAction<Comment[]>) {
      state.comments.push(...action.payload);
    },
    deltaComments(state, action: PayloadAction<Comment[]>) {
      //keep track of comment we're updating.
      const updatedCommentIds = [];

      action.payload.forEach((newUpdatedComment) => {
        //find and replace.
        const index = state.comments.findIndex(
          (comment) => comment.id === newUpdatedComment.id
        );
        if (index >= 0) {
          if (!newUpdatedComment.deleted) {
            //replace comment
            state.comments[index] = newUpdatedComment;
          } else {
            //delete comment
            state.comments.splice(index, 1);
          }
          updatedCommentIds.push(newUpdatedComment.id);
        }
      });

      //filter the annos we have already updated.
      const newComments = action.payload.filter(
        (comment) => !updatedCommentIds.includes(comment.id) && !comment.deleted
      );

      //add the rest to state.
      state.comments.unshift(...newComments);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getComments.fulfilled, (state, action) => {
      state.comments = action.payload;
    });
    builder.addCase(
      asyncActions.getDeltaComments.fulfilled,
      (state, action) => {
        {
          //keep track of annos we're updating.
          const updatedCommentsIds = [];

          action.payload.forEach((newUpdatedComment) => {
            //find and replace.
            const index = state.comments.findIndex(
              (anno) => anno.id === newUpdatedComment.id
            );
            if (index >= 0) {
              if (!newUpdatedComment.deleted) {
                //replace anno
                state.comments[index] = newUpdatedComment;
                console.log('updating anno:');
              } else {
                //delete anno
                state.comments.splice(index, 1);
              }
              updatedCommentsIds.push(newUpdatedComment.id);
            }
          });

          //filter the annos we have already updated.
          const newComments = action.payload.filter(
            (anno) => !updatedCommentsIds.includes(anno.id) && !anno.deleted
          );

          //add the rest to state.
          state.comments.push(...newComments);
        }
      }
    );
    builder.addCase(asyncActions.addComment.fulfilled, (state, action) => {
      state.comments.push(action.payload);
    });
    builder.addCase(asyncActions.deleteComment.fulfilled, (state, action) => {
      const removeComment = state.comments.find(
        (comment) => comment.id === action.payload
      );
      state.comments = state.comments.filter(
        (comment) => comment.id !== removeComment.id
      );
    });
    builder.addCase(asyncActions.updateComment.fulfilled, (state, action) => {
      //find the index in comments array
      const commentsIndex = state.comments.findIndex(
        (comment) => comment.id === action.payload.id
      );
      if (commentsIndex !== -1) {
        state.comments[commentsIndex] = action.payload;
      }
    });
  },
});

const { actions, reducer } = slice;

export const commentsReducer = reducer;

export const commentsActions = { ...actions, asyncActions };
