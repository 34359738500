import { DTO } from '@readcloud/common';
import { getApiv14 } from './apiv14Setup';

export const addAnnotation = (annotation: DTO.CreateAnnotation) =>
  getApiv14().post('/add/annotation', annotation);
export const updateAnnotation = (
  id: string,
  annotation: DTO.UpdateAnnotation
) => {
  const { authorID, bookID, ...body } = annotation;

  return getApiv14().put('/update/annotation', body, { params: { id } });
};

export const listAnnotations = () => getApiv14().get('/list/annotation.json');

export const deleteAnnotation = (id: string) =>
  getApiv14().delete('/delete/annotation', { params: { id } });

export const deltaAnnotations = (millisec: number) =>
  getApiv14().get<{
    annotation: DTO.Annotation[];
  }>('/delta/annotations', { params: { millisec } });
