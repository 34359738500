import { TextConfig } from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export const getTextConfigs = (config?: AxiosRequestConfig) =>
  getApiv14().get('/text_config/all', {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const updateTextConfig = (
  uid: string,
  req: Partial<TextConfig>,
  config?: AxiosRequestConfig
) => getApiv14().patch('/text_config/' + uid, req, config);

export const createTextconfig = (
  req: Partial<TextConfig>,
  config?: AxiosRequestConfig
) => getApiv14().post('/text_config', [req], config);

export const deleteTextConfig = (uid: string, config?: AxiosRequestConfig) =>
  getApiv14().delete('/text_config/' + uid, config);
