import { ProductBundle } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { BundlesState } from './types';

import {
  AddProductBundleThunkAction,
  DeleteProductBundleThunkAction,
  GetProductBundlesThunkAction,
  UpdateProductBundleThunkAction,
} from './thunk';

const initialState: BundlesState = {
  bundles: {},
};

const name = 'product_bundle';

const asyncActions = {
  getProductBundles: createAsyncThunk(
    `${name}/get`,
    GetProductBundlesThunkAction
  ),
  addProductBundle: createAsyncThunk(
    `${name}/add`,
    AddProductBundleThunkAction
  ),
  updateProductBundle: createAsyncThunk(
    `${name}/update`,
    UpdateProductBundleThunkAction
  ),
  deleteProductBundle: createAsyncThunk(
    `${name}/delete`,
    DeleteProductBundleThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    clearBundles(state) {
      state.bundles = {};
    },
    setBundles(state, action: PayloadAction<Record<string, ProductBundle>>) {
      state.bundles = action.payload;
    },
    addBundles(state, action: PayloadAction<Record<string, ProductBundle>>) {
      state.bundles = {
        ...state.bundles,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      asyncActions.addProductBundle.fulfilled,
      (state, action) => {
        state.bundles[action.payload.id] = action.payload;
      }
    );
    builder.addCase(
      asyncActions.getProductBundles.fulfilled,
      (state, action) => {
        state.bundles = action.payload;
      }
    );
    builder.addCase(
      asyncActions.deleteProductBundle.fulfilled,
      (state, action) => {
        delete state.bundles[action.payload];
      }
    );
    builder.addCase(
      asyncActions.updateProductBundle.fulfilled,
      (state, action) => {
        state.bundles[action.payload.id] = {
          ...state.bundles[action.payload.id],
          ...action.payload,
        };
      }
    );
  },
});

const { actions, reducer } = slice;

export const bundlesReducer = reducer;

export const bundlesActions = { ...actions, asyncActions };
