import {
  AnnotationListControlActions,
  DocumentControlActions,
} from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  UpdateEpubPagesThunkAction,
  UpdateViewStateThunkAction,
} from './thunk';
import { ReaderState, ViewState } from './types';
import { trackEvent } from '@root/libs/common/src';
import { tryCatchAndNotify } from '@root/libs/common/src/lib/rum';

const initialState: ReaderState = {
  readerData: {
    title: '',
    bookId: '',
    cloudId: '',
    fileUrl: '',
    locationIndexStart: 0,
    locationIndexEnd: 0,
    loadingPageLabels: true,
    numPages: 0,
    lockScroll: false,
    pageLabels: undefined,
    pageLabelsByLocation: undefined,
    outline: null,
    printMode: false,
    bookOptions: {
      printable: false,
    },
    hasNumPageBeenSet: false,
    hasBookOptBeenSet: false,
    viewState: {
      annotationFilter: '',
      annotationFilterUsers: undefined,
      epubFont: 'sans-serif',
    },
  },
  documentControl: null,
  annotationListControl: null,
};

const name = 'reader';

const asyncActions = {
  updateViewState: createAsyncThunk(
    `${name}/updateViewState`,
    UpdateViewStateThunkAction
  ),
  updateEpubPages: createAsyncThunk(
    `${name}/updateEpubPages`,
    UpdateEpubPagesThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateReaderData(
      state,
      action: PayloadAction<Partial<ReaderState['readerData']>>
    ) {
      state.readerData = { ...state.readerData, ...action.payload };
    },
    updateLocalViewState(state, action: PayloadAction<Partial<ViewState>>) {
      state.readerData.viewState = {
        ...state.readerData.viewState,
        ...action.payload,
      };
    },
    resetReaderData(state) {
      state.readerData = {
        title: '',
        bookId: '',
        cloudId: '',
        fileUrl: '',
        locationIndexStart: 0,
        locationIndexEnd: 0,
        loadingPageLabels: true,
        numPages: 0,
        lockScroll: false,
        pageLabels: undefined,
        pageLabelsByLocation: undefined,
        outline: null,
        printMode: false,
        bookOptions: {
          printable: false,
        },
        hasNumPageBeenSet: false,
        hasBookOptBeenSet: false,
        viewState: {
          annotationFilter: undefined,
          annotationFilterUsers: undefined,
          location: '1',
          pageViewMode: 'OnePage',
          pageNumberStart: 1,
          pageNumberEnd: 1,
          scale: 1,
          epubFont: 'sans-serif',
        },
      };
    },
    updateDocumentControl(
      state,
      action: PayloadAction<DocumentControlActions>
    ) {
      tryCatchAndNotify(() => {
        if (action.payload.type === 'gotoToc') {
          trackEvent('Webapp/NavigatedUsingTOC', {
            destination: action.payload.payload.dest,
            tocTitle: action.payload.payload.title,
            tocSubtitle: action.payload.payload.subtitle,
            newWindow: action.payload.payload.newWindow,
            bookId: state.readerData.bookId,
            cloudId: state.readerData.cloudId,
            visiblePageStart: state.readerData.viewState?.pageNumberStart,
            visiblePageEnd: state.readerData.viewState?.pageNumberEnd,
          });
        }
      });

      state.documentControl = action.payload;
    },
    updateAnnotationListControl(
      state,
      action: PayloadAction<AnnotationListControlActions>
    ) {
      state.annotationListControl = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      asyncActions.updateViewState.fulfilled,
      (state, action: PayloadAction<Partial<ViewState>>) => {
        state.readerData.viewState = {
          ...(state.readerData.viewState || {}),
          ...action.payload,
        };
      }
    );
    builder.addCase(
      asyncActions.updateEpubPages.fulfilled,
      (state, action: PayloadAction<{ bookId: string; epubPages: string }>) => {
        state.readerData.bookOptions = {
          ...(state.readerData.bookOptions || {}),
          epubPages: action.payload?.epubPages,
        };
      }
    );
  },
});

const { actions, reducer } = slice;

export const readerReducer = reducer;
export const readerActions = { ...actions, asyncActions };
