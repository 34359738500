import { Bookmark } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddConnectedBookmarkThunkAction,
  DeleteConnectedBookmarkThunkAction,
  GetConnectedBookmarksThunkAction,
  GetDeltaConnectedBookmarksThunkAction,
  UpdateConnectedBookmarkThunkAction,
} from './thunk';
import { BookmarksState } from './types';

const initialState: BookmarksState = {
  bookmarks: [],
  bookmarkPageLabels: {},
};

// const name = 'bookmarks';

const name = 'bookmarks';

const asyncActions = {
  getBookmarks: createAsyncThunk(
    `${name}/get`,
    GetConnectedBookmarksThunkAction
  ),
  getDeltaBookmarks: createAsyncThunk(
    `${name}/delta`,
    GetDeltaConnectedBookmarksThunkAction
  ),
  addBookmark: createAsyncThunk(`${name}/add`, AddConnectedBookmarkThunkAction),
  updateBookmark: createAsyncThunk(
    `${name}/update`,
    UpdateConnectedBookmarkThunkAction
  ),
  deleteBookmark: createAsyncThunk(
    `${name}/delete`,
    DeleteConnectedBookmarkThunkAction
  ),
};

// const asyncActions = {
//   getBookmarks: createAsyncActions<void, Bookmark[]>(`${name}/getBookmarks`),
//   addBookmark: createAsyncActions<Bookmark, Bookmark>(`${name}/addBookmark`),
//   deleteBookmark: createAsyncActions<string, string>(`${name}/deleteBookmark`),
// };

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setBookmarks(state, action: PayloadAction<Bookmark[]>) {
      state.bookmarks = action.payload;
    },
    addBookmarks(state, action: PayloadAction<Bookmark[]>) {
      state.bookmarks.unshift(...action.payload);
    },
    deltaBookmarks(state, action: PayloadAction<Bookmark[]>) {
      //keep track of bookmark we're updating.
      const updatedBookmarkIds = [];

      action.payload.forEach((newUpdatedBookmark) => {
        //find and replace.
        const index = state.bookmarks.findIndex(
          (bookmark) => bookmark.id === newUpdatedBookmark.id
        );
        if (index >= 0) {
          if (!newUpdatedBookmark.deleted) {
            //replace bookmark
            state.bookmarks[index] = newUpdatedBookmark;
          } else {
            //delete bookmark
            state.bookmarks.splice(index, 1);
          }
          updatedBookmarkIds.push(newUpdatedBookmark.id);
        }
      });

      //filter the annos we have already updated.
      const newBookmarks = action.payload.filter(
        (bookmark) =>
          !updatedBookmarkIds.includes(bookmark.id) && !bookmark.deleted
      );

      //add the rest to state.
      state.bookmarks.unshift(...newBookmarks);
    },
    setBookmarkPageLabels(
      state,
      action: PayloadAction<{ [id: string]: string }>
    ) {
      state.bookmarkPageLabels = action.payload;
    },

    updateBookmarkPageLabels(
      state,
      action: PayloadAction<{ id: string; label: string }>
    ) {
      state.bookmarkPageLabels[action.payload.id] = action.payload.label;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getBookmarks.fulfilled, (state, action) => {
      state.bookmarks = action.payload;
    });
    builder.addCase(
      asyncActions.getDeltaBookmarks.fulfilled,
      (state, action) => {
        //keep track of bookmark we're updating.
        const updatedBookmarkIds = [];

        action.payload.forEach((newUpdatedBookmark) => {
          //find and replace.
          const index = state.bookmarks.findIndex(
            (bookmark) => bookmark.id === newUpdatedBookmark.id
          );
          if (index >= 0) {
            if (!newUpdatedBookmark.deleted) {
              //replace bookmark
              state.bookmarks[index] = newUpdatedBookmark;
            } else {
              //delete bookmark
              state.bookmarks.splice(index, 1);
            }
            updatedBookmarkIds.push(newUpdatedBookmark.id);
          }
        });

        //filter the annos we have already updated.
        const newBookmarks = action.payload.filter(
          (bookmark) =>
            !updatedBookmarkIds.includes(bookmark.id) && !bookmark.deleted
        );

        //add the rest to state.
        state.bookmarks.unshift(...newBookmarks);
      }
    );
    builder.addCase(asyncActions.addBookmark.fulfilled, (state, action) => {
      state.bookmarks.push(action.payload);
    });
    builder.addCase(asyncActions.deleteBookmark.fulfilled, (state, action) => {
      state.bookmarks = state.bookmarks.filter(
        (bookmarks) => bookmarks.id !== action.payload
      );
    });
  },
});

const { actions, reducer } = slice;

export const bookmarksReducer = reducer;

export const bookmarksActions = { ...actions, asyncActions };
