import { ObjectId } from 'bson';
import * as t from 'io-ts';
import { withFallback } from 'io-ts-types/lib/withFallback';
import { DeepPartial } from 'ts-essentials';

// Updating this will cause all new annotations to have this as the version field
export const CURRENT_VERSION = '3';
export interface AnnotationsState {
  annotations: Annotation[];
}

export const annotationRect = t.type({
  width: t.number,
  height: t.number,
  x: t.number,
  y: t.number,
});

export const annotationOptionsType = t.union([
  t.undefined,
  t.nullType,
  t.literal('Publisher'),
]);
export const annotationOptionsStatus = t.union([
  t.undefined,
  t.nullType,
  t.literal('Draft'),
  t.literal('Published'),
]);
export const annotationType = t.union([
  t.literal('Path'),
  t.literal('Rectangle'),
  t.literal('OverlayText'),
  t.literal('Circle'),
  t.literal('HTML'),
  t.literal('Media'),
  t.literal('Image'),
  t.literal('Text'),
]);
export const annotationVersion = t.union([t.undefined, t.null, t.string]);
export const annotationBookType = t.union([
  t.literal('EPUB'),
  t.literal('PDF'),
  t.literal('MEDIA'),
]);
export const annotationCommon = t.intersection([
  t.type({
    id: t.string,
    created: t.string,
    updated: t.string,
    clientCreated: withFallback(t.union([t.string, t.undefined]), undefined),
    clientUpdated: withFallback(t.union([t.string, t.undefined]), undefined),
    deleted: t.boolean,
    visibleToUsers: t.array(t.string),
    visibleInClouds: t.array(t.string),
    author: t.string,
    color: t.string,
    book: t.string,
    bookType: annotationBookType,
    location: t.type({
      pageNumber: t.number,
    }),
    version: annotationVersion,
    selectedText: t.string,
  }),
  t.partial({
    tags: withFallback(t.union([t.array(t.string), t.null, t.undefined]), []),
    options: withFallback(
      t.type({
        type: annotationOptionsType,
        status: annotationOptionsStatus,
      }),
      {
        type: undefined,
        status: undefined,
      }
    ),
  }),
]);

export const commonData = t.type({
  data: t.partial({
    description: t.string,
    scaleWithPage: t.boolean,
    belowContent: t.boolean,
  }),
});
export const boundingRectData = t.type({
  rect: annotationRect,
});

export const textHighlightAnnotation = t.intersection([
  annotationCommon,
  t.type({
    location: t.type({
      range: t.string,
    }),
    type: t.literal('Text'),
  }),
  t.partial({
    text: t.union([t.string, t.null]),
    data: t.undefined,
  }),
]);

export const mediaAnnotation = t.intersection([
  annotationCommon,
  commonData,
  t.type({
    type: t.literal('Media'),
    data: t.intersection([
      boundingRectData,
      t.type({
        url: t.string,
        variant: t.union([t.literal('Embedded'), t.literal('Icon')]),
      }),
    ]),
  }),
]);

export const htmlAnnotation = t.intersection([
  annotationCommon,
  commonData,
  t.type({
    type: t.literal('HTML'),
    data: t.intersection([
      boundingRectData,
      t.type({
        html: t.string,
        variant: t.union([t.literal('Embedded'), t.literal('Icon')]),
      }),
    ]),
  }),
]);

export const imageAnnotation = t.intersection([
  annotationCommon,
  commonData,
  t.type({
    type: t.literal('Image'),
    data: t.intersection([
      boundingRectData,
      t.type({
        url: t.string,
      }),
    ]),
  }),
]);

export const commonShapeData = t.type({
  fill: t.string,
});
export const shapeAnnotationData = t.type({
  stroke: withFallback(t.string, '#00000000'),
  strokeWidth: withFallback(t.number, 0),
});

export const rectangleAnnotation = t.intersection([
  annotationCommon,
  commonData,
  t.type({
    type: t.literal('Rectangle'),
    data: t.intersection([
      boundingRectData,
      commonShapeData,
      shapeAnnotationData,
    ]),
  }),
]);
export const overlayTextAnnotation = t.intersection([
  annotationCommon,
  commonData,
  t.type({
    type: t.literal('OverlayText'),
    data: t.intersection([
      boundingRectData,
      commonShapeData,
      t.type({
        text: t.string,
        lineHeight: withFallback(t.number, 1),
        overflowWrap: withFallback(
          t.union([t.literal('break-word'), t.literal('normal')]),
          'normal'
        ),
        wordBreak: withFallback(
          t.union([
            t.literal('break-all'),
            t.literal('keep-all'),
            t.literal('normal'),
          ]),
          'normal'
        ),
        textAlign: withFallback(
          t.union([t.literal('center'), t.literal('left'), t.literal('right')]),
          'left'
        ),
        fontFamily: withFallback(t.string, 'Arial'),
        fontSize: withFallback(t.number, 24),
        fontWeight: withFallback(t.number, 400),
      }),
    ]),
  }),
]);
export const circleAnnotation = t.intersection([
  annotationCommon,
  commonData,
  t.type({
    type: t.literal('Circle'),
    data: t.intersection([
      boundingRectData,
      commonShapeData,
      shapeAnnotationData,
    ]),
  }),
]);

export const point = t.type({
  x: t.number,
  y: t.number,
});
export const path = t.type({
  points: t.array(point),
});
export const pathAnnotation = t.intersection([
  annotationCommon,
  commonData,
  t.type({
    type: t.literal('Path'),
    data: t.intersection([
      boundingRectData,
      t.type({
        stroke: t.string,
        strokeWidth: t.number,
        paths: t.array(path),
      }),
    ]),
  }),
]);

export const annotation = t.union([
  mediaAnnotation,
  htmlAnnotation,
  imageAnnotation,
  rectangleAnnotation,
  circleAnnotation,
  overlayTextAnnotation,
  pathAnnotation,
]);

export const annotationOrHighlight = t.union([
  annotation,
  textHighlightAnnotation,
]);
export type Annotation = t.TypeOf<typeof annotation>;
export type AnnotationOrHighlight = t.TypeOf<typeof annotationOrHighlight>;
export type MediaAnnotation = t.TypeOf<typeof mediaAnnotation>;
export type HTMLAnnotation = t.TypeOf<typeof htmlAnnotation>;
export type ImageAnnotation = t.TypeOf<typeof imageAnnotation>;
export type RectangleAnnotation = t.TypeOf<typeof rectangleAnnotation>;
export type CircleAnnotation = t.TypeOf<typeof circleAnnotation>;
export type PathAnnotation = t.TypeOf<typeof pathAnnotation>;
export type OverlayTextAnnotation = t.TypeOf<typeof overlayTextAnnotation>;
export type TextHighlightAnnotation = t.TypeOf<typeof textHighlightAnnotation>;
export type AnnotationRect = t.TypeOf<typeof annotationRect>;
export type AnnotationType = t.TypeOf<typeof annotationType>;
export type Path = t.TypeOf<typeof path>;
export type Point = t.TypeOf<typeof point>;

export const mediaVariants: MediaAnnotation['data']['variant'][] = [
  'Embedded',
  'Icon',
];

export const htmlVariants: HTMLAnnotation['data']['variant'][] = [
  'Embedded',
  'Icon',
];
export type KnownUser = {
  firstName: string;
  lastName: string;
  email: string;
  id: string;
};

export type KnownCloud = { name: string; institution: string; id: string };

export const createEmptyAnnotation = ({
  author,
  book,
  color,
  pageNumber,
  rect,
  type,
  annoPrefs,
}: {
  author: string;
  book: string;
  color: string;
  pageNumber: number;
  rect: AnnotationRect;
  type: Annotation['type'];
  annoPrefs?: { [index: string]: Annotation['data'] };
}): Annotation => {
  const common = {
    location: {
      pageNumber,
    },
    author,
    book,
    color,
    version: '3' as const,
    selectedText: '',
  };

  const preferences = annoPrefs && annoPrefs[type];

  switch (type) {
    case 'Media':
      return annotationContainers[type].create({
        ...common,
        data: {
          variant: 'Embedded',
          ...preferences, // anything above this will get replaced if a preference exists for it.
          description: '',
          rect,
          url: '',
          scaleWithPage: true,
        },
      });

    case 'HTML':
      return annotationContainers[type].create({
        ...common,
        data: {
          variant: 'Embedded',
          ...preferences, // anything above this will get replaced if a preference exists for it.
          description: '',
          html: '',
          rect,
          scaleWithPage: true,
        },
      });
    case 'Image':
      return annotationContainers[type].create({
        ...common,
        data: {
          ...preferences, // anything above this will get replaced if a preference exists for it.
          description: '',
          rect,
          url: '',
          scaleWithPage: true,
        },
      });
    case 'Circle':
      return annotationContainers[type].create({
        ...common,
        data: {
          fill: color,
          stroke: '#00000000',
          strokeWidth: 0,
          ...preferences, // anything above this will get replaced if a preference exists for it.
          rect,
          scaleWithPage: true,
          belowContent: true,
        },
      });
    case 'Rectangle':
      return annotationContainers[type].create({
        ...common,
        data: {
          fill: color,
          stroke: '#00000000',
          strokeWidth: 0,
          ...preferences, // anything above this will get replaced if a preference exists for it.
          rect,
          scaleWithPage: true,
          belowContent: true,
        },
      });
    case 'OverlayText':
      return annotationContainers[type].create({
        ...common,
        data: {
          fill: '#000000',
          textAlign: 'left',
          fontFamily: 'Arial',
          fontSize: 16,
          fontWeight: 400,
          lineHeight: 1,
          overflowWrap: 'normal',
          wordBreak: 'normal',
          ...preferences,
          text: 'Enter Text',
          rect,
          scaleWithPage: true,
          belowContent: true,
        },
      });
    case 'Path':
      return annotationContainers[type].create({
        ...common,
        data: {
          strokeWidth: 4,
          stroke: '#000000',
          ...preferences,
          rect,
          paths: [],
          scaleWithPage: true,
          belowContent: true,
        },
      });
  }
};

type AnnotationCommon = t.TypeOf<typeof annotationCommon>;

class AnnotationContainer<T extends Annotation> {
  constructor(
    public readonly type: T['type'],
    public readonly ioType: t.Type<T>,
    public readonly config: {
      requiresCreationForm: boolean;
      minWidth: number;
      minHeight: number;
    },
    public readonly defaultData?: DeepPartial<T['data']>
  ) {}
  public create = (params: {
    author: string;
    book: string;
    color: string;
    location: T['location'];
    data: T['data'];
    version?: T['version'];
    selectedText?: string;
    tags?: string[];
    options?: AnnotationCommon['options'];
  }) => ({
    created: new Date().toISOString(),
    updated: new Date().toISOString(),
    clientCreated: new Date().toISOString(),
    clientUpdated: new Date().toISOString(),
    id: new ObjectId().valueOf().toString(),
    visibleInClouds: [],
    visibleToUsers: [],
    deleted: false,
    type: this.type,
    options: params.options,
    author: params.author,
    book: params.book,
    tags: params.tags || [],
    data: params.data,
    color: params.color,
    location: params.location,
    version: params.version || CURRENT_VERSION,
    selectedText: params.selectedText || '',
    bookType: 'PDF' as const,
  });
}

export const annotationContainers = {
  Path: new AnnotationContainer('Path', pathAnnotation, {
    requiresCreationForm: false,
    minHeight: 50,
    minWidth: 50,
  }),
  Circle: new AnnotationContainer('Circle', circleAnnotation, {
    requiresCreationForm: false,
    minHeight: 50,
    minWidth: 50,
  }),
  OverlayText: new AnnotationContainer('OverlayText', overlayTextAnnotation, {
    requiresCreationForm: false,
    minHeight: 30,
    minWidth: 100,
  }),
  Rectangle: new AnnotationContainer('Rectangle', rectangleAnnotation, {
    requiresCreationForm: false,
    minHeight: 10,
    minWidth: 10,
  }),
  HTML: new AnnotationContainer('HTML', htmlAnnotation, {
    requiresCreationForm: true,
    minHeight: 50,
    minWidth: 50,
  }),
  Image: new AnnotationContainer('Image', imageAnnotation, {
    requiresCreationForm: true,
    minHeight: 100,
    minWidth: 100,
  }),
  Media: new AnnotationContainer('Media', mediaAnnotation, {
    requiresCreationForm: true,
    minHeight: 50,
    minWidth: 50,
  }),
} as const;

/*   export const match = <V extends t.Mixed, CS extends [V, V, ...V[]], R>(
  patterns: t.UnionC<CS>
) => (matchers: { [k in Extract<CS, number>]: (pattern: CS[k]) => R }) => (
  value: V
) => {
  const index = patterns.types.findIndex((t, i) => t.is(value));
  if (index < 0) {
    throw new Error("Incorrect pattern error");
  } else {
    return (matchers as any)[index](value);
  }
}; */
