import { joinPermissions } from '../../constants/permissions';
const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

export const GetResellerApiKeysThunkAction = async (
  payload: void,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/v14/get_api_keys', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response?.json().then(({ result }) => result));
};

export const UpdateAuthThunkAction = async (data: any) => {
  const userData = data['user'];
  const userRole = userData['role'] || 'Student';
  const userScopes =
    userData['userScopes'] || userData['userScope']?.split(' ') || [];

  // temporarily call function here. The data this function spits out should already come from the WAR.

  const permissions = joinPermissions(userRole, userScopes);

  const userInstitution = userData['institution'];

  const userResellerId = userData['resellerId'];

  return {
    ...data,
    permissions: permissions,
    institution: userInstitution,
    resellerId: userResellerId,
  };
};
export const GetUserScopeParamsThunkAction = async (
  payload: void,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/v14/user_scope_params', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response?.json().then(({ result }) => result));
};
