/**
 * Puts a very obvious button on the screen that allows users to provide feedback.
 * Only users with the `BetaAppFeedback` permission will see this button.
 *
 * If this feature becomes widely used and important, we should consider changing the feedback URL to something more semantic and less likely to change.
 */
import { isIOS } from '@readcloud/common';
import {
  Button,
  Input,
  Label,
  Modal,
  ModalBody,
  Tab,
  Tabs,
  TextArea,
} from '@readcloud/component-library';
import { useCallback, useState } from 'react';
import ReactDOM from 'react-dom';
import { useForm } from 'react-hook-form';

const feedbackUrl =
  'https://5t3mqkt2t2.execute-api.ap-southeast-2.amazonaws.com/add-feedback';

function blobToBase64(blob) {
  return new Promise<string>((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () =>
      // @ts-expect-error - reader.result is definitely a string because we did `readAsDataURL`. Types of the library are not idiomatic TS.
      resolve(reader.result.replace('data:image/png;base64,', ''));
    reader.readAsDataURL(blob);
  });
}
const captureScreen = async () => {
  const html2canvas = await import('html2canvas');
  const compress = await import('browser-image-compression');
  const canvas = await html2canvas.default(document.body, {
    scale: 0.5,
    useCORS: true,
    ignoreElements: (el) =>
      el.id === 'modal-root' || el.id === 'feedback-button',
  });

  const blob = await new Promise<Blob>((res) => canvas.toBlob(res));
  // @ts-expect-error - types of browser-image-compression are wrong
  const compressedBlob = await compress.default(blob, { maxSizeMB: 1 });

  return compressedBlob;
};

/**
 * Use this to display, as a fixed DOM element, overlay UI that allows users to provide feedback.
 * Only show this to the users that you want to provide feedback, such as beta or demo users, as it may interrupt the normal experience.
 *
 * Depends on OIDC context, with a valid access token containing permissions on the payload.
 */
export function FeedbackUI({
  getOpenReplaySessionURL,
  appName,
  userEmail,
}: {
  /**
   * The name of the app module that the user must have permissions for in order to see the feedback UI. Defaults to 'BetaAppFeedback'.
   */
  requiresAppModuleName?: string;
  getOpenReplaySessionURL: () => string;
  appName: string;
  userEmail: string;
}) {
  const { register, handleSubmit, setValue, watch, formState } = useForm({
    defaultValues: {
      type: 'Idea',
      feedbackTitle: '',
      feedback: '',
      email: userEmail,
      openReplaySessionURL: '',
      appName,
      origin: location.origin,
      image: undefined as string | undefined,
    },
  });
  const type = watch('type');
  const [screengrabbing, setScreengrabbing] = useState(false);
  const [screenshotUrl, setScreenshotUrl] = useState<string | null>(null);
  const handleClick = useCallback(async () => {
    setIsOpen(true);
    try {
      if (!isIOS()) {
        setScreengrabbing(true);
        const screenshot = await captureScreen().then((res) => {
          setScreengrabbing(false);

          return res;
        });
        const url = URL.createObjectURL(screenshot);
        setValue('image', await blobToBase64(screenshot));
        setScreenshotUrl(url);
      }
    } catch (e) {
      setScreengrabbing(false);
      setIsOpen(false);
    }
  }, []);

  const handleClose = () => {
    setScreengrabbing(false);
    setScreenshotUrl(null);
    setIsOpen(false);
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <Modal
        modalClassName="ignore-screenshot"
        isOpen={isOpen}
        onClosed={handleClose}
        onExit={handleClose}
      >
        <ModalBody>
          <form
            onSubmit={handleSubmit((values) => {
              handleClose();
              return fetch(feedbackUrl, {
                method: 'POST',
                body: JSON.stringify({
                  ...(values || {}),
                  openReplaySessionURL: getOpenReplaySessionURL(),
                }),
              });
            })}
          >
            {!isIOS() && (
              <div className=" .relative .grid .place-content-center">
                <div className=".max-h-60 .max-w-sm .flex .flex-col .justify-center ">
                  {screengrabbing ? (
                    'Creating screenshot...'
                  ) : (
                    <img
                      className=".object-contain .h-60 .rounded-lg .shadow-lg"
                      src={screenshotUrl}
                    />
                  )}
                </div>
              </div>
            )}

            <div className=".grid .place-content-center .pt-2">
              <Tabs onSelect={(tab) => setValue('type', tab)} activeKey={type}>
                <Tab eventKey="Idea" title="💡 Idea" />
                <Tab eventKey="Bug" title="🐛 Bug" />
                <Tab eventKey="Other" title="❓ Other" />
              </Tabs>
            </div>
            <div className=".pt-4 ">
              <Label>Short Summary</Label>
              <Input {...register('feedbackTitle', { required: true })} />
              <br />
              <Label>Feedback (optional)</Label>
              <TextArea {...register('feedback')} />
              <br />
              <small className=".text-center">
                We're also collecting additional non-sensitive information about
                your session, such as your URL and how you got here, your
                current book and cloud, etc.
              </small>
            </div>

            <div className=".flex .pt-4">
              <Button onClick={handleClose} variant="light">
                Cancel
              </Button>
              <div className=".flex-1" />
              <Button
                submit
                disabled={screengrabbing || formState.isSubmitting}
              >
                {formState.isSubmitting ? 'Submitting...' : 'Submit'}
              </Button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      {ReactDOM.createPortal(
        <button
          id="feedback-button"
          onClick={handleClick}
          className=".inline-block .text-white .border-none .fixed .m-auto .bottom-0 .left-0 .right-0 .top-3/4 .opacity-80 hover:.opacity-100 .cursor-pointer .h-12 .rounded-full .w-72 .bg-slate-800 .text-center"
        >
          💡 Feedback
        </button>,
        document.body
      )}
    </>
  );
}
