import { DTO } from '@readcloud/common';
import { Version } from '../dto/WAR/_deprecated/base.dto';
import { BookState as _deprecatedBook } from '../dto/WAR/_deprecated/book.dto';
import { Bookmark as _deprecatedBookmark } from '../dto/WAR/_deprecated/bookmark.dto';
import { Cloud as _deprecatedCloud } from '../dto/WAR/_deprecated/cloud.dto';
import { Comment as _deprecatedComment } from '../dto/WAR/_deprecated/comment.dto';
import { Tag as _deprecatedTag } from '../dto/WAR/_deprecated/tag.dto';
import {
  UserRole,
  User as _deprecatedUser,
} from '../dto/WAR/_deprecated/user.dto';
import {
  Annotation,
  Book,
  Bookmark,
  Cloud,
  Comment,
  Tag,
  User,
} from '../models';

/**
 *
 * @description Sets `published`, `epubURI`, `pdfURI` and `fileSize` to default values.
 * @description Sets `isbn` and `publisher` to default values if they dont exist.
 */
export const readerBookToReduxBook = (readerBook: _deprecatedBook): Book => {
  return {
    ...readerBook,
    tags: readerBook.tags.map((tag) => readerTagToReduxTag(tag)),
    published: '',
    pdfURI: '',
    epubURI: '',
    fileSize: 0,
    isbn: readerBook.isbn || '',
    publisher: readerBook.publisher || '',
  };
};
/*########################################
ANNOTATION // NOT NEEDED, Converted type in data to match the type in Reader.
#########################################*/
export const reduxAnnotationToReaderAnnotation = (
  reduxAnnotation: Annotation
): DTO.Annotation => {
  return reduxAnnotation as DTO.Annotation;
};
export const readerAnnotationToReduxAnnotation = (
  readerAnnotation: DTO.Annotation
): Annotation => {
  return { ...(readerAnnotation as Annotation) };
};

/*########################################
BOOKMARK
#########################################*/
export const reduxBookmarkToReaderBookmark = (
  reduxBookmark: Bookmark
): _deprecatedBookmark => {
  return {
    ...reduxBookmark,
    version: (reduxBookmark.version as Version) || '2',
    clientCreated: reduxBookmark.clientCreated.toString(),
    clientUpdated: reduxBookmark.clientUpdated.toString(),
  };
};
export const readerBookmarkToReduxBookmark = (
  readerBookmark: _deprecatedBookmark
): Bookmark => {
  return {
    ...readerBookmark,
    clientCreated: readerBookmark.clientCreated,
    clientUpdated: readerBookmark.clientUpdated,
  };
};

/*########################################
COMMENT
#########################################*/
export const reduxCommentToReaderComment = (
  reduxComment: Comment
): _deprecatedComment => {
  return {
    ...reduxComment,
    authorId: reduxComment.authorId || '',
    subjectId: reduxComment.subjectId || '',
    subjectType:
      (reduxComment.subjectType as
        | 'Comment'
        | 'Unknown'
        | 'Annotation'
        | 'Book') || 'Unknown',
    text: reduxComment.text || '',
    visibleInClouds: reduxComment.visibleInClouds || [],
    version: '2',
  };
};
export const readerCommentToReduxComment = (
  readerComment: _deprecatedComment
): Comment => {
  return {
    ...readerComment,
  };
};
/*########################################
USERS
#########################################*/
export const reduxUserToReaderUser = (reduxUser: User): _deprecatedUser => {
  return {
    ...reduxUser,
    role: (reduxUser.role as UserRole) || 'Student',
    loginDateTime: '',
    pollingDateTime: '',
    institution: reduxUser.institution || '',
  };
};
export const readerUserToReduxUser = (readerUser: _deprecatedUser): User => {
  return {
    ...readerUser,
    institutions: [readerUser.institution],
  };
};

/*########################################
CLOUD
#########################################*/
export const reduxCloudToReaderCloud = (
  reduxCloud: Partial<Cloud>
): Partial<_deprecatedCloud> => {
  return {
    ...reduxCloud,
    addBookAdminOnly: reduxCloud.addBookAdminOnly,
  };
};
export const readerCloudToReduxCloud = (
  readerCloud: _deprecatedCloud
): Cloud => {
  return {
    ...readerCloud,
    subject: '',
    yearLevel: '',
    archivedFor: [],
    institutions: [readerCloud.institution],
    resellers: [],
    extConfig: {},
    axcelerateId: '',
    customBookSortOrder: readerCloud.customBookSortOrder,
  };
};
/*########################################
TAG
#########################################*/
export const reduxTagToReaderTag = (reduxTag: Tag): _deprecatedTag => {
  return {
    ...reduxTag,
    created: reduxTag.created.toString(),
    updated: reduxTag.updated.toString(),
  };
};
export const readerTagToReduxTag = (readerTag: _deprecatedTag): Tag => {
  return {
    ...readerTag,
    type: '',
  };
};
