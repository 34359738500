import { AppModule } from '@readcloud/data';

const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

export const GetAppModulesThunkAction = async (payload: void, { getState }) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/v14/app_module/all', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response?.json().then(({ result }) => result));
};

export const UpdateAppModuleThunkAction = async (
  payload: AppModule,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + `/api/v14/app_module/${payload.id}`, {
    method: 'PATCH',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then(() => {
    return payload;
  });
};
