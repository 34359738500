import { Comment, reduxCommentToReaderComment } from '@readcloud/data';
import { trackEvent } from '@root/libs/common/src';
import { tryCatchAndNotify } from '@root/libs/common/src/lib/rum';
import { applicationActions } from '../../reader-features';
import { getReaderAnalyticsContext } from '../../analytics';

const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

export const GetConnectedCommentsThunkAction = async (
  payload: {
    offset?: number;
    limit?: number;
    filterBookIds?: string[];
    userEmail?: string;
  },
  { dispatch, getState }
) => {
  /* replace the options of the required resource with following: 
    {
        get: true,
        offset: payload.offset,
        limit: payload.limit,
        order: '-updated',
    }
    */

  const getAllQueryOpts = {
    upToVersion: '3',
    userOpts: { get: false },
    bookOpts: { get: false },
    annotationOpts: { get: false },
    bookmarkOpts: { get: false },
    cloudOpts: { get: false },
    commentOpts: {
      get: true,
      offset: payload?.offset,
      limit: payload?.limit,
      order: 'updated',
    },
    tagOpts: { get: false },
    appStateOpts: { get: false },
    filterBookIds: payload?.filterBookIds,
    userEmail: payload?.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/rc/v14/get/all', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(getAllQueryOpts),
  }).then((response) =>
    response.json().then(({ result }) => {
      dispatch(applicationActions.setAllServerMilliSec(result?.serverMillisec));
      return result?.comments;
    })
  );
};

export const AddConnectedCommentThunkAction = async (
  payload: Comment,
  { getState }
) => {
  tryCatchAndNotify(() => {
    trackEvent('Webapp/CommentCreated', {
      comment: {
        subjectType: payload.subjectType,
        subjectId: payload.subjectId,
        text: payload.text,
        id: payload.id,
      },
      ...getReaderAnalyticsContext(getState),
    });
  });
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  // Webapp data requires some manipulating for the API to accept them. This is done here.
  const expectedPayload = reduxCommentToReaderComment(payload);

  return await fetch(absolutePath + `/api/v14/add/comment`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(expectedPayload),
  }).then(() => {
    return payload;
  });
};

export const UpdateConnectedCommentThunkAction = async (
  payload: Comment,
  { getState }
) => {
  tryCatchAndNotify(() => {
    trackEvent('Webapp/CommentUpdated', {
      comment: {
        subjectType: payload.subjectType,
        subjectId: payload.subjectId,
        text: payload.text,
        id: payload.id,
      },
      ...getReaderAnalyticsContext(getState),
    });
  });
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  // Webapp data requires some manipulating for the API to accept them. This is done here.
  const expectedPayload = reduxCommentToReaderComment(payload);

  return await fetch(
    absolutePath + `/api/v14/update/comment?id=${payload.id}`,
    {
      method: 'PUT',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify(expectedPayload),
    }
  ).then(() => {
    return payload;
  });
};

export const DeleteConnectedCommentThunkAction = async (
  id: string,
  { getState }
) => {
  tryCatchAndNotify(() => {
    const comment = getState().CommentsState.comments.find(
      (comment) => comment.id === id
    );
    trackEvent('Webapp/CommentDeleted', {
      comment: {
        subjectType: comment.subjectType,
        subjectId: comment.subjectId,
        text: comment.text,
        id: comment.id,
      },
      ...getReaderAnalyticsContext(getState),
    });
  });
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + `/api/v14/delete/comment?id=${id}`, {
    method: 'DELETE',
    mode: 'cors',
    headers: headers,
  }).then(() => id);
};

export const GetDeltaConnectedCommentsThunkAction = async (
  payload: {
    filterBookIds?: string[];
    userEmail?: string;
  },
  { getState, dispatch }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  const params = new URLSearchParams({
    millisec: getState().ApplicationState.lastPoll.commentsServerMillisec || 0,
    upToVersion: '3',
    userEmail: payload.userEmail || '',
  });

  return await fetch(
    absolutePath + `/api/rc/v14/delta/comments?` + params.toString(),
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then(async (response) => {
    if (!response.ok) {
      throw new Error();
    }
    const { result } = await response.json();
    dispatch(
      applicationActions.setCommentsServerMilliSec(result?.serverMillisec)
    );
    return result.comment;
  });
};
