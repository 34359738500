import { fuzzy, Searcher } from 'fast-fuzzy';
import memo from 'nano-memoize';

/**
 * Compare strings; returning a scalar between 0 and 1 where 1 means it's the same string and 0 means they're nothing alike.
 * Memoized due to it being a potentially expensive call.
 */
export const stringSimilarity = memo((string1: string, string2: string) =>
  fuzzy(string1, string2)
);

/**
 * Use this when you have a haystack (list of things to search) that doesn't change much, for optimisations.
 */
export const FuzzySearcher = Searcher;
