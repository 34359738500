import { Ability, AbilityBuilder, RawRuleOf } from '@casl/ability';
import {
  Action,
  AppAbility,
  PermissionsMap,
  Role,
  Scope,
  Subject,
} from './types';

export const defineRules = (role: Role = Role.Public, scopes: Scope[] = []) => {
  const { can, build, rules } = new AbilityBuilder<AppAbility>(Ability);

  defineRulesForRole(can, role);
  defineRulesForScopes(can, scopes);
  defineReadsFromWrites(can, rules);

  return build();
};

export const isAuthorized = (
  ability: AppAbility,
  permissions: PermissionsMap[]
) => {
  const ands = permissions.map((permission) =>
    // @ts-expect-error
    Object.keys(permission).every((subject: keyof typeof Subject) =>
      ability.can(permission[subject], Subject[subject])
    )
  );

  return ands.some(Boolean);
};

const defineReadsFromWrites = (
  can: AbilityBuilder<AppAbility>['can'],
  rules: RawRuleOf<AppAbility>[]
) => {
  rules.forEach((rule) => {
    if (rule['action'] === Action.Write) {
      can(Action.Read, rule.subject);
    }
  });
};

const defineRulesForRole = (
  can: AbilityBuilder<AppAbility>['can'],
  role: Role
) => {
  switch (role) {
    case Role.Admin:
      can(Action.Write, Subject.Orders);
      can(Action.Write, Subject.OrdersDiscount);
      can(Action.Write, Subject.Bundles);
      can(Action.Write, Subject.QuotableItems);
      can(Action.Write, Subject.Institutions);
      can(Action.Write, Subject.InstitutionsResellers);
      can(Action.Write, Subject.InstitutionsCreate);
      can(Action.Write, Subject.Users);
      can(Action.Write, Subject.Books);
      can(Action.Write, Subject.Transactions);
      can(Action.Write, Subject.Clouds);
      can(Action.Write, Subject.Licences);
      can(Action.Write, Subject.AppModules);
      can(Action.Write, Subject.Admin);
      break;
    case Role.ResellerAdmin:
      can(Action.Write, Subject.Orders);
      can(Action.Read, Subject.OrdersDiscount);
      can(Action.Write, Subject.Bundles);
      can(Action.Write, Subject.QuotableItems);
      can(Action.Write, Subject.Institutions);
      can(Action.Write, Subject.InstitutionsResellers);
      can(Action.Write, Subject.Users);
      can(Action.Write, Subject.Books);
      can(Action.Write, Subject.Clouds);
      can(Action.Write, Subject.ResellerUtils);
      can(Action.Write, Subject.Repurchase);
      can(Action.Write, Subject.Licences);
      can(Action.Write, Subject.Transactions);
      can(Action.Write, Subject.Reselleradmin);
      break;
    case Role.SchoolAdmin:
      can(Action.Read, Subject.Orders);
      can(Action.Write, Subject.Institutions);
      can(Action.Read, Subject.Users);
      can(Action.Read, Subject.Books);
      can(Action.Read, Subject.Clouds);
      can(Action.Write, Subject.Schooladmin);
      break;
    case Role.Teacher:
      can(Action.Write, Subject.Orders);
      can(Action.Read, Subject.Bundles);
      can(Action.Read, Subject.Institutions);
      can(Action.Read, Subject.Users);
      can(Action.Read, Subject.Books);
      can(Action.Read, Subject.Clouds);
      can(Action.Read, Subject.DashboardTeacher);
      can(Action.Write, Subject.Teacher);
      break;
    case Role.Student:
      can(Action.Read, Subject.Users);
      can(Action.Read, Subject.Books);
      can(Action.Read, Subject.Clouds);
      can(Action.Write, Subject.Student);
      break;
    case Role.GroupAdmin:
      can(Action.Write, Subject.GroupAdmin);
      break;
    case Role.Public:
      can(Action.Write, Subject.Public);
      break;
    default:
      break;
  }
};

const defineRulesForScopes = (
  can: AbilityBuilder<AppAbility>['can'],
  scopes: Scope[]
) => {
  scopes.forEach((scope) => {
    switch (scope) {
      case Scope.U_Order_Discount:
        can(Action.Write, Subject.Orders);
        can(Action.Write, Subject.OrdersDiscount);
        break;
      case Scope.U_Sales:
        can(Action.Write, Subject.Sales);
        break;
      case Scope.U_Publisher_Author:
      case Scope.U_Populate_School_Clouds:
      default:
        break;
    }
  });
};
