import { Button } from '@readcloud/component-library/components/Base/Button/Button';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalWithButton,
} from '@readcloud/component-library/components/Base/Modal';

import React, { ReactElement } from 'react';
interface Props {
  title: string;
  message: string;
  confirmText?: string;
  cancelText?: string;
  cancelVariant?: string;
  confirmVariant?: string;
  onConfirm?: () => void;
  onCancel?: () => void;
  onToggleExtra?: (e?: React.MouseEvent<HTMLElement>) => void;
  renderButton: ReactElement;
  buttonTooltip?: string;
}

export class ButtonWithConfirmation extends React.PureComponent<Props> {
  public static defaultProps = {
    cancelVariant: 'light',
    confirmVariant: 'primary',
    cancelText: 'Cancel',
    confirmText: 'Confirm',
  };
  public renderDialogContent = (props) => (
    <Modal
      isOpen={props.isOpen}
      toggle={props.toggle}
      contentClassName={'border border-danger'}
      size="sm"
    >
      <ModalBody>
        <p>{this.props.message}</p>
      </ModalBody>
      <ModalFooter>
        <Button
          variant={this.props.cancelVariant}
          onClick={(e) => {
            props.toggle(e);
            if (this.props.onCancel) {
              this.props.onCancel();
            }
          }}
        >
          {this.props.cancelText}
        </Button>
        <Button
          variant={this.props.confirmVariant}
          onClick={(e) => {
            props.toggle(e);
            if (this.props.onConfirm) {
              this.props.onConfirm();
            }
          }}
        >
          {this.props.confirmText}
        </Button>
      </ModalFooter>
    </Modal>
  );

  public override render() {
    return (
      <ModalWithButton
        buttonComponent={this.props.renderButton}
        buttonTooltip={this.props.buttonTooltip}
        ModalComponent={this.renderDialogContent}
        onToggleExtra={this.props.onToggleExtra}
      />
    );
  }
}
