import { AxiosInstance } from 'axios';
import { compose } from 'ramda';
import { Subject } from 'rxjs';
let accessToken: string;
let refreshToken: string;
let clientId: string;

const addAuthInterceptor = (client) => {
  client.interceptors.request.use((config) => {
    if (accessToken) {
      config.headers = {
        Authorization: `Bearer ${accessToken}`,
        ...config.headers,
      };
    }
    return config;
  });
  return client;
};

const addCommonHeaders = (client: AxiosInstance) => {
  //console.log("CommonHeaderCreated");
  client.defaults.headers = {
    Accept: 'application/json',
    'content-type': 'application/json',
    ...client.defaults.headers,
  };
  return client;
};

export const addCommonAxiosConfig = compose(
  addAuthInterceptor,
  addCommonHeaders
);

export const setAccessToken = (token) => (accessToken = token);
export const getAccessToken = () => accessToken;
export const setRefreshToken = (token) => (refreshToken = token);
export const getRefreshToken = () => refreshToken;
export const setClientId = (_clientId) => (clientId = _clientId);
export const getClientId = () => clientId;
export const refreshTokenFailure$ = new Subject();
