/* tslint:disable */
/* javascript-obfuscator:disable */
import { Buffer } from 'buffer';
import * as cryptoBrowserify from 'crypto-browserify';
/* javascript-obfuscator:enable */
const crypto = typeof window !== 'undefined' && window.crypto;
const subtle = crypto
  ? window.crypto.subtle || (window.crypto as any).webkitSubtle
  : null;

function incrementBuffer(buf: Buffer, cnt: number) {
  var i, len, mod;
  len = buf.length;
  i = len - 1;
  while (cnt !== 0) {
    mod = (cnt + buf[i]) % 256;
    cnt = Math.floor((cnt + buf[i]) / 256);
    buf[i] = mod;
    i -= 1;
    if (i < 0) {
      i = len - 1;
    }
  }
  return buf;
}
/* decryptCtr */
const dc = async (keyData: any, iv: any, data: any, counter: number = 0) => {
  if (!subtle)
    throw new Error('Crypto API not available. Are you running over HTTPS?');

  const key = await subtle.importKey(
    'raw',
    keyData,
    {
      name: 'AES-CTR',
    } as any,
    false,
    ['decrypt']
  );

  return subtle.decrypt(
    {
      name: 'AES-CTR',
      counter: counter > 0 ? incrementBuffer(iv, counter) : iv,
      length: 128,
    },
    key,
    data
  );
};
/* decryptCbc */
const dcbc = async (keyData: any, iv: any, data: any) => {
  if (!subtle)
    throw new Error('Crypto API not available. Are you running over HTTPS?');

  const key = await subtle.importKey(
    'raw',
    keyData,
    {
      name: 'AES-CBC',
    } as any,
    false,
    ['decrypt']
  );

  return subtle.decrypt(
    {
      name: 'AES-CBC',
      counter: iv,
      length: 128,
    },
    key,
    data
  );
};

const AES_BLOCK_SIZE = 16;
const BOOK_KEY_SIZE = 48;
const ZERO_IV = Buffer.alloc(AES_BLOCK_SIZE);

/* calculateSha256 */
function cs(buffer: any) {
  const hash = cryptoBrowserify.createHash('sha256');
  hash.update(buffer);
  return hash.digest();
}

/* encryptmk */
function emk(key: any) {
  var buf = Buffer.from(key);
  buf = cs(buf);
  var mask = 0x01;
  mask |= (buf[7] & 0x40) >> 3;
  mask |= buf[5] & 0x02;
  mask |= (buf[1] & 0x18) >> 3;
  mask |= (buf[31] & 0x21) << 2;
  mask |= (buf[10] & 0x06) << 3;

  for (var i = 1; i <= 128; i <<= 1) {
    if ((mask & i) === 0) buf = cs(buf);
    else {
      var cipher = cryptoBrowserify.createCipheriv('aes-256-cbc', buf, ZERO_IV);
      buf = cipher.update(buf);
    }
    buf[15] ^= 0xa5;
  }
  return buf;
}

/* decrypt book secret */
const dbs = (isbn: any, encryptedBookSecret: any) => {
  const mk = emk('std::exception..std::bad_exception' + ':' + isbn);
  const decipher = cryptoBrowserify.createDecipheriv(
    'aes-256-cbc',
    mk,
    ZERO_IV
  );
  const decipherFirst = decipher.update(encryptedBookSecret);
  return Buffer.concat([decipherFirst, decipher.final()]);
};

/* splitEncKeyAndIv */
const sekai = (encKeyAndIv: any) => {
  const bufferedData = Buffer.from(encKeyAndIv, 'hex');
  return {
    encryptedKey: bufferedData.slice(0, BOOK_KEY_SIZE),
    iv: bufferedData.slice(BOOK_KEY_SIZE),
  };
};

/* decrypt/process resource */
export const pr = async (
  isbn: any,
  bookbytes: any,
  encKeyAndIv: any,
  encMethod: any,
  counter: number = 0
): Promise<ArrayBuffer | Uint8Array | { error: any }> => {
  try {
    const { encryptedKey, iv } = sekai(encKeyAndIv);

    const secret = dbs(isbn, encryptedKey);

    if (typeof window !== 'undefined' && window.crypto !== undefined) {
      return `${encMethod}` === '2'
        ? await dc(secret, iv, bookbytes, counter)
        : await dcbc(secret, iv, bookbytes);
    } else {
      const decipher = cryptoBrowserify.createDecipheriv(
        `${encMethod}` === '2' ? 'aes-256-ctr' : 'aes-256-cbc',
        secret,
        iv
      );
      return Buffer.concat([
        decipher.update(Buffer.from(bookbytes)),
        decipher.final(),
      ]);
    }
  } catch (e) {
    return {
      error: e,
    };
  }
};
