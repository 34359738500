import { DTO } from '@readcloud/common';
import { ApiKey } from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';
import { UserAgent } from './models';

export const usernameValidation = (
  email: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/username_validation', {
    params: { email },
    ...config,
  });

export const getConfig = (userEmail?: string, config?: AxiosRequestConfig) =>
  getApiv14().get('/config', { params: { userEmail }, ...config });

export const myActions = (userEmail?: string, config?: AxiosRequestConfig) =>
  getApiv14().get('/my_actions', { params: { userEmail }, ...config });

export const login = (loginRequest: UserAgent, config?: AxiosRequestConfig) =>
  getApiv14().post<DTO.Login>('/login', loginRequest, config);

export const serviceNotifications = (config?: AxiosRequestConfig) =>
  getApiv14().get('/service_notifications', config);

export const deltaAll = (
  millisec = 0,
  userEmail = '',
  upToVersion?: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get<DTO.DeltaAll>('/delta/all', {
    params: { millisec, userEmail, upToVersion },
    ...config,
  });

interface Opt {
  get: boolean;
  order?: string;
  limit?: number;
}
export const getAll = (
  opts: {
    upToVersion: string;
    cloudOpts?: Opt;
    bookOpts?: Opt;
    userOpts?: Opt;
    annotationOpts?: Opt;
    bookmarkOpts?: Opt;
    commentOpts?: Opt;
    tagOpts?: Opt;
    schoolCloudOpts?: Opt;
    appStateOpts?: Opt;
    filterBookIds?: string[];
    userEmail?: string;
  },
  config?: AxiosRequestConfig
) => {
  let req = '/get/all';
  if (opts.userEmail && opts.userEmail.length > 0)
    req = req + '?userEmail=' + opts.userEmail;
  return getApiv14().post<DTO.GetAll>(req, opts, {
    ...config,
  });
};

export const ltiLaunch = (bookId: string, config?: AxiosRequestConfig) =>
  getApiv14().get('/lti_launch', { params: { bookId }, ...config });

export const ltiLaunchData = (bookId: string, config?: AxiosRequestConfig) =>
  getApiv14().get('/lti_launch_data', { params: { bookId }, ...config });

export const resetPasswordGen = (email: string, config?: AxiosRequestConfig) =>
  getApiv14().get('/resetpasswordgen', { params: { email }, ...config });

export const resetPassword = (
  email: string,
  tmpPwd: string,
  newPwd: string,
  config?: AxiosRequestConfig
) =>
  /*getApiv14().post("/resetpassword", {
    params: { email, tmpPwd, newPwd },
    ...config
  });*/
  getApiv14().get('/resetpassword', {
    params: { email, tmpPwd, newPwd },
    ...config,
  });

export const setPassword = (
  req: { email: string; newPassword: string },
  config?: AxiosRequestConfig
) => getApiv14().post('/setpassword', req, config);

export const uploadFile = (
  {
    file,
    fileType,
    fileExtension,
    fileName,
    fileSubType,
    params,
  }: DTO.UploadFileRequest & { params?: any },
  config?: AxiosRequestConfig
) => {
  const form = new FormData();
  form.append('file', file, fileName);
  form.append('fileType', fileType);
  if (fileExtension) {
    form.append('fileExtension', fileExtension);
  }
  if (fileSubType) {
    form.append('fileSubType', fileSubType);
  }
  if (fileName) {
    form.append('fileName', fileName);
  }

  return getApiv14().post('/upload_file', form, {
    params,
    headers: {
      'Content-Type': 'multipart/form-data',
      ...(config ? config.headers : {}),
    },
    ...config,
  });
};

export const getApiKeys = (config?: AxiosRequestConfig) =>
  getApiv14().get<{ result?: ApiKey }>('/get_api_keys', {
    ...config,
  });

export const sendEmail = (
  file: File | Blob,
  filename: string,
  metadata: File | Blob,
  config?: AxiosRequestConfig
) => {
  const form = new FormData();
  form.append('file1', file, filename);
  form.append('metadata', metadata);

  return getApiv14().post('/send_email', form, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
};
