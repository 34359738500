import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export const searchSoftwareLicence = (
  req: {
    disableLookup: {
      book: boolean;
      cloud: boolean;
      institution: boolean;
      reseller: boolean;
      user: boolean;
    };
    institutionIds?: string[];
    limit: number;
    offset: number;
    order: string;
    resellerIds?: string[];
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/software_licence', req, config);
