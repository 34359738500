import { DTO } from '@readcloud/common';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DeleteTransactionsThunkAction,
  SearchAdvancedTransactionsThunkAction,
} from './thunk';
import { TransactionsState } from './types';

const initialState: TransactionsState = {
  transactionSearchResult: {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: 0,
    },
  },
  transactionSearchCsvResult: {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: 0,
    },
  },
};

const name = 'transactions';

const otherActions = {
  deleteTransactions: createAsyncThunk(
    `${name}/deleteTransactions`,
    DeleteTransactionsThunkAction
  ),
  searchTransactions: createAsyncThunk(
    `${name}/searchTransactions`,
    SearchAdvancedTransactionsThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    searchAdvancedTransactions(
      state,
      action: PayloadAction<DTO.TransactionSearchResult>
    ) {
      state.transactionSearchResult = {
        result: {
          ...action.payload.result,
          items: [
            ...state.transactionSearchResult.result.items,
            ...action.payload.result.items,
          ],
        },
      };
    },
    searchCSVTransactions(
      state,
      action: PayloadAction<DTO.TransactionSearchResult>
    ) {
      state.transactionSearchCsvResult = {
        ...state.transactionSearchCsvResult,
        ...action.payload,
      };
    },
    clearTransactions(state) {
      state.transactionSearchResult = {
        result: {
          items: [],
          moreAvailable: false,
          nextOffset: 0,
          timeMs: 0,
        },
      };
    },
    deleteTransactions(state, action) {
      state.transactionSearchResult.result.items.filter(
        (item) => !action.payload.includes(item.transaction.id)
      );
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      otherActions.deleteTransactions.fulfilled,
      (state, action) => {
        state.transactionSearchResult.result.items.filter(
          (item) => !action.payload.includes(item.transaction.id)
        );
      }
    );
  },
});

const { actions, reducer } = slice;

export const transactionsReducer = reducer;

export const transactionsActions = { ...actions, ...otherActions };
