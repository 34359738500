import { Book } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetBooksByIsbnThunkAction,
  GetBooksStatsThunkAction,
  GetBooksThunkAction,
  GetConnectedBooksThunkAction,
  GetInstitutionBooksThunkAction,
  GetLTIBookDataThunkAction,
  ListBookForUserThunkAction,
  SearchAdvancedBooksThunkAction,
} from './thunk';
import { BooksState } from './types';

const initialState: BooksState = {
  books: [],
  bookList: [],
  booksStats: undefined,
  bookSearchResult: {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      collection: '',
      timeMs: 0,
    },
  },
  listBookForUser: [],
  ltiBookData: {},
};

const name = 'books';

const asyncActions = {
  getConnectedBooks: createAsyncThunk(
    `${name}/getConnectedBooks`,
    GetConnectedBooksThunkAction
  ),
  getBooks: createAsyncThunk(`${name}/get`, GetBooksThunkAction),
  listBooksForUser: createAsyncThunk(
    `${name}/listBooksForUser`,
    ListBookForUserThunkAction
  ),
  getBookStats: createAsyncThunk(
    `${name}/getBookStats`,
    GetBooksStatsThunkAction
  ),
  getInstitutionBooks: createAsyncThunk(
    `${name}/getInstitutionBooks`,
    GetInstitutionBooksThunkAction
  ),
  searchAdvancedBooks: createAsyncThunk(
    `${name}/searchAdvancedBooks`,
    SearchAdvancedBooksThunkAction
  ),
  getBooksByIsbn: createAsyncThunk(
    `${name}/getBooksByIsbn`,
    GetBooksByIsbnThunkAction
  ),
  getLTIBookData: createAsyncThunk(
    `${name}/getLTIBookData`,
    GetLTIBookDataThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    addBook(state, action: PayloadAction<Book>) {
      state.books.push(action.payload);
    },
    deltaBooks(state, action: PayloadAction<Book[]>) {
      //keep track of book we're updating.
      const updatedBookIds = [];

      action.payload.forEach((newUpdatedBook) => {
        //find and replace.
        const index = state.books.findIndex(
          (book) => book.id === newUpdatedBook.id
        );
        if (index >= 0) {
          if (!newUpdatedBook.deleted) {
            //replace book
            state.books[index] = newUpdatedBook;
          } else {
            //delete book
            state.books.splice(index, 1);
          }
          updatedBookIds.push(newUpdatedBook.id);
        }
      });

      //filter the annos we have already updated.
      const newBooks = action.payload.filter(
        (book) => !updatedBookIds.includes(book.id) && !book.deleted
      );

      //add the rest to state.
      state.books.unshift(...newBooks);
    },
    setBooks(state, action: PayloadAction<Book[]>) {
      state.books = action.payload;
    },
    addBooks(state, action: PayloadAction<Book[]>) {
      state.books.push(...action.payload);
    },
    clearBookSearchResult(state) {
      state.bookSearchResult = {
        result: {
          items: [],
          moreAvailable: false,
          nextOffset: 0,
          collection: '',
          timeMs: 0,
        },
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      asyncActions.getConnectedBooks.fulfilled,
      (state, action) => {
        state.books = action.payload;
      }
    );
    builder.addCase(asyncActions.getBooks.fulfilled, (state, action) => {
      state.books = action.payload;
    });
    builder.addCase(
      asyncActions.listBooksForUser.fulfilled,
      (state, action) => {
        state.bookList = action.payload;
      }
    );
    builder.addCase(asyncActions.getBookStats.fulfilled, (state, action) => {
      state.booksStats = action.payload;
    });
    builder.addCase(
      asyncActions.getInstitutionBooks.fulfilled,
      (state, action) => {
        state.bookList = action.payload;
      }
    );
    builder.addCase(
      asyncActions.searchAdvancedBooks.fulfilled,
      (state, action) => {
        const {
          result: { collection, items, moreAvailable, nextOffset, timeMs },
        } = action.payload;

        state.bookSearchResult = {
          result: {
            items: [
              ...state.bookSearchResult.result.items,
              ...items.map((item, index) => {
                return {
                  ...item,
                  idx: index + state.bookSearchResult.result.items.length,
                };
              }),
            ],
            moreAvailable,
            nextOffset,
            collection,
            timeMs,
          },
        };
      }
    );
    builder.addCase(asyncActions.getBooksByIsbn.fulfilled, (state, action) => {
      state.books = action.payload;
    });
    builder.addCase(asyncActions.getLTIBookData.fulfilled, (state, action) => {
      state.ltiBookData[action.payload.bookId] = action.payload.ltiData;
    });
  },
});

const { actions, reducer } = slice;

export const booksReducer = reducer;

export const booksActions = { ...actions, asyncActions };
