import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TextSelectionState } from './types';

const initialState: TextSelectionState = {
  selectedText: '',
  pageNumber: 0,
  pageLabel: '',
  selectionRects: [],
  boundingRect: undefined,
  selecting: false,
};

const name = 'textSelection';

const asyncActions = {};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateSelectionData(
      state,
      action: PayloadAction<Partial<TextSelectionState>>
    ) {
      Object.keys(action.payload).forEach(
        (key) => (state[key] = action.payload[key])
      );
    },
    resetSelectionData() {
      return initialState;
    },
  },
});

const { actions, reducer } = slice;

export const textSelectionReducer = reducer;
export const textSelectionActions = { ...actions, asyncActions };
