const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

export const GetPublishersThunkAction = async (
  payload: string | void, // userEmail
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(
    absolutePath + '/api/v14/get_publishers' + (payload ? `?${payload}` : ''),
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then((response) => response?.json().then(({ result }) => result));
};
