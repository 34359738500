import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { SearchState } from './types';

const initialState: SearchState = {
  query: '',
  matchCount: 0,
  currentMatchCount: 0,
  caseSensitive: false,
  highlightAll: false,
  findState: undefined,
  open: false,
  phraseSearch: true,
};

const name = 'search';

const asyncActions = {};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setSearchQuery(state, action: PayloadAction<string>) {
      state.query = action.payload;
    },
    togglePhraseSearch(state) {
      state.phraseSearch = !state.phraseSearch;
    },
    toggleCaseSensitive(state) {
      state.caseSensitive = !state.caseSensitive;
    },
    toggleHighlightAll(state) {
      state.highlightAll = !state.highlightAll;
    },
    setOpen(state, action: PayloadAction<boolean>) {
      console.log('SETTING STATE');
      state.open = action.payload;
    },
    setFindState(state, action: PayloadAction<number>) {
      state.findState = action.payload;
    },
    setMatchCount(state, action: PayloadAction<number>) {
      state.matchCount = action.payload;
    },
    setCurrentMatchCount(state, action: PayloadAction<number>) {
      state.currentMatchCount = action.payload;
    },
    setCloseAndClear(state, action: PayloadAction<void>) {
      return initialState;
    },
  },
});

const { actions, reducer } = slice;

export const searchReducer = reducer;
export const searchActions = { ...actions, asyncActions };
