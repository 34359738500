import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetAllResellersThunkAction, GetResellersThunkAction } from './thunk';
import { ResellersState } from './types';

const initialState: ResellersState = {
  resellers: [],
  resellerAllResult: { result: [] },
};

const name = 'resellers';

const asyncActions = {
  getResellers: createAsyncThunk(
    `${name}/getResellers`,
    GetResellersThunkAction
  ),
  getResellerAll: createAsyncThunk(
    `${name}/getResellerAll`,
    GetAllResellersThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setResellers(state, action: PayloadAction<string[]>) {
      state.resellers = action.payload;
    },
    addResellers(state, action: PayloadAction<string[]>) {
      state.resellers.push(...action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getResellers.fulfilled, (state, action) => {
      state.resellers = action.payload;
    });
    builder.addCase(asyncActions.getResellers.rejected, (state) => {
      state.resellers = [];
    });
    builder.addCase(asyncActions.getResellerAll.fulfilled, (state, action) => {
      state.resellerAllResult = action.payload;
    });
    builder.addCase(asyncActions.getResellerAll.rejected, (state) => {
      state.resellerAllResult = {
        result: [],
      };
    });
  },
});

const { actions, reducer } = slice;

export const resellersReducer = reducer;

export const resellersActions = { ...actions, asyncActions };
