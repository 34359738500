import * as React from 'react';
import { TestingProps } from '../../Types/Testing';
import { OverlayTrigger, Tooltip } from '../Tooltip';

interface IModalWithButtonProps extends TestingProps {
  buttonComponent?: React.ReactElement;
  ModalComponent?: React.FC<{
    toggle: (e?: React.MouseEvent<HTMLElement>) => void;
    isOpen: boolean;
  }>;
  onToggleExtra?: (e: React.MouseEvent<HTMLElement>) => void;
  modalKey?: string;
  buttonTooltip?: string;
}

export const ModalWithButton: React.FC<IModalWithButtonProps> = ({
  buttonComponent,
  ModalComponent,
  onToggleExtra,
  modalKey,
  buttonTooltip,
  testId,
}: IModalWithButtonProps) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggle = (e) => {
    if (onToggleExtra && e) onToggleExtra(e);
    setIsOpen((d: boolean) => {
      return !d;
    });
  };

  return (
    <>
      {buttonTooltip ? (
        <OverlayTrigger
          placement="bottom"
          overlay={<Tooltip>{buttonTooltip}</Tooltip>}
        >
          {React.cloneElement(buttonComponent, { onClick: toggle })}
        </OverlayTrigger>
      ) : (
        React.cloneElement(buttonComponent, { onClick: toggle })
      )}
      <ModalComponent toggle={toggle} isOpen={isOpen} key={modalKey} />
    </>
  );
};
