import { createContext, useContext, useEffect, useRef, useState } from 'react';

// Make sure this
const rootPageTitle = 'ReadCloud App';
const separator = '|';
type PageContext = {
  subtitles: string[];
  setPageSubtitles: (...string: string[]) => void;
};
const pageContext = createContext<PageContext | null>(null);

function createPageTitle(...subtitles: string[]) {
  return `${rootPageTitle} ${separator} ${subtitles.join(` ${separator} `)}`;
}

/**
 * Use this for every page within the app to ensure sane defaults and consistent overall behaviour, such as page title and viewport dimensions.
 *
 * Pass in a title only for the specific page you're wanting to show, not the overall document title.
 * This will be crafted using a root page title and a separator for displaying heirarchy.
 *
 * Within children, you can also set subtitles, which will be appended to the page title.
 *
 */
export function Page(props: { children: React.ReactNode; title: string }) {
  // We're using a ref here to avoid re-rendering the page whenever children set their subtitles.
  const pageState = useRef({
    subtitles: [] as string[],
  });

  function commitCurrentTitle() {
    document.title = createPageTitle(
      props.title,
      ...pageState.current.subtitles
    );
  }
  useEffect(() => {
    commitCurrentTitle();
  }, [props.title, pageState.current.subtitles]);

  const setPageSubtitles = (...subtitles: string[]) => {
    pageState.current.subtitles = subtitles;
    commitCurrentTitle();
  };

  return (
    <pageContext.Provider
      value={{ subtitles: pageState.current.subtitles, setPageSubtitles }}
    >
      <div className=".h-screen .w-full">{props.children}</div>
    </pageContext.Provider>
  );
}

export function usePageContext() {
  const context = useContext(pageContext);
  if (context === null) {
    throw new Error('usePageContext must be used within a Page component');
  }
  // Remove page subtitles when unmounting
  useEffect(() => {
    return () => {
      context.setPageSubtitles();
    };
  }, []);
  return context;
}
