import {
  ActivityDataTypes,
  EpubReadStatEvent,
  OneOfActivityDataTypes,
  ReadStatAddRequest,
  ReadStatEvent,
  UserActivityRequest,
} from '@readcloud/data';
import { getPromise, x64hash128 } from 'fingerprintjs2';
import { detect } from 'detect-browser';
import { trackEvent, uuidV4 } from '@root/libs/common/src';
import { tryCatchAndNotify } from '@root/libs/common/src/lib/rum';
import { getReaderAnalyticsContext } from '../../analytics';
const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

const browser = detect();
let currentAgentInfo = undefined;

export const CreateReadStatThunkAction = async (
  payload: ReadStatEvent | EpubReadStatEvent,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  const ReadStatAddPayloads: ReadStatAddRequest[] = Array.from(
    Array(payload.pageEnd - payload.pageStart + 1)
  ).map((_, i) => ({
    bookID: getState().ReaderState.readerData.bookId,
    userID: getState().AuthState.user.id,
    page: i + payload.pageStart,
    timeOpened: payload.timeOpened.toISOString(),
    timeClosed: payload.timeClosed.toISOString(),
  }));

  ReadStatAddPayloads.forEach((readstat) => {
    tryCatchAndNotify(() => {
      trackEvent('Webapp/PageRead', {
        page: readstat.page,
        timeOpened: readstat.timeOpened,
        timeClosed: readstat.timeClosed,
        durationSeconds:
          payload.timeClosed && payload.timeOpened
            ? (payload.timeClosed.valueOf() - payload.timeOpened.valueOf()) /
              1000
            : 0,
        ...getReaderAnalyticsContext(getState),
      });
    });
  });

  await fetch(absolutePath + `/api/v14/add/statistics`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(ReadStatAddPayloads),
  });

  return ReadStatAddPayloads.map((readStat) => ({
    ...readStat,
    timeOpened: payload.timeOpened.toISOString(),
    timeClosed: payload.timeClosed.toISOString(),
  }));
};

export const CreateUserActivityThunkAction = async (
  payload: OneOfActivityDataTypes & {
    type: keyof ActivityDataTypes;
  },
  { getState }
) => {
  const { type, ...rest } = payload;
  const getAgentInfo = async () => {
    return (
      currentAgentInfo ||
      (currentAgentInfo = {
        deviceId: x64hash128(
          (await getPromise()).map(({ value }) => value).join(),
          31
        ),
        deviceIdSource: 'Fingerprintjs2',
        osName: (browser && browser.name) || 'Unknown',
        osVersion: (browser && browser.version) || 'Unknown',
      })
    );
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };
  const agentInfo = await getAgentInfo();

  const userActivityRequest: UserActivityRequest = {
    ...agentInfo,
    result: 'error' in rest ? 'Failure' : 'Success',
    type: type,
    date: new Date().toISOString(),
    data: rest,
  };

  await fetch(absolutePath + `/api/v14/user_activity`, {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify([userActivityRequest]),
  });

  return userActivityRequest;
};
