import { Order } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddOrderThunkAction,
  DeleteOrderThunkAction,
  GetMappedOrdersThunkAction,
  GetOrderableBooksThunkAction,
  GetOrderFilterOptionsThunkAction,
  GetOrdersThunkAction,
  GetOrderThunkAction,
  SearchMappedOrdersThunkAction,
  SearchMultiOrderTransactionsThunkAction,
  SetOrderFilterOptionsThunkAction,
  UpdateOrderThunkAction,
} from './thunk';
import { OrdersState } from './types';

const initialState: OrdersState = {
  orders: {},
  orderFilterOptions: {
    effectiveFromRange: {
      start: new Date(new Date().getFullYear(), 0, 1).toISOString(),
      end: new Date(new Date().getFullYear(), 11, 31).toISOString(),
    },
    salesStatus: [],
    operationsStatus: [],
    financeStatus: [],
  },
  order: null,
  orderableBooks: [],
  mappedOrdersAll: [],
  mappedOrders: {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: 0,
    },
  },
  mappedOrderTransactions: {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: 0,
    },
  },
};

const name = 'order';

const asyncActions = {
  getOrders: createAsyncThunk(`${name}/get`, GetOrdersThunkAction),
  getMappedOrders: createAsyncThunk(
    `${name}/getMappedOrders`,
    GetMappedOrdersThunkAction
  ),
  searchMappedOrders: createAsyncThunk(
    `${name}/searchMappedOrders`,
    SearchMappedOrdersThunkAction
  ),
  getOrder: createAsyncThunk(`${name}/getOrder`, GetOrderThunkAction),
  addOrder: createAsyncThunk(`${name}/add`, AddOrderThunkAction),
  updateOrder: createAsyncThunk(`${name}/update`, UpdateOrderThunkAction),
  deleteOrder: createAsyncThunk(`${name}/delete`, DeleteOrderThunkAction),
  setOrderFilterOptions: createAsyncThunk(
    `${name}/setOrderFilterOptions`,
    SetOrderFilterOptionsThunkAction
  ),
  getOrderFilterOptions: createAsyncThunk(
    `${name}/getOrderFilterOptions`,
    GetOrderFilterOptionsThunkAction
  ),
  getOrderableBooks: createAsyncThunk(
    `${name}/getOrderableBooks`,
    GetOrderableBooksThunkAction
  ),
  searchMultiOrderTransactions: createAsyncThunk(
    `${name}/searchMultiOrderTransactions`,
    SearchMultiOrderTransactionsThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setOrders(state, action: PayloadAction<Record<string, Order>>) {
      state.orders = action.payload;
    },
    addOrders(state, action: PayloadAction<Record<string, Order>>) {
      state.orders = {
        ...state.orders,
        ...action.payload,
      };
    },
    searchMappedOrdersMore(state, action) {
      state.mappedOrders.result.items.push(...action.payload.result);
      state.mappedOrders.result.moreAvailable = action.payload.moreAvailable;
      state.mappedOrders.result.nextOffset = action.payload.nextOffset;
      state.mappedOrders.result.timeMs = Date.now();
    },
    searchMappedOrders(state, action) {
      state.mappedOrders.result = action.payload.result;
      state.mappedOrders.result.timeMs = Date.now();
    },
    searchMultiOrderTransactionsAction(state, action) {
      state.mappedOrderTransactions.result = action.payload.result;
      state.mappedOrderTransactions.result.timeMs = Date.now();
    },
    searchMultiOrderTransactionsMoreAction(state, action) {
      state.mappedOrderTransactions.result.items.push(...action.payload.result);
      state.mappedOrderTransactions.result.moreAvailable =
        action.payload.moreAvailable;
      state.mappedOrderTransactions.result.nextOffset =
        action.payload.nextOffset;
      state.mappedOrderTransactions.result.timeMs = Date.now();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.addOrder.fulfilled, (state, action) => {
      state.orders[action.payload.id] = action.payload;
    });
    builder.addCase(asyncActions.getOrders.fulfilled, (state, action) => {
      state.orders = action.payload;
    });
    builder.addCase(asyncActions.deleteOrder.fulfilled, (state, action) => {
      delete state.orders[action.payload];
    });
    builder.addCase(asyncActions.updateOrder.fulfilled, (state, action) => {
      console.log(action.payload);
      action.payload?.forEach((order) => {
        console.log('order: ', state.orders);
        console.log(state.orders[order.id]);
        state.orders[order.id] = order;
      });
    });
    builder.addCase(
      asyncActions.setOrderFilterOptions.fulfilled,
      (state, action) => {
        state.orderFilterOptions = action.payload;
      }
    );
    builder.addCase(
      asyncActions.getOrderFilterOptions.fulfilled,
      (state, action) => {
        state.orderFilterOptions = action.payload;
      }
    );
    builder.addCase(asyncActions.getOrder.fulfilled, (state, action) => {
      state.order = action.payload;
    });
    builder.addCase(asyncActions.getMappedOrders.fulfilled, (state, action) => {
      state.mappedOrdersAll = action.payload;
    });
    builder.addCase(
      asyncActions.getOrderableBooks.fulfilled,
      (state, action) => {
        state.orderableBooks = action.payload;
      }
    );
  },
});

const { actions, reducer } = slice;

export const ordersReducer = reducer;

export const ordersActions = { ...actions, asyncActions };
