import { getAccessToken } from '../client';
import { getApiv14Url } from './apiv14Setup';
export async function dictionary(
  text: string
): Promise<ReadableStreamDefaultReader<string>> {
  const res = await fetch(
    `${getApiv14Url()}/dictionary?text=${encodeURIComponent(text)}`,
    {
      headers: {
        Authorization: `Bearer ${getAccessToken()}`,
      },
      method: 'GET',
    }
  );
  if (!res.ok) {
    console.error(res);
    throw Error('Fetch failed. See logged error.');
  }
  return res.body.pipeThrough(new TextDecoderStream()).getReader();
}
