import C from 'classnames';
import * as React from 'react';
import { TestingProps } from '../../Types/Testing';

export interface IInputProps extends TestingProps {
  name: string;
  size?: 'sm' | 'lg';
  type?: string;
  customControl?: boolean;
  rounded?: boolean;
  flush?: boolean;
  auto?: boolean;
  className?: string;
  valid?: boolean;
  invalid?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  innerRef?: React.RefObject<HTMLInputElement>;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  customLabel?: string | JSX.Element;
  checked?: boolean;
  elKey?: string;
  defaultValue?: string;
  style?: React.CSSProperties;
  autoCompleteOff?: boolean;
  min?: number | string;
  max?: number | string;
  customVariant?: string;
}

const sizeMapper: { [index: string]: string } = {
  lg: 'form-control-lg',
  sm: 'form-control-sm',
};

export const Input = React.forwardRef<HTMLInputElement, IInputProps>(
  (
    {
      children,
      className,
      rounded,
      flush,
      auto,
      customControl,
      type = 'text',
      size,
      valid,
      invalid,
      placeholder,
      name,
      value,
      readOnly,
      disabled,
      onChange,
      onFocus,
      onBlur,
      onClick,
      innerRef,
      testId,
      required,
      customLabel,
      checked,
      autoCompleteOff,
      elKey,
      defaultValue,
      style,
      min,
      max,
      customVariant,
    },
    ref
  ) => {
    const customVariantMapper: { [variant: string]: string } = {
      primary: 'custom-control-bg-primary',
      secondary: 'custom-control-bg-secondary',
      dark: 'custom-control-bg-dark',
      light: 'custom-control-bg-light',
      success: 'custom-control-bg-success',
      warning: 'custom-control-bg-warning',
      danger: 'custom-control-bg-danger',
      info: 'custom-control-bg-info',
    };
    return customControl ? (
      <div className="custom-control custom-switch mb-2" key={elKey}>
        <input
          className={C(
            'form-control',
            'custom-control-input',

            size && sizeMapper[size],
            rounded && 'form-control-rounded',
            flush && 'form-control-flush',
            auto && 'form-control-auto',
            valid && 'is-valid',
            invalid && 'is-invalid',
            className && className
          )}
          style={style}
          readOnly={readOnly}
          disabled={disabled}
          id={name}
          name={name}
          type={type}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          onClick={onClick}
          ref={innerRef || ref}
          data-testid={testId}
          required={required}
          checked={checked}
          key={elKey + '-input'}
          autoComplete={autoCompleteOff && 'off'}
          {...(autoCompleteOff
            ? {
                /* Setting data-lpignore="true" data-form-type="other" makes password managers ignore it */
                'data-lpignore': 'true',
                'data-form-type': 'other',
              }
            : {})}
          min={min}
          max={max}
        >
          {children}
        </input>
        <label
          className={C(
            'custom-control-label',
            (customVariant && customVariantMapper[customVariant]) || null
          )}
          htmlFor={name}
        >
          {customLabel}
        </label>
      </div>
    ) : (
      <input
        className={C(
          'form-control',
          size && sizeMapper[size],
          rounded && 'form-control-rounded',
          flush && 'form-control-flush',
          auto && 'form-control-auto',
          valid && 'is-valid',
          invalid && 'is-invalid',
          className && className
        )}
        readOnly={readOnly}
        disabled={disabled}
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        onClick={onClick}
        ref={innerRef || ref}
        data-testid={testId}
        required={required}
        checked={checked}
        key={elKey + '-input'}
        style={style}
        autoComplete={autoCompleteOff && 'off'}
        {...(autoCompleteOff
          ? {
              /* Setting data-lpignore="true" data-form-type="other" makes password managers ignore it */
              'data-lpignore': 'true',
              'data-form-type': 'other',
            }
          : {})}
        min={min}
        max={max}
      >
        {children}
      </input>
    );
  }
);
