import * as t from 'io-ts';
import { userRole } from '../Common';
import { CommonError } from './Common';
export interface PasswordTokenRequest {
  clientId: string;
  grantType: 'Password';
  email: string;
  password: string;
}

export interface RefreshTokenRequest {
  clientId: string;
  grantType: 'RefreshToken';
  refreshToken: string;
}

export interface SuccessfulTokenResponse {
  access_token: string;
  refresh_token: string;
}
export type TokenResponseBody =
  | SuccessfulTokenResponse
  | CommonError<undefined>;

const jwtMeta = t.intersection([
  t.type({
    exp: t.number,
    iat: t.number,
  }),
  t.partial({
    aud: t.union([t.string, t.array(t.string)]),
  }),
]);

const userScopeParams = t.partial({
  U_Publisher_Author: t.type({
    publishers: t.array(t.string),
  }),
  U_PopulateSchoolClouds: t.unknown,
});

const userScopeKey = t.keyof(userScopeParams.props);

export const jwtPayload = t.intersection([
  t.type({
    userId: t.string,
    institution: t.string,
    email: t.string,
    role: userRole,
    clientId: t.string,
  }),
  t.partial({
    userScope: t.string,
    userScopeParams,
  }),
  jwtMeta,
]);

export type JWTMeta = t.TypeOf<typeof jwtMeta>;

export type JWTPayload = t.TypeOf<typeof jwtPayload>;

export type UserScopeKey = t.TypeOf<typeof userScopeKey>;

export const getUserScopes = (jwtPayload: JWTPayload): UserScopeKey[] =>
  !jwtPayload.userScope
    ? []
    : jwtPayload.userScope.split(' ').filter(userScopeKey.is);
