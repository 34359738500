import { Book, BooksStatsRequest, FindRequest } from '@readcloud/data';
import { applicationActions } from '../../reader-features';

const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

export const GetConnectedBooksThunkAction = async (
  payload: {
    offset?: number;
    limit?: number;
    filterBookIds?: string[];
    userEmail?: string;
  },
  { dispatch, getState, requestId }
) => {
  /* replace the options of the required resource with following: 
    {
        get: true,
        offset: payload.offset,
        limit: payload.limit,
        order: '-updated',
    }
    */

  const getAllQueryOpts = {
    upToVersion: '3',
    userOpts: { get: false },
    bookOpts: {
      get: true,
      offset: payload?.offset,
      limit: payload?.limit,
      order: '-updated',
    },
    annotationOpts: { get: false },
    bookmarkOpts: { get: false },
    cloudOpts: { get: false },
    commentOpts: { get: false },
    tagOpts: { get: false },
    appStateOpts: { get: false },
    filterBookIds: payload?.filterBookIds,
    userEmail: payload?.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/rc/v14/get/all', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(getAllQueryOpts),
  }).then((response) =>
    response.json().then(({ result }) => {
      dispatch(applicationActions.setAllServerMilliSec(result?.serverMillisec));
      return result?.books;
    })
  );
};

export const ListBookForUserThunkAction = async (
  payload: void,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
    ? 'https://api.readcloud.com'
    : '';

  return await fetch(absolutePath + '/api/v14/list/book', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response.json().then(({ result }) => result.book));
};

// Thunk Action - Does some Async stuff (or data manipulation) then dispatch the action creator.
export const GetBooksStatsThunkAction = async (
  requestParams: BooksStatsRequest,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
    ? 'https://api.readcloud.com'
    : '';

  return await fetch(absolutePath + '/api/v14/book_page_statistics', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(requestParams),
  }).then((response) => response.json().then(({ result }) => result));
};

export const GetInstitutionBooksThunkAction = async (
  institutionId: string,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(
    '/api/v14/institution/' + institutionId + '/school_books/all',
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then((response) => response.json().then(({ result }) => result));
};

export const SearchAdvancedBooksThunkAction = async (
  payload: {
    vcollection: string;
    vchildren: any;
    voffset: number;
    vlimit: number;
    pJoinMethod?: string;
  },
  { getState }
) => {
  // exclude rule 'Search by Isbn on the web' if exists
  const pChildren = [];
  let webIsbn = null;
  payload.vchildren.forEach((line) => {
    if (line.key === 'search on the web by isbn') {
      webIsbn = line.value;
    } else if (webIsbn && line.key === 'deleted') {
      // don't include 'deleted' rule if there is webIsbn
    } else pChildren.push(line);
  });

  if (webIsbn && payload.vcollection === 'webisbn') {
    return await fetch(
      'https://www.googleapis.com/books/v1/volumes?q=isbn:' + webIsbn
    )
      .then((response) => response.json())
      .then((result) => {
        let book = null;
        if (result && result.items && result.items.length > 0) {
          const item = result.items[0];
          if (item && item.volumeInfo) {
            book = item.volumeInfo;
            //console.log(book);
          }
        }
        if (!book) {
          console.log('Book not found');
          const data = {
            result: {
              items: [],
              moreAvailable: false,
              nextOffset: 0,
              collection: '',
              timeMs: 0,
            },
          };
          return data;
        } else {
          console.log('Book found %j', book);
          const bx: Book = {
            tags: book.tags,
            id: '1',
            title: book.title,
            isbn: webIsbn,
            thumbnailURL: book.imageLinks
              ? book.imageLinks.smallThumbnail
              : null,
            authorName: book.authors[0],
            publisher: book.publisher,
            created: null,
            updated: null,
            deleted: false,
            owner: null,
            description: null,
            published: null,
            epubURI: null,
            pdfURI: null,
            imageThumbnailURI: null,
            downloadURI: null,
            fileSize: null,
            type: null,
          };
          const data = {
            result: {
              items: [bx],
              moreAvailable: false,
              nextOffset: 0,
              collection: '',
              timeMs: Date.now(),
            },
          };
          return data;
        }
      });
  }

  if (pChildren.length === 0 || payload.vcollection === 'webisbn') {
    const data = {
      result: {
        items: [],
        moreAvailable: false,
        nextOffset: 0,
        collection: '',
        timeMs: 0,
      },
    };
    return data;
  }
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*/*',
  };

  return await fetch(
    '/api/v14/search/advanced/book?apiKey=bed91270-fd9f-11e1-bb4b-84c9b273558d',
    {
      method: 'POST',
      mode: 'cors',
      headers: headers,
      body: JSON.stringify({
        collection: payload.vcollection,
        limit: payload.vlimit,
        offset: payload.voffset,
        order: '-date',
        root: {
          joinMethod: payload.pJoinMethod ? payload.pJoinMethod : 'and',
          children: pChildren,
        },
      }),
    }
  )
    .then(async (response: any) => {
      try {
        /*const data = {
          result: {
            items: dummyTransactionSearchData.result.items,
            moreAvailable: dummyTransactionSearchData.result.moreAvailable,
            nextOffset: dummyTransactionSearchData.result.nextOffset
          }
        };
        return Promise.resolve(dispatch(SearchAdvancedTransactionsAction(data)));*/
        //console.log(response);
        const data = {
          result: {
            items: response.data.result.items,
            moreAvailable: response.data.result.moreAvailable,
            nextOffset: response.data.result.nextOffset,
            collection: response.data.result.collection,
            timeMs: Date.now(),
          },
        };
        return data;
      } catch (e) {}
    })
    .catch((error: any) => {
      console.log('API call error: ' + error);
      const data = {
        result: {
          items: [],
          moreAvailable: false,
          nextOffset: 0,
          collection: '',
          timeMs: 0,
        },
      };
      return data;
    });
};

export const GetBooksByIsbnThunkAction = async (
  isbns: string[],
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  console.log(isbns);
  return fetch('/api/v14/search/advanced/book', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify({
      collection: 'books',
      limit: 200,
      offset: 0,
      order: '-updated',
      root: {
        joinMethod: 'and',
        children: [
          {
            key: 'isbn',
            valueType: 'String',
            op: 'In',
            values: isbns,
          },
          {
            key: 'deleted',
            valueType: 'Boolean',
            op: 'Equals',
            value: 'false',
          },
        ],
      },
    }),
  })
    .then((response) => response.json().then(({ result }) => result.items))
    .catch((error: any) => {
      console.log('API call error: ' + error);
    });
};

// Thunk Action - Does some Async stuff (or data manipulation) then dispatch the action creator.
export const GetBooksThunkAction = async (
  payload: string | void,
  { getState }
) => {
  const buildRequest: FindRequest = {
    collection: 'books',
    limit: 20,
    offset: 0,
    order: '-updated',
    root: {
      joinMethod: 'Or',
      children: [
        {
          key: 'isbn', //Title // Publisher
          op: 'StartsWith',
          value: payload || '',
        },
        {
          key: 'title', //Title // Publisher
          op: 'Contains',
          value: payload || '',
        },
      ],
    },
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  //Do API HERE

  return await fetch(absolutePath + '/api/v14/search/advanced/book', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(buildRequest),
  }).then((response) =>
    response.json().then(({ result }) =>
      result.items.map((book: Book) => ({
        isbn: book.isbn,
        title: book.title,
        publisher: book.publisher,
      }))
    )
  );
};

export const GetLTIBookDataThunkAction = async (
  payload: string,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(
    absolutePath + `/api/v14/lti_launch_data?bookId=${payload}`,
    {
      method: 'GET',
      mode: 'cors',
      headers: headers,
    }
  ).then((response) =>
    response.json().then((res) => {
      if (res?.result?.html) {
        return { bookId: payload, ltiData: res?.result?.html as string };
      }
      return { bookId: payload, ltiData: null };
    })
  );
};
