import C from 'classnames';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { TestingProps } from '../../Types/Testing';

interface ICardProps extends TestingProps {
  className?: string;
  style?: object;
  innerRef?: (element?: HTMLElement) => any;
  to?: string;
  href?: string;
  ref?: React.Ref<HTMLDivElement>;
  onClick?: (e: React.MouseEvent<HTMLDivElement>) => void;
}

export const Card: React.FunctionComponent<ICardProps> = React.forwardRef<
  HTMLDivElement,
  ICardProps
>((props, ref) => {
  const { className, ...rest } = props;
  return props.to ? (
    <Link
      className={C('card', className)}
      to={props.to}
      data-testid={props.testId}
    >
      <div ref={ref} {...rest} data-testid={props.testId}>
        {props.children}
      </div>
    </Link>
  ) : props.href ? (
    <a
      className={C('card', className)}
      href={props.href}
      data-testid={props.testId}
    >
      <div ref={ref} {...rest} data-testid={props.testId}>
        {props.children}
      </div>
    </a>
  ) : (
    <div
      ref={ref}
      {...rest}
      className={C('card', className)}
      data-testid={props.testId}
    >
      {props.children}
    </div>
  );
});
