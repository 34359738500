import { Annotation } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { castDraft } from 'immer';
import {
  AddConnectedAnnotationThunkAction,
  DeleteConnectedAnnotationThunkAction,
  GetConnectedAnnotationsThunkAction,
  GetDeltaConnectedAnnotationsThunkAction,
  SetAnnotationFilter,
  UpdateConnectedAnnotationThunkAction,
} from './thunk';
import { AnnotationsState, ToolType, toolTypes } from './types';

const initialState: AnnotationsState = {
  annotations: [],
  annotationsByPage: [],
  annotationsUnfiltered: [],
  selectedAnnotation: null,
  currentlyDrawing: undefined,
  currentTool: toolTypes.Select,
  options: { annotationsV3: false },
  annotationFilters: undefined,
};

const name = 'annotations';

/*#########################################################################

Redux Saga actions - uncomment these and comment the thunk actions to swap.
-----------------------

const asyncActions = {
  getAnnotations: createAsyncActions<GetAnnotationsArgs, Annotation[]>(
    `${name}/getAnnotations`
  ),
  updateAnnotation: createAsyncActions<Annotation, Annotation>(
    `${name}/updateAnnotation`
  ),
  addAnnotation: createAsyncActions<Annotation, Annotation>(
    `${name}/addAnnotation`
  ),
  deleteAnnotation: createAsyncActions<string, string>(
    `${name}/deleteAnnotation`
  ),
};
#########################################################################*/

const asyncActions = {
  getAnnotations: createAsyncThunk(
    `${name}/get`,
    GetConnectedAnnotationsThunkAction
  ),
  getDeltaAnnotations: createAsyncThunk(
    `${name}/delta`,
    GetDeltaConnectedAnnotationsThunkAction
  ),
  addAnnotation: createAsyncThunk(
    `${name}/add`,
    AddConnectedAnnotationThunkAction
  ),
  updateAnnotation: createAsyncThunk(
    `${name}/update`,
    UpdateConnectedAnnotationThunkAction
  ),
  deleteAnnotation: createAsyncThunk(
    `${name}/delete`,
    DeleteConnectedAnnotationThunkAction
  ),
  setAnnotationFilter: createAsyncThunk(
    `${name}/setAnnotationFilter`,
    SetAnnotationFilter
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAnnotations(state, action: PayloadAction<Annotation[]>) {
      state.annotations = castDraft(action.payload);
    },
    addAnnotations(state, action: PayloadAction<Annotation[]>) {
      state.annotations.unshift(...castDraft(action.payload));
    },
    deltaAnnotations(state, action: PayloadAction<Annotation[]>) {
      //keep track of annotation we're updating.
      const updatedAnnotationIds = [];

      action.payload.forEach((newUpdatedAnnotation) => {
        //find and replace.
        const index = state.annotations.findIndex(
          (annotation) => annotation.id === newUpdatedAnnotation.id
        );
        if (index >= 0) {
          if (!newUpdatedAnnotation.deleted) {
            //replace annotation
            state.annotations[index] = newUpdatedAnnotation;
          } else {
            //delete annotation
            state.annotations.splice(index, 1);
          }
          updatedAnnotationIds.push(newUpdatedAnnotation.id);
        }
      });

      //filter the annos we have already updated.
      const newAnnotations = action.payload.filter(
        (annotation) =>
          !updatedAnnotationIds.includes(annotation.id) && !annotation.deleted
      );

      //add the rest to state.
      state.annotations.unshift(...newAnnotations);
    },
    setSelectedAnnotation(state, action: PayloadAction<string>) {
      state.selectedAnnotation =
        state.annotations.find((anno) => anno.id === action.payload) || null;
    },
    updateCurrentTool(state, action: PayloadAction<ToolType>) {
      state.currentTool = action.payload;
    },
    updateCurrentlyDrawing(state, action: PayloadAction<number | undefined>) {
      action.payload
        ? (state.currentlyDrawing = { pageNumber: action.payload })
        : (state.currentlyDrawing = undefined);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getAnnotations.fulfilled, (state, action) => {
      state.annotations = action.payload.annotations;
    });
    builder.addCase(
      asyncActions.getDeltaAnnotations.fulfilled,
      (state, action) => {
        //keep track of annos we're updating.
        const updatedAnnoIds = [];

        action.payload.forEach((newUpdatedAnno) => {
          //find and replace.
          const index = state.annotations.findIndex(
            (anno) => anno.id === newUpdatedAnno.id
          );
          if (index >= 0) {
            if (!newUpdatedAnno.deleted) {
              //replace anno
              state.annotations[index] = newUpdatedAnno;
            } else {
              //delete anno
              state.annotations.splice(index, 1);
            }
            updatedAnnoIds.push(newUpdatedAnno.id);
          }
        });

        //filter the annos we have already updated.
        const newAnnos = action.payload.filter(
          (anno) => !updatedAnnoIds.includes(anno.id) && !anno.deleted
        );

        //add the rest to state.
        state.annotations.unshift(...newAnnos);
      }
    );
    builder.addCase(
      asyncActions.updateAnnotation.fulfilled,
      (state, action) => {
        const index = state.annotations.findIndex(
          (annotation) => annotation.id === action.payload.id
        );
        if (index !== -1) {
          state.annotations[index] = castDraft(action.payload);
          if (
            state.selectedAnnotation &&
            state.selectedAnnotation.id === action.payload.id
          ) {
            state.selectedAnnotation = castDraft(action.payload);
          }
        }
      }
    );
    builder.addCase(asyncActions.addAnnotation.fulfilled, (state, action) => {
      state.annotations.unshift(action.payload as any);
    });
    builder.addCase(
      asyncActions.deleteAnnotation.fulfilled,
      (state, action) => {
        state.annotations = state.annotations.filter(
          (annotations) => annotations.id !== action.payload
        );
      }
    );
    builder.addCase(
      asyncActions.setAnnotationFilter.fulfilled,
      (state, action) => {
        state.annotationFilters = action.payload;
      }
    );
  },
});

const { actions, reducer } = slice;

export const annotationsReducer = reducer;

export const annotationsActions = { ...actions, asyncActions };
