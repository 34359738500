import { UserAddRequest, UserUpdateRequest } from '@readcloud/data';
import { usersActions } from './slice';
import { applicationActions } from '../../reader-features';

const absolutePath = process.env['NX_USE_ABSOLUTE_PATHS']
  ? 'https://api.readcloud.com'
  : '';

// Thunk Action - Does some Async stuff (or data manipulation) then dispatch the action creator.
export const GetUserInstitutionsThunkAction = async (
  payload: void,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/v14/institution/all', {
    method: 'GET',
    mode: 'cors',
    headers: headers,
  }).then((response) => response.json().then(({ result }) => result));
};

export const SearchUserThunkAction = async (
  payload: {
    institution: string;
    searchKey: string;
    searchString: string;
  },
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };
  console.log('SearchUserThunkAction');
  const params = new URLSearchParams(payload);
  return await fetch(
    absolutePath + '/api/v14/search/user?' + params.toString(),
    {
      mode: 'cors',
      headers: headers,
    }
  ).then((response) => response.json().then(({ result }) => result.user));
};

export const SearchUserAdvancedThunkAction = async (
  payload: {
    limit: number;
    offset: number;
    order: string;
    root: any;
  },
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };
  console.log('SearchUserAdvancedThunkAction');

  const res = {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: Date.now(),
    },
  };

  return await fetch(absolutePath + '/api/v14/search/advanced/user', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(payload),
  }).then((response) => response.json().then((data) => data));
};

export const AddUserThunkAction = async (
  payload: UserAddRequest,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };
  console.log('AddUserThunkAction');

  try {
    return await fetch(absolutePath + '/api/v14/add/user', {
      method: 'POST',
      headers,
      body: JSON.stringify(payload),
    }).then((response) => response.json().then(({ result }) => result));
  } catch (e) {
    console.log('Error in AddUserThunkAction');
    console.log(e);
  }
};

export const UpdateUserThunkAction = async (
  payload: UserUpdateRequest,
  { getState }
) => {
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };
  console.log('UpdateUserThunkAction');

  return await fetch(absolutePath + '/api/v14/user/', {
    method: 'PATCH',
    headers,
    body: JSON.stringify(payload),
  }).then((response) => response.json().then(({ result }) => result));
};

export const GetConnectedUsersThunkAction = async (
  payload: {
    offset?: number;
    limit?: number;
    filterBookIds?: string[];
    userEmail?: string;
  },
  { dispatch, getState }
) => {
  const getAllQueryOpts = {
    upToVersion: '3',
    userOpts: {
      get: true,
      order: '-updated',
    },
    bookOpts: { get: false },
    annotationOpts: { get: false },
    bookmarkOpts: { get: false },
    cloudOpts: { get: false },
    commentOpts: { get: false },
    tagOpts: { get: false },
    appStateOpts: { get: false },
    filterBookIds: payload?.filterBookIds,
    userEmail: payload?.userEmail,
  };

  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };

  return await fetch(absolutePath + '/api/rc/v14/get/all', {
    method: 'POST',
    mode: 'cors',
    headers: headers,
    body: JSON.stringify(getAllQueryOpts),
  }).then((response) =>
    response.json().then(({ result }) => {
      dispatch(applicationActions.setAllServerMilliSec(result?.serverMillisec));
      return result?.users;
    })
  );
};

export const SearchEnrolmentAdvancedThunkAction = async (
  payload: {
    limit: number;
    offset: number;
    order: string;
    root: any;
  },
  { getState }
) => {
  //Do API HERE
  // if successful update the state
  const headers = {
    Authorization: `Bearer ${getState().AuthState.user.access_token}`,
    'Content-Type': 'application/json',
  };
  console.log('SearchEnrolmentAdvancedThunkAction');

  let res = {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: Date.now(),
    },
  };

  return await fetch(absolutePath + '/api/v14/search/advanced/enrolment', {
    method: 'POST',
    headers,
    body: JSON.stringify(payload),
  }).then((response) =>
    response
      .json()
      .then((data) =>
        payload.offset === 0
          ? usersActions.searchEnrolmentadvanced(data)
          : usersActions.searchEnrolmentadvancedMore(data)
      )
  );
};
