import { Ability } from '@casl/ability';

export enum Role {
  Admin = 'Admin',
  ResellerAdmin = 'ResellerAdmin',
  SchoolAdmin = 'SchoolAdmin',
  GroupAdmin = 'GroupAdmin',
  Teacher = 'Teacher',
  Student = 'Student',
  Public = 'Public',
}

export enum Scope {
  U_Publisher_Author = 'U_Publisher_Author',
  U_Populate_School_Clouds = 'U_Populate_School_Clouds',
  U_Sales = 'U_Sales',
  U_Order_Discount = 'U_Order_Discount',
}

export enum Action {
  Read = 'Read',
  Write = 'Write',
}

export enum Subject {
  Orders = 'Orders',
  Bundles = 'Bundles',
  QuotableItems = 'QuotableItems',
  Institutions = 'Institutions',
  Users = 'Users',
  Books = 'Books',
  Transactions = 'Transactions',
  Clouds = 'Clouds',
  ResellerUtils = 'ResellerUtils',
  Repurchase = 'Repurchase',
  DashboardTeacher = 'DashboardTeacher',
  Sales = 'Sales',
  OrdersDiscount = 'OrdersDiscount',
  InstitutionsResellers = 'InstitutionsResellers',
  InstitutionsCreate = 'InstitutionsCreate',
  Licences = 'Licences',
  AppModules = 'AppModules',
  Admin = 'RoleAdmin',
  Reselleradmin = 'RoleReselleradmin',
  Schooladmin = 'RoleSchooladmin',
  ResellerAdmin = 'ResellerAdmin',
  SchoolAdmin = 'SchoolAdmin',
  GroupAdmin = 'GroupAdmin',
  Teacher = 'Teacher',
  Student = 'Student',
  Public = 'Public',
  RoleAdmin = 'RoleAdmin',
}

export type AppAbility = Ability<[Action, Subject]>;

export type PermissionsMap = Partial<Record<Subject, Action>>;

export interface DecodedToken {
  accessToken: string;
  email: string;
  firstName: string;
  lastName: string;
  institution: string;
  permissions: string[];
  reseller?: string;
  resellerId?: string;
  sub: string;
  id: string;
  clientId: string;
  role: string;
  scope: string;
  userScopes?: string[];
}
