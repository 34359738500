import { OrderItem } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetEffectiveOrderItemsThunkAction,
  GetTransactionOrderItemsThunkAction,
} from './thunk';
import { InventoryState } from './types';

const initialState: InventoryState = {
  activeItems: [],
  transactionInventory: [],
};

const name = 'inventory';

const asyncActions = {
  getEffectiveItems: createAsyncThunk(
    `${name}/getEffectiveItems`,
    GetEffectiveOrderItemsThunkAction
  ),
  getTransactionItems: createAsyncThunk(
    `${name}/getTransactionItems`,
    GetTransactionOrderItemsThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setEffectiveItems(state, action: PayloadAction<OrderItem[]>) {
      state.activeItems = action.payload;
    },
    addAEffectiveItem(state, action: PayloadAction<OrderItem[]>) {
      state.activeItems.push(...action.payload);
    },

    setTransactionItems(state, action: PayloadAction<OrderItem[]>) {
      state.activeItems = action.payload;
    },
    addTransactionItem(state, action: PayloadAction<OrderItem[]>) {
      state.activeItems.push(...action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      asyncActions.getEffectiveItems.fulfilled,
      (state, action) => {
        state.activeItems = action.payload;
      }
    );
    builder.addCase(
      asyncActions.getTransactionItems.fulfilled,
      (state, action) => {
        state.activeItems = action.payload;
      }
    );
  },
});

const { actions, reducer } = slice;

export const inventoryReducer = reducer;

export const inventoryActions = { ...actions, asyncActions };
