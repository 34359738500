import { QuotableItem } from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export const getAllQuotableItems = (config?: AxiosRequestConfig) =>
  getApiv14().get('/quotable_item/all', {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const getQuotableItem = (
  quotableItemId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/quotable_item/' + quotableItemId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const createQuotableItems = (
  quotableItems: QuotableItem[],
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/quotable_item/ex', quotableItems, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const createQuotableItem = (
  quotableItem: QuotableItem,
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/quotable_item/ex', [quotableItem], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const updateQuotableItems = (
  quotableItems: QuotableItem[],
  config?: AxiosRequestConfig
) =>
  getApiv14().put('/quotable_item/ex', quotableItems, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const updateQuotableItem = (
  quotableItem: QuotableItem,
  config?: AxiosRequestConfig
) =>
  getApiv14().put('/quotable_item/ex', [quotableItem], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const removeQuotableItem = (
  quotableItemsId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().delete('/quotable_item/ex' + quotableItemsId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const searchQuotableItems = (
  req: {
    collection: string;
    limit: number;
    offset: number;
    order: string;
    user?: string;
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/quotable_item', req, config);
