import { Annotation } from '@readcloud/data';

export type ToolType = 'Select' | Annotation['type'];

export const toolTypes: { [k in ToolType]: k } = {
  Circle: 'Circle',
  Rectangle: 'Rectangle',
  HTML: 'HTML',
  OverlayText: 'OverlayText',
  Image: 'Image',
  Media: 'Media',
  Path: 'Path',
  Select: 'Select',
  Text: 'Text',
};

export interface AnnotationsState {
  annotations: Annotation[];
  selectedAnnotation: Annotation;
  annotationsUnfiltered: Annotation[];
  annotationsByPage: { [key: number]: Annotation[] | undefined };
  loading?: boolean;
  currentlyDrawing?: { pageNumber: number };
  currentTool: ToolType;
  options: { annotationsV3?: boolean };
  annotationFilters?: { types: string; users: string[] };
}

export interface GetAnnotationsArgs {
  offset: number;
  limit: number;
  filterBookIds: string[];
  userEmail: string;
}
