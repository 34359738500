import { combineReducers } from '@reduxjs/toolkit';
import { annotationsReducer } from './features/annotations/slice';
import { announcementReducer } from './features/announcements';
import { appModulesReducer } from './features/appModules/slice';
import { appStatesReducer } from './features/appStates/slice';
import { authReducer } from './features/auth/slice';
import { bookmarksReducer } from './features/bookmarks/slice';
import { booksReducer } from './features/books/slice';
import { bundlesReducer } from './features/bundles/slice';
import { cloudsReducer } from './features/clouds/slice';
import { commentsReducer } from './features/comments/slice';
import { institutionsReducer } from './features/institutions/slice';
import { inventoryReducer } from './features/inventory/slice';
import { jobsReducer } from './features/jobs/slice';
import { licencesReducer } from './features/licences/slice';
import { logsReducer } from './features/logs/slice';
import { ordersReducer } from './features/orders/slice';
import { publishersReducer } from './features/publishers/slice';
import { quotableItemsReducer } from './features/quotableItems/slice';
import { resellersReducer } from './features/resellers/slice';
import { tagsReducer } from './features/tags/slice';
import { transactionsReducer } from './features/transactions/slice';
import { usersReducer } from './features/users/slice';
import {
  applicationReducer,
  pdfReducer,
  readerReducer,
  reportReducer,
  searchReducer,
  textSelectionReducer,
} from './reader-features';
import { homeSettingsReducer } from './clouds-and-content-features/settings';
import { cloudsAndContentReducer } from './clouds-and-content-features/cloudsAndContent';

export { homeSettingsReducer as HomeSettingsState };
const RootReducer = {
  AnnotationsState: annotationsReducer,
  AppModulesState: appModulesReducer,
  AppStatesState: appStatesReducer,
  AnnouncementState: announcementReducer,
  AuthState: authReducer,
  BookmarksState: bookmarksReducer,
  BooksState: booksReducer,
  BundlesState: bundlesReducer,
  CloudsState: cloudsReducer,
  CommentsState: commentsReducer,
  InstitutionsState: institutionsReducer,
  InventoryState: inventoryReducer,
  JobsState: jobsReducer,
  LicencesState: licencesReducer,
  LogsState: logsReducer,
  OrdersState: ordersReducer,
  PublishersState: publishersReducer,
  QuotableItemsState: quotableItemsReducer,
  ResellersState: resellersReducer,
  TagsState: tagsReducer,
  TransactionsState: transactionsReducer,
  UserState: usersReducer,
  ApplicationState: applicationReducer,
  PDFState: pdfReducer,
  ReaderState: readerReducer,
  HomeSettingsState: homeSettingsReducer,
  ReportState: reportReducer,
  SearchState: searchReducer,
  TextSelectionState: textSelectionReducer,
  CloudsAndContentState: cloudsAndContentReducer,
};

const RootReducerCombined = combineReducers(RootReducer);

export type RootState = ReturnType<typeof RootReducerCombined>;

export { RootReducer, RootReducerCombined };
