import * as React from 'react';
import Transition from 'react-transition-group/CSSTransition';

const defaultStyle = {
  opacity: 0,
};

interface IFadeProps {
  in: boolean;
  timeout: number;
  startOpacity?: number;
  endOpacity?: number;
  from?: 'left' | 'right';
  children?: React.ReactNode;
  className?: string;
  HtmlTag?: any;
  remove?: boolean;
  appear?: boolean;
}

export const Fade: React.FC<IFadeProps> = ({
  HtmlTag = 'div',
  ...props
}: IFadeProps) => {
  return (
    <Transition
      in={props.in}
      timeout={props.timeout}
      appear={props.appear}
      mountOnEnter={props.remove}
      unmountOnExit={props.remove}
      classNames={'fade'}
      style={{ willChange: 'transform' }}
    >
      {(state) => {
        return (
          <HtmlTag
            className={props.className}
            style={{
              transition: `opacity ${props.timeout}ms ease-in-out`,
            }}
          >
            {props.children && props.children}
          </HtmlTag>
        );
      }}
    </Transition>
  );
};
