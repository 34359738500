import { pageViewModes } from '@readcloud/common/lib/data/reader';
import { isTouchDevice } from '@readcloud/common/lib/deviceUtils';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PDFState } from './types';

const initialState: PDFState = {
  slidesView: isTouchDevice(),
  baseScale: 1,
  scale: 1,
  mostCommonHeight: 0,
  mostCommonWidth: 0,
  pageViewMode: pageViewModes.OnePage,
  settings: {
    renderMode: 'canvas',
    redrawDebounceTime: 0,
    updateDebounceTime: 230,
    maxCanvasPixels: 3000 * 3000, // This limit seems to fix https://gitlab.com/readcloud/readcloud/issues/59
    bufferSize: 3,
    flow: 'Horizontal',
    workersEnabled: true,
    renderingEngine: 'Web',
    darkMode: true,
    continuous: true,
    gutterSize: 16,
  },
};

const name = 'pdf';

const asyncActions = {};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updatePDFData(state, action: PayloadAction<Partial<PDFState>>) {
      Object.keys(action.payload).forEach(
        (key) => (state[key] = action.payload[key])
      );
    },
    updatePDFSettings(
      state,
      action: PayloadAction<Partial<PDFState['settings']>>
    ) {
      state.settings = { ...state.settings, ...action.payload };
    },
  },
});

const { actions, reducer } = slice;

export const pdfReducer = reducer;
export const pdfActions = { ...actions, asyncActions };
