import { DTO } from '@readcloud/common';
import { getApiv14 } from './apiv14Setup';

export const updateUserPreferences = (
  req: DTO.UserPrefState,
  currentDateMs: number
) =>
  getApiv14().put(
    '/update/app_state',
    [
      {
        tags: { realm: 'UserPrefState' },
        state: req,
        date: new Date().toISOString(),
        version: '3',
      },
    ],
    { params: { currentDateMs } }
  );

// User preferences are pulled down when the application first loads in a get/all,
// there is no reason to create a get request currently.
