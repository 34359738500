import { AppState } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetConnectedAppStatesThunkAction } from './thunk';
import { AppStatesState } from './types';

const initialState: AppStatesState = {
  appStates: [],
};

const name = 'appStates';

const asyncActions = {
  getAppStates: createAsyncThunk(
    `${name}/getAppStates`,
    GetConnectedAppStatesThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAppStates(state, action: PayloadAction<AppState[]>) {
      state.appStates = action.payload;
    },
    addAppStates(state, action: PayloadAction<AppState[]>) {
      state.appStates.push(...action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getAppStates.fulfilled, (state, action) => {
      state.appStates = action.payload;
    });
  },
});

const { actions, reducer } = slice;

export const appStatesReducer = reducer;

export const appStatesActions = { ...actions, asyncActions };
