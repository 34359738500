import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export const searchTransactionAdvanced = (
  req: {
    csvOutput: boolean;
    disableLookup: {
      institution: boolean;
      reseller: boolean;
      user: boolean;
    };
    limit: number;
    offset: number;
    order: string;
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/transaction', req, config);

export const deleteTransactions = (
  transIds: { id: string }[],
  config?: AxiosRequestConfig
) => getApiv14().delete('/transaction', { data: transIds, ...config });
