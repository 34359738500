export interface APICallDescriptor {
  name: string;
  description: string;
}

export const getJwtPayload = (jwt: string) => {
  try {
    return JSON.parse(Buffer.from(jwt.split('.')[1], 'base64').toString());
  } catch (e) {
    throw new Error(`Invalid JWT: ${jwt}`);
  }
};

export const isJwtValid = (token: string) => {
  const payload = getJwtPayload(token);
  const expiry = new Date(payload.exp * 1000 - 10 * 1000); // Give us a 10 second buffer
  return expiry > new Date();
};

export const getErrorFromApiResponse = (responseError: any): string | null => {
  try {
    return responseError &&
      responseError.response &&
      responseError.response.data
      ? responseError.response.data.error
        ? responseError.response.data.error.message
        : responseError.response.data.result
        ? responseError.response.data.result.error
        : responseError.message
      : responseError.message;
  } catch (e) {
    return responseError.message;
  }
};
