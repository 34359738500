import { AsyncJob, Job } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { HandleAsyncJobThunkAction } from './thunk';
import { JobsState } from './types';

const initialState: JobsState = {
  asyncJobs: {},
  jobs: {},
};

const name = 'jobs';

const otherActions = {
  handleAsyncJob: createAsyncThunk(
    `${name}/handleAsyncJob`,
    HandleAsyncJobThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setJobs(state, action: PayloadAction<Record<string, Job>>) {
      state.jobs = action.payload;
    },
    addJobs(state, action: PayloadAction<Record<string, Job>>) {
      state.jobs = {
        ...state.jobs,
        ...action.payload,
      };
    },
    addJob(state, action: PayloadAction<Job>) {
      state.jobs[action.payload.id] = action.payload;
    },
    deleteJob(state, action: PayloadAction<string>) {
      delete state.jobs[action.payload];
    },
    updateJob(state, action: PayloadAction<Job>) {
      state.jobs[action.payload.id] = {
        ...state.jobs[action.payload.id],
        ...action.payload,
      };
    },
    setAsyncJobs(state, action: PayloadAction<Record<string, AsyncJob>>) {
      state.asyncJobs = action.payload;
    },
    addAsyncJobs(state, action: PayloadAction<Record<string, AsyncJob>>) {
      state.asyncJobs = {
        ...state.asyncJobs,
        ...action.payload,
      };
    },
    addAsyncJob(state, action: PayloadAction<AsyncJob>) {
      state.asyncJobs[action.payload.id] = action.payload;
    },
    deleteAsyncJob(state, action: PayloadAction<string>) {
      delete state.asyncJobs[action.payload];
    },
    updateAsyncJob(state, action: PayloadAction<AsyncJob>) {
      state.asyncJobs[action.payload.id] = {
        ...state.asyncJobs[action.payload.id],
        ...action.payload,
      };
    },
  },
});

const { actions, reducer } = slice;

export const jobsReducer = reducer;

export const jobsActions = { ...actions, ...otherActions };
