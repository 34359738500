import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CloudsAndContentState } from './types';

const initialState: CloudsAndContentState = {
  scrollPosition: {
    containerPosition: 0,
    cloudId: null,
    cloudPosition: 0,
  },
};

const name = 'cloudsAndContent';

const asyncActions = {};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    updateScrollPosition(
      state,
      action: PayloadAction<CloudsAndContentState['scrollPosition']>
    ) {
      state.scrollPosition = action.payload;
    },
    resetScrollPosition(state) {
      state.scrollPosition = initialState.scrollPosition;
    },
  },
});

const { actions, reducer } = slice;

export const cloudsAndContentReducer = reducer;
export const cloudsAndContentActions = { ...actions, asyncActions };
