import posthog from 'posthog-js';
import { useEffect, createContext, useState, useContext } from 'react';
import { CustomEvent } from './analyticsEvents';
import { useReadCloudAuth } from './AuthProvider';
import * as Sentry from '@sentry/react';

type FeatureFlagKey = 'beta-feedback-button';

type AnalyticsContext = Readonly<{
  isFeatureEnabled: (key: FeatureFlagKey) => boolean;
}>;

const analyticsContext = createContext<AnalyticsContext>(null);

export function Analytics(props: {
  apiKey?: string;
  children: React.ReactNode;
}) {
  const shouldUsePoshog = !import.meta.env.VITE_NO_ANALYTICS && props.apiKey;
  const { user } = useReadCloudAuth();
  const [state, setState] = useState<AnalyticsContext>({
    isFeatureEnabled: () => false,
  });

  useEffect(() => {
    if (!shouldUsePoshog) {
      return;
    }

    Sentry.init({
      dsn: 'https://38bca65ce5943ccedb3f696610a237a4@o4506198685515776.ingest.sentry.io/4506198690365440',
      integrations: [
        new posthog.SentryIntegration(posthog, 'readcloud', 4506198690365440),
        new Sentry.BrowserTracing({
          tracePropagationTargets: [
            'localhost',
            /^https:\/\/(api|rcs|app)\.readcloud\.com/,
          ],
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, // Capture 100% of the transactions
    });
    posthog.init(props.apiKey, {
      api_host: 'https://app.posthog.com',
      persistence: 'localStorage+cookie',
    });
    const unsubFeatureFlags = posthog.onFeatureFlags(function () {
      setState((prevState) => ({
        ...prevState,
        isFeatureEnabled: (key) => posthog.isFeatureEnabled(key),
      }));
    });
    return () => {
      unsubFeatureFlags();
    };
  }, []);
  useEffect(() => {
    if (!shouldUsePoshog) {
      return;
    }
    if (user?.sub) {
      posthog.identify(user.sub, {
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
        role: user.role,
        institution: user.institution,
        scope: user.scope,
        clientId: user.clientId,
      });
    }
  }, [user?.sub]);

  return (
    <analyticsContext.Provider value={state}>
      {props.children}
    </analyticsContext.Provider>
  );
}

export function useAnalytics() {
  const ctx = useContext(analyticsContext);
  if (!ctx) {
    throw Error('useAnalytics must be used within AnalyticsProvider');
  }
  return ctx;
}

export function trackEvent(...event: CustomEvent) {
  return posthog.capture(event[0], {
    ...event[1],
    // @ts-expect-error: COMMIT_SHA on window relies on a global interface augmentation. We should be using env but it requires more setup.
    commitSHA: window.COMMIT_SHA,
    // @ts-expect-error: COMMIT_DATE on window relies on a global interface augmentation. We should be using env but it requires more setup.
    commitDate: Date.parse(window.COMMIT_DATE),
  });
}
