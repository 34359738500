import moize from 'moize';
import { ChangeEvent } from 'react';
export const makeEventChangeCb = moize(
  <V = any>(cb: (v: V) => void) =>
    (e: ChangeEvent<{ value: V }>) =>
      cb(e.target.value)
);
export const makeSecondArgChangeCb = moize(
  <V = any>(cb: (v: V) => void) =>
    (_: any, v: V) =>
      cb(v)
) as <V>(cb: (v: V) => void) => (_: any, v: V) => any;
