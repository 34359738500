import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export const getImmersiveReaderToken = (config?: AxiosRequestConfig) => {
  return getApiv14().get('/auth_3dp/immerse', {
    ...config,
  });
};

// User preferences are pulled down when the application first loads in a get/all,
// there is no reason to create a get request currently.
