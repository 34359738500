import { MappedOrder, UpdateMappedOrderRequest } from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export interface MultiOrderAddRequest {
  name: string;
  orders: string[];
  resellerId: string;
}

export const addMultiOrder = (req: MultiOrderAddRequest) =>
  getApiv14().post('/add/multiorder', req);

export const deleteMultiOrder = (name: string, resellerId: string) =>
  getApiv14().delete('/delete/multiorder', { data: { name, resellerId } });

export const resellerUpdateMappedOrder = (
  req: UpdateMappedOrderRequest,
  apiKey: string
) =>
  getApiv14().post('/reseller/update/mappedOrder', req, {
    params: {
      apiKey,
    },
  });

export const searchMultiOrder = (searchKey: string, searchString: string) =>
  getApiv14().get('/search/multiorder', {
    params: { searchKey: searchString },
  });

export const getAllMappedOrders = (config?: AxiosRequestConfig) =>
  getApiv14().get('/multi_order/all', {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const getOrderableBooks = (config?: AxiosRequestConfig) =>
  getApiv14().get('/get_orderable_books', {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const searchMappedOrdersAdvanced = (
  req: {
    limit: number;
    offset: number;
    order: string;
    resellerIds?: string[];
    disableLookup: {
      reseller: boolean;
      book: boolean;
    };
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/multi_order', req, config);

export const createMappedOrder = (
  order: MappedOrder,
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/multi_order', [order], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const updateMappedOrder = (
  order: Partial<MappedOrder>,
  config?: AxiosRequestConfig
) =>
  getApiv14().patch('/multi_order', [order], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const removeMappedOrder = (
  orderId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().delete('/multi_order/' + orderId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });

export const searchMappedOrderTransactionsAdvanced = (
  req: {
    limit: number;
    offset: number;
    order: string;
    resellerIds?: string[];
    disableLookup: {
      reseller: boolean;
      user: boolean;
      institution: boolean;
    };
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/multi_order_transaction', req, config);
