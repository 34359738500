// @ts-nocheck
//
// charts.js
// Theme module
//

import Chart from 'chart.js';

//
// letiables
//
export const updatedChart = () => {
  const colors = {
    gray: {
      300: '#E3EBF6',
      600: '#95AAC9',
      700: '#6E84A3',
      800: '#152E4D',
      900: '#283E59',
    },
    primary: {
      100: '#D2DDEC',
      300: '#A6C5F7',
      700: '#2C7BE5',
    },
    black: '#12263F',
    white: '#FFFFFF',
    transparent: 'transparent',
  };

  const fonts = {
    base: 'Cerebri Sans',
  };

  const toggle = document.querySelectorAll('[data-toggle="chart"]');
  const legend = document.querySelectorAll('[data-toggle="legend"]');

  //
  // Functions
  //

  function globalOptions() {
    // Global

    Chart.defaults.global.responsive = true;
    Chart.defaults.global.maintainAspectRatio = false;

    // Default
    Chart.defaults.global.defaultColor = colors.gray[600];
    Chart.defaults.global.defaultFontColor = colors.gray[600];
    Chart.defaults.global.defaultFontFamily = fonts.base;
    Chart.defaults.global.defaultFontSize = 13;

    // Layout
    Chart.defaults.global.layout.padding = 0;

    // Legend
    Chart.defaults.global.legend.display = false;
    Chart.defaults.global.legend.position = 'bottom';
    Chart.defaults.global.legend.labels.usePointStyle = true;
    Chart.defaults.global.legend.labels.padding = 16;

    // Point
    Chart.defaults.global.elements.point.radius = 0;
    Chart.defaults.global.elements.point.backgroundColor = colors.primary[700];

    // Line
    Chart.defaults.global.elements.line.tension = 0.4;
    Chart.defaults.global.elements.line.borderWidth = 3;
    Chart.defaults.global.elements.line.borderColor = colors.primary[700];
    Chart.defaults.global.elements.line.backgroundColor = colors.transparent;
    Chart.defaults.global.elements.line.borderCapStyle = 'rounded';

    // Rectangle
    Chart.defaults.global.elements.rectangle.backgroundColor =
      colors.primary[700];

    // Arc
    Chart.defaults.global.elements.arc.backgroundColor = colors.primary[700];
    Chart.defaults.global.elements.arc.borderColor = colors.white;
    Chart.defaults.global.elements.arc.borderWidth = 4;
    Chart.defaults.global.elements.arc.hoverBorderColor = colors.white;

    // Tooltips
    Chart.defaults.global.tooltips.enabled = false;
    Chart.defaults.global.tooltips.mode = 'index';
    Chart.defaults.global.tooltips.intersect = false;
    Chart.defaults.global.tooltips.custom = function (model) {
      let tooltip = document.getElementById('chart-tooltip');

      if (!tooltip) {
        tooltip = document.createElement('div');

        tooltip.setAttribute('id', 'chart-tooltip');
        tooltip.setAttribute('role', 'tooltip');
        tooltip.classList.add('popover');
        tooltip.classList.add('bs-popover-top');

        document.body.appendChild(tooltip);
      }

      if (model.opacity === 0) {
        tooltip.style.visibility = 'hidden';
        return;
      }

      function getBody(bodyItem) {
        return bodyItem.lines;
      }

      if (model.body) {
        let titleLines = model.title || [];
        let bodyLines = model.body.map(getBody);
        let html = '';

        html += '<div class="arrow"></div>';

        titleLines.forEach(function (title) {
          html += '<h3 class="popover-header text-center">' + title + '</h3>';
        });

        bodyLines.forEach(function (body, i) {
          let colors = model.labelColors[i];
          let styles = 'background-color: ' + colors.backgroundColor;
          let indicator =
            '<span class="popover-body-indicator" style="' +
            styles +
            '"></span>';
          let align =
            bodyLines && bodyLines.length > 1
              ? 'justify-content-left'
              : 'justify-content-center';

          html +=
            '<div class="popover-body d-flex align-items-center ' +
            align +
            '">' +
            indicator +
            body +
            '</div>';
        });

        tooltip.innerHTML = html;
      }

      let canvas = this._chart.canvas;
      let canvasRect = canvas.getBoundingClientRect();

      let canvasWidth = canvas.offsetWidth;
      let canvasHeight = canvas.offsetHeight;

      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      let scrollLeft =
        window.pageXOffset ||
        document.documentElement.scrollLeft ||
        document.body.scrollLeft ||
        0;

      let canvasTop = canvasRect.top + scrollTop;
      let canvasLeft = canvasRect.left + scrollLeft;

      let tooltipWidth = tooltip.offsetWidth;
      let tooltipHeight = tooltip.offsetHeight;

      let top = canvasTop + model.caretY - tooltipHeight - 16;
      let left = canvasLeft + model.caretX - tooltipWidth / 2;

      tooltip.style.top = top + 'px';
      tooltip.style.left = left + 'px';
      tooltip.style.visibility = 'visible';
    };
    Chart.defaults.global.tooltips.callbacks.label = function (item, data) {
      let label = data.datasets[item.datasetIndex].label || '';
      let yLabel = item.yLabel;
      let content = '';

      if (data.datasets && data.datasets.length > 1) {
        content +=
          '<span class="popover-body-label mr-auto">' + label + '</span>';
      }

      content += '<span class="popover-body-value">' + yLabel + '</span>';

      return content;
    };

    // Doughnut
    Chart.defaults.doughnut.cutoutPercentage = 83;
    Chart.defaults.doughnut.tooltips.callbacks.title = function (item, data) {
      let title = data.labels[item[0].index];
      return title;
    };
    Chart.defaults.doughnut.tooltips.callbacks.label = function (item, data) {
      let value = data.datasets[0].data[item.index];
      let content = '';

      content += '<span class="popover-body-value">' + value + '</span>';
      return content;
    };
    Chart.defaults.doughnut.legendCallback = function (chart) {
      let data = chart.data;
      let content = '';

      data.labels.forEach(function (label, index) {
        let bgColor = data.datasets[0].backgroundColor[index];

        content += '<span class="chart-legend-item">';
        content +=
          '<i class="chart-legend-indicator" style="background-color: ' +
          bgColor +
          '"></i>';
        content += label;
        content += '</span>';
      });

      return content;
    };

    // yAxes
    Chart.scaleService.updateScaleDefaults('linear', {
      gridLines: {
        borderDash: [2],
        borderDashOffset: [2],
        color: colors.gray[300],
        drawBorder: false,
        drawTicks: false,
        zeroLineColor: colors.gray[300],
        zeroLineBorderDash: [2],
        zeroLineBorderDashOffset: [2],
      },
      ticks: {
        beginAtZero: true,
        padding: 10,
        callback: function (value) {
          if (!(value % 10)) {
            return value;
          }
        },
      },
    });

    // xAxes
    Chart.scaleService.updateScaleDefaults('category', {
      gridLines: {
        drawBorder: false,
        drawOnChartArea: false,
        drawTicks: false,
      },
      ticks: {
        padding: 20,
      },
      maxBarThickness: 10,
    });
  }

  function toggleOptions(el) {
    let target = el.dataset.target;
    let targetEl = document.querySelector(target);
    let chart = getChartInstance(targetEl);
    let options = JSON.parse(el.dataset.add);

    if (el.checked) {
      pushOptions(chart, options);
    } else {
      popOptions(chart, options);
    }

    chart.update();
  }

  function updateOptions(el) {
    let target = el.dataset.target;
    let targetEl = document.querySelector(target);
    let chart = getChartInstance(targetEl);
    let options = JSON.parse(el.dataset.update);
    let prefix = el.dataset.prefix;
    let suffix = el.dataset.suffix;

    parseOptions(chart, options);

    if (prefix || suffix) {
      toggleTicks(chart, prefix, suffix);
    }

    chart.update();
  }

  function parseOptions(chart, options) {
    for (let item in options) {
      if (typeof options[item] !== 'object') {
        chart[item] = options[item];
      } else {
        parseOptions(chart[item], options[item]);
      }
    }
  }

  function pushOptions(chart, options) {
    for (let item in options) {
      if (Array.isArray(options[item])) {
        options[item].forEach(function (data) {
          chart[item].push(data);
        });
      } else {
        pushOptions(chart[item], options[item]);
      }
    }
  }

  function popOptions(chart, options) {
    for (let item in options) {
      if (Array.isArray(options[item])) {
        options[item].forEach(function (data) {
          chart[item].pop();
        });
      } else {
        popOptions(chart[item], options[item]);
      }
    }
  }

  function toggleTicks(chart, prefix, suffix) {
    prefix = prefix ? prefix : '';
    suffix = suffix ? suffix : '';

    chart.options.scales.yAxes[0].ticks.callback = function (value) {
      if (!(value % 10)) {
        return prefix + value + suffix;
      }
    };

    chart.options.tooltips.callbacks.label = function (item, data) {
      let label = data.datasets[item.datasetIndex].label || '';
      let yLabel = item.yLabel;
      let content = '';

      if (data.datasets && data.datasets.length > 1) {
        content +=
          '<span class="popover-body-label mr-auto">' + label + '</span>';
      }

      content +=
        '<span class="popover-body-value">' +
        prefix +
        yLabel +
        suffix +
        '</span>';
      return content;
    };
  }

  function toggleLegend(el) {
    let chart = getChartInstance(el);
    let legend = chart.generateLegend();
    let target = el.dataset.target;
    let targetEl = document.querySelector(target);

    targetEl.innerHTML = legend;
  }

  function getChartInstance(chart) {
    let chartInstance = undefined;

    helpers.each(instances, function (instance) {
      if (chart == instance.chart.canvas) {
        chartInstance = instance;
      }
    });

    return chartInstance;
  }

  //
  // Events
  //

  if (typeof Chart !== 'undefined') {
    // Global options
    globalOptions();

    // Toggle chart
    if (toggle) {
      [].forEach.call(toggle, function (el) {
        el.addEventListener('change', function () {
          if (el.dataset.add) {
            toggleOptions(el);
          }
        });
        el.addEventListener('click', function () {
          if (el.dataset.update) {
            updateOptions(el);
          }
        });
      });
    }

    // Toggle lenegd
    if (legend) {
      document.addEventListener('DOMContentLoaded', function () {
        [].forEach.call(legend, function (el) {
          toggleLegend(el);
        });
      });
    }
  }

  //change how rectangle are drawn. (rounded corners)
  Chart.elements.Rectangle.prototype.draw = function () {
    let ctx = this._chart.ctx;
    let vm = this._view;
    let left, right, top, bottom, signX, signY, borderSkipped, radius;
    let borderWidth = vm.borderWidth;
    // Set Radius Here
    // If radius is large enough to cause drawing errors a max radius is imposed
    let cornerRadius = 6;

    if (!vm.horizontal) {
      // bar
      left = vm.x - vm.width / 2;
      right = vm.x + vm.width / 2;
      top = vm.y;
      bottom = vm.base;
      signX = 1;
      signY = bottom > top ? 1 : -1;
      borderSkipped = vm.borderSkipped || 'bottom';
    } else {
      // horizontal bar
      left = vm.base;
      right = vm.x;
      top = vm.y - vm.height / 2;
      bottom = vm.y + vm.height / 2;
      signX = right > left ? 1 : -1;
      signY = 1;
      borderSkipped = vm.borderSkipped || 'left';
    }

    // Canvas doesn't allow us to stroke inside the width so we can
    // adjust the sizes to fit if we're setting a stroke on the line
    if (borderWidth) {
      // borderWidth shold be less than bar width and bar height.
      let barSize = Math.min(Math.abs(left - right), Math.abs(top - bottom));
      borderWidth = borderWidth > barSize ? barSize : borderWidth;
      let halfStroke = borderWidth / 2;
      // Adjust borderWidth when bar top position is near vm.base(zero).
      let borderLeft =
        left + (borderSkipped !== 'left' ? halfStroke * signX : 0);
      let borderRight =
        right + (borderSkipped !== 'right' ? -halfStroke * signX : 0);
      let borderTop = top + (borderSkipped !== 'top' ? halfStroke * signY : 0);
      let borderBottom =
        bottom + (borderSkipped !== 'bottom' ? -halfStroke * signY : 0);
      // not become a vertical line?
      if (borderLeft !== borderRight) {
        top = borderTop;
        bottom = borderBottom;
      }
      // not become a horizontal line?
      if (borderTop !== borderBottom) {
        left = borderLeft;
        right = borderRight;
      }
    }

    ctx.beginPath();
    ctx.fillStyle = vm.backgroundColor;
    ctx.strokeStyle = vm.borderColor;
    ctx.lineWidth = borderWidth;

    // Corner points, from bottom-left to bottom-right clockwise
    // | 1 2 |
    // | 0 3 |
    let corners = [
      [left, bottom],
      [left, top],
      [right, top],
      [right, bottom],
    ];

    // Find first (starting) corner with fallback to 'bottom'
    let borders = ['bottom', 'left', 'top', 'right'];
    let startCorner = borders.indexOf(borderSkipped, 0);
    if (startCorner === -1) {
      startCorner = 0;
    }

    function cornerAt(index) {
      return corners[(startCorner + index) % 4];
    }

    // Draw rectangle from 'startCorner'
    let corner = cornerAt(0);
    ctx.moveTo(corner[0], corner[1]);

    for (let i = 1; i < 4; i++) {
      let corner = cornerAt(i);
      let nextCornerId = i + 1;
      if (nextCornerId == 4) {
        nextCornerId = 0;
      }

      let nextCorner = cornerAt(nextCornerId);

      let width = corners[2][0] - corners[1][0];
      let height = corners[0][1] - corners[1][1];
      let x = corners[1][0];
      let y = corners[1][1];

      let radius = cornerRadius;

      // Fix radius being too large
      if (radius > height / 2) {
        radius = height / 2;
      }
      if (radius > width / 2) {
        radius = width / 2;
      }

      ctx.moveTo(x + radius, y);
      ctx.lineTo(x + width - radius, y);
      ctx.quadraticCurveTo(x + width, y, x + width, y + radius);
      ctx.lineTo(x + width, y + height - radius);
      ctx.quadraticCurveTo(
        x + width,
        y + height,
        x + width - radius,
        y + height
      );
      ctx.lineTo(x + radius, y + height);
      ctx.quadraticCurveTo(x, y + height, x, y + height - radius);
      ctx.lineTo(x, y + radius);
      ctx.quadraticCurveTo(x, y, x + radius, y);
    }

    ctx.fill();
    if (borderWidth) {
      ctx.stroke();
    }
  };

  return Chart;
};
