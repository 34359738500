import resolveConfig from 'tailwindcss/resolveConfig';
import { Config, ThemeConfig } from 'tailwindcss/types/config';

export const extentions = {
  fontFamily: {
    // We'll need to add a robus font family fallback so that something decent shows during font download.
    sans: ['Montserrat', 'Cerebri Sans'],
    // We should override serif and mono too with something good looking.
  },
  colors: {
    primary: {
      DEFAULT: '#0056A4',
      50: '#E6F3FF',
      100: '#C3E2FF',
      200: '#7BC0FF',
      300: '#349EFF',
      400: '#007BEB',
      500: '#0056A4',
      600: '#004E95',
      700: '#004685',
      800: '#003E76',
      900: '#003667',
      950: '#00325F',
    },
    secondary: '#00A9AA',
    oldPrimary: '#6e84a3',
    light: {
      DEFAULT: '#ffffff',
      100: '#f9fbfd',
      200: '#edf2f9',
      300: '#e3ebf6',
      400: '#d2ddec',
    },
    dark: '#12263f',
  },
  height: {
    // @ts-expect-error - this is actually valid according to Tailwind (100vh is the fallback value), it's just that it's types are dodgy
    screen: ['100vh', '100dvh'] as string,
  },
} satisfies NonNullable<Config['theme']['extend']>;

export const tailwind = resolveConfig({
  content: [],
  theme: {
    extend: extentions,
  },
}) as unknown as Config & {
  theme: ThemeConfig & typeof extentions;
};
