import { AppModule } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppModuleState } from './types';

const initialState: AppModuleState = {
  appModules: [],
};

import { GetAppModulesThunkAction, UpdateAppModuleThunkAction } from './thunk';

const name = 'appModules';

const asyncActions = {
  getAppModules: createAsyncThunk(`${name}/get`, GetAppModulesThunkAction),
  updateAppModule: createAsyncThunk(
    `${name}/update`,
    UpdateAppModuleThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setAppModules(state, action: PayloadAction<AppModule[]>) {
      state.appModules = action.payload;
    },
    addAppModules(state, action: PayloadAction<AppModule[]>) {
      state.appModules.push(...action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getAppModules.fulfilled, (state, action) => {
      state.appModules = action.payload;
    });
    builder.addCase(asyncActions.updateAppModule.fulfilled, (state, action) => {
      const index = state.appModules.findIndex(
        (annotation) => annotation.id === action.payload.id
      );
      if (index !== -1) {
        state.appModules[index] = {
          ...state.appModules[index],
          ...action.payload,
        };
      }
    });
  },
});

const { actions, reducer } = slice;

export const appModulesReducer = reducer;

export const appModulesActions = { ...actions, asyncActions };
