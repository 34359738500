import * as ReactDOM from 'react-dom';

// @ts-expect-error -- This package is a submodule of vite-plugin-pwa apparently. If that plugin has been set up correctly this should exist.
import { registerSW } from 'virtual:pwa-register';
import AppRoot from './AppRoot';

// ##########################################################
// DO NOT REMOVE
// ==========================================================
// This if statement is responsible for installing the service worker.
// It will install the service worker on web if this is removed.
// ==========================================================

if (typeof (window as any).__TAURI__ !== 'undefined') {
  console.log('TAURI detected, registering service worker');
  registerSW({
    onNeedRefresh() {
      // Optionally show a message to the user to refresh the page
      console.log('New content available, please refresh.');
    },
    onOfflineReady() {
      // Optionally inform the user that the app is ready to work offline
      console.log('App is ready to work offline.');
    },
  });
} else {
  console.log('TAURI not detected, skipping service worker registration');
}

ReactDOM.render(<AppRoot />, document.getElementById('root'));
