import { BookAnalyticsContext } from '@root/libs/common/src/lib/analyticsEvents';
import { RootState } from './features';
import { getLatestRcsResources, getTileType } from '@root/libs/common/src';

export function getReaderAnalyticsContext(
  getState: () => RootState
): BookAnalyticsContext {
  const readerState = getState().ReaderState;
  const booksState = getState().BooksState;
  const cloudsState = getState().CloudsState;
  const book = booksState.books.find(
    (book) => book.id === readerState.readerData.bookId
  );
  const rcsUrl = book?.rcsData
    ? getLatestRcsResources(book.rcsData)?.bookbytesUrl
    : undefined;
  const contentUrl = rcsUrl || book.downloadURI;
  const bookType = contentUrl ? getTileType(contentUrl) : 'UNKNOWN';

  return {
    bookType,
    bookId: readerState.readerData.bookId,
    bookTitle: readerState.readerData.title,
    cloudId: readerState.readerData.cloudId,
    cloudName: cloudsState.clouds[readerState.readerData.cloudId]?.name,
    visiblePageStart: readerState.readerData.viewState?.pageNumberStart,
    visiblePageEnd: readerState.readerData.viewState?.pageNumberEnd,
  };
}
