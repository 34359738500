import { DTO } from '@readcloud/common';
import { getApiv14 } from './apiv14Setup';

export const updateViewState = (
  currentDateStr: string,
  currentDateMs: number,
  bookId: string,
  req?: DTO.ViewState
) => {
  return getApiv14().put(
    '/update/app_state',
    [
      {
        tags: { webComponent: 'Reader', realm: 'ViewState', bookId: bookId },
        state: req,
        date: currentDateStr,
        version: '3',
      },
    ],
    { params: { currentDateMs } }
  );
};

// User preferences are pulled down when the application first loads in a get/all,
// there is no reason to create a get request currently.
