import axios, { AxiosInstance } from 'axios';
import { compose } from 'ramda';
import { addCommonAxiosConfig } from '../client';
import { makeAuthAssertionInterceptor } from '../makeAuthInterceptor';

let newapiUrl;

const addNewapiUrlInterceptor = (client) => {
  client.interceptors.request.use((config) => ({
    baseURL: newapiUrl,
    ...config,
  }));
  return client;
};

const ensureNewApiClientReady = () => {
  if (!!newapiUrl) {
    return true;
  } else {
    throw new Error(
      'Newapi client was accessed before the base URL was set. Please configure this before accessing the client.'
    );
  }
};

const makeNewapiClient: any = compose(
  addNewapiUrlInterceptor,
  makeAuthAssertionInterceptor(),
  addCommonAxiosConfig,
  axios.create
);

const newapiClient = makeNewapiClient({});

export const getNewapi = (): AxiosInstance => (
  ensureNewApiClientReady(), newapiClient
);

export const setNewapiUrl = (url) => (newapiUrl = url);
