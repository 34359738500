import { Institution } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddUserThunkAction,
  GetConnectedUsersThunkAction,
  GetUserInstitutionsThunkAction,
  SearchUserAdvancedThunkAction,
  SearchUserThunkAction,
  UpdateUserThunkAction,
} from './thunk';
import { UsersState } from './types';

const initialState: UsersState = {
  institutions: {},
  viewingInstitution: null,
  searchUserResult: [],
  searchUserAdvancedResult: {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: 0,
    },
  },
  apiRequestResult: {
    performed: false,
    success: true,
    errMsg: '',
  },
  users: [],
  searchEnrolmentAdvancedResult: {
    result: {
      items: [],
      moreAvailable: false,
      nextOffset: 0,
      timeMs: 0,
    },
  },
};

const name = 'users';

const asyncActions = {
  addUser: createAsyncThunk(`${name}/addUser`, AddUserThunkAction),
  updateUser: createAsyncThunk(`${name}/updateUser`, UpdateUserThunkAction),
  getUserInstitutions: createAsyncThunk(
    `${name}/getUserInstitutions`,
    GetUserInstitutionsThunkAction
  ),
  searchUsers: createAsyncThunk(`${name}/searchUsers`, SearchUserThunkAction),
  searchUsersAdvanced: createAsyncThunk(
    `${name}/searchUsersAdvanced`,
    SearchUserAdvancedThunkAction
  ),
  getConnectedUsers: createAsyncThunk(
    `${name}/getConnectedUsers`,
    GetConnectedUsersThunkAction
  ),
  // searchEnrolment: createAsyncThunk(
  //   `${name}/searchEnrolment`,
  //   SearchEnrolmentAdvancedThunkAction
  // ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    apiRequestClear(state) {
      state.apiRequestResult = {
        performed: false,
        success: true,
        errMsg: '',
      };
    },
    setDefaultInstitution(state, action: PayloadAction<Institution>) {
      state.viewingInstitution = action.payload;
    },
    searchEnrolmentadvanced(state, action) {
      state.searchEnrolmentAdvancedResult = action.payload;
      state.searchEnrolmentAdvancedResult.result.timeMs = Date.now();
    },
    searchEnrolmentadvancedMore(state, action) {
      state.searchEnrolmentAdvancedResult.result.items.push(
        ...action.payload.result.items
      );
      state.searchEnrolmentAdvancedResult.result.moreAvailable =
        action.payload.result.moreAvailable;
      state.searchEnrolmentAdvancedResult.result.nextOffset =
        action.payload.result.nextOffset;
      state.searchEnrolmentAdvancedResult.result.timeMs = Date.now();
    },
    addUsers(state, action) {
      state.users.push(action.payload);
    },
    deltaUsers(state, action) {
      //keep track of user we're updating.
      const updatedUserIds = [];

      action.payload.forEach((newUpdatedUser) => {
        //find and replace.
        const index = state.users.findIndex(
          (user) => user.id === newUpdatedUser.id
        );
        if (index >= 0) {
          if (!newUpdatedUser.deleted) {
            //replace user
            state.users[index] = newUpdatedUser;
          } else {
            //delete user
            state.users.splice(index, 1);
          }
          updatedUserIds.push(newUpdatedUser.id);
        }
      });

      //filter the annos we have already updated.
      const newUsers = action.payload.filter(
        (user) => !updatedUserIds.includes(user.id) && !user.deleted
      );

      //add the rest to state.
      state.users.unshift(...newUsers);
    },
    setUsers(state, action) {
      state.users = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.addUser.fulfilled, (state, action) => {
      state.searchUserAdvancedResult.result.items.push(action.payload);
      state.apiRequestResult = {
        performed: true,
        success: true,
        errMsg: '',
      };
    });
    builder.addCase(asyncActions.addUser.rejected, (state) => {
      state.apiRequestResult = {
        performed: true,
        success: false,
        errMsg:
          'Error: cannot add user. Check that the entered values are valid.',
      };
    });
    builder.addCase(asyncActions.updateUser.fulfilled, (state, action) => {
      const index = state.searchUserAdvancedResult.result.items.findIndex(
        (item) => item.user.id === action.payload.user.id
      );

      if (index !== -1) {
        state.searchUserAdvancedResult.result.items[index].user =
          action.payload.user;
        state.apiRequestResult = {
          performed: true,
          success: true,
          errMsg: '',
        };
      }
    });
    builder.addCase(asyncActions.updateUser.rejected, (state) => {
      state.apiRequestResult = {
        performed: true,
        success: false,
        errMsg:
          'Error: cannot update user. Check that the entered values are valid.',
      };
    });
    builder.addCase(
      asyncActions.getUserInstitutions.fulfilled,
      (state, action) => {
        state.institutions = action.payload;
      }
    );
    builder.addCase(asyncActions.searchUsers.fulfilled, (state, action) => {
      state.searchUserResult = action.payload;
    });
    builder.addCase(
      asyncActions.searchUsersAdvanced.fulfilled,
      (state, action) => {
        const {
          result: { items },
          offset,
        } = action.payload;

        state.searchUserAdvancedResult = {
          result: {
            ...action.payload.result,
            items:
              offset === 0
                ? items
                : [...state.searchUserAdvancedResult.result.items, ...items],
          },
        };
      }
    );
    builder.addCase(
      asyncActions.getConnectedUsers.fulfilled,
      (state, action) => {
        state.users = action.payload;
      }
    );
  },
});

const { actions, reducer } = slice;

export const usersReducer = reducer;

export const usersActions = { ...actions, asyncActions };
