import { Institution } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  AddInstitutionThunkAction,
  DeleteInstitutionThunkAction,
  GetInstitutionReportsThunkAction,
  GetInstitutionsThunkAction,
  GetInstitutionThunkAction,
  updateInstitutionGenericRegexps,
  updateInstitutionSpecificRegexps,
  updateInstitutionSubjects,
  UpdateInstitutionThunkAction,
  updateInstitutionYearLevels,
} from './thunk';
import { InstitutionsState } from './types';

const initialState: InstitutionsState = {
  institutions: {},
  institution: null,
  reports: null,
};

const name = 'institution';

const asyncActions = {
  getInstitutions: createAsyncThunk(`${name}/get`, GetInstitutionsThunkAction),
  getInstitution: createAsyncThunk(
    `${name}/get/:id`,
    GetInstitutionThunkAction
  ),
  addInstitution: createAsyncThunk(`${name}/add`, AddInstitutionThunkAction),
  updateInstitution: createAsyncThunk(
    `${name}/update`,
    UpdateInstitutionThunkAction
  ),
  deleteInstitution: createAsyncThunk(
    `${name}/delete`,
    DeleteInstitutionThunkAction
  ),
  updateGenericRegexps: createAsyncThunk(
    `${name}/updateGenericRegexps`,
    updateInstitutionGenericRegexps
  ),
  updateSpecificRegexps: createAsyncThunk(
    `${name}/updateSpecificRegexps`,
    updateInstitutionSpecificRegexps
  ),
  updateSubjects: createAsyncThunk(
    `${name}/updateSubjects`,
    updateInstitutionSubjects
  ),
  updateYearLevels: createAsyncThunk(
    `${name}/updateYearLevels`,
    updateInstitutionYearLevels
  ),
  getInstitutionPublisherReports: createAsyncThunk(
    `${name}/getInstitutionPublisherReports`,
    GetInstitutionReportsThunkAction
  ),
};
const slice = createSlice({
  name,
  initialState,
  reducers: {
    setInstitutions(state, action: PayloadAction<Record<string, Institution>>) {
      state.institutions = action.payload;
    },
    addInstitutions(state, action: PayloadAction<Record<string, Institution>>) {
      state.institutions = {
        ...state.institutions,
        ...action.payload,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.addInstitution.fulfilled, (state, action) => {
      state.institutions[action.payload.id] = action.payload;
    });
    builder.addCase(asyncActions.getInstitutions.fulfilled, (state, action) => {
      state.institutions = action.payload;
    });
    builder.addCase(asyncActions.getInstitution.fulfilled, (state, action) => {
      state.institution = action.payload;
    });
    builder.addCase(
      asyncActions.deleteInstitution.fulfilled,
      (state, action) => {
        delete state.institutions[action.payload];
      }
    );
    builder.addCase(
      asyncActions.updateInstitution.fulfilled,
      (state, action) => {
        state.institutions[action.payload.id] = {
          ...state.institutions[action.payload.id],
          ...action.payload,
        };
      }
    );
    builder.addCase(
      asyncActions.updateSpecificRegexps.fulfilled,
      (state, action) => {
        state.institutions[
          action.payload.institutionId
        ].classNameMappings.specificRegexps = action.payload.specificRegexps;
      }
    );
    builder.addCase(
      asyncActions.updateGenericRegexps.fulfilled,
      (state, action) => {
        state.institutions[
          action.payload.institutionId
        ].classNameMappings.genericRegexps = action.payload.genericRegexps;
      }
    );
    builder.addCase(asyncActions.updateSubjects.fulfilled, (state, action) => {
      state.institutions[
        action.payload.institutionId
      ].classNameMappings.subjects = action.payload.subjects;
    });
    builder.addCase(
      asyncActions.updateYearLevels.fulfilled,
      (state, action) => {
        state.institutions[
          action.payload.institutionId
        ].classNameMappings.yearLevels = action.payload.yearLevels;
      }
    );
    builder.addCase(
      asyncActions.getInstitutionPublisherReports.fulfilled,
      (state, action) => {
        state.reports = action.payload;
      }
    );
  },
});

const { actions, reducer } = slice;

export const institutionsReducer = reducer;

export const institutionsActions = { ...actions, asyncActions };
