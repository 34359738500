import { ProductBundle } from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export const getAllProductBundles = (config?: AxiosRequestConfig) =>
  getApiv14().get('/product_bundle/all', {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const getProductBundle = (
  productBundleId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().get('/product_bundle/' + productBundleId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const createProductBundles = (
  productBundles: ProductBundle[],
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/product_bundle', productBundles, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const createProductBundle = (
  productBundle: ProductBundle,
  config?: AxiosRequestConfig
) =>
  getApiv14().post('/product_bundle', [productBundle], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const updateProductBundles = (
  productBundles: Partial<ProductBundle>[],
  config?: AxiosRequestConfig
) =>
  getApiv14().patch('/product_bundle', productBundles, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const updateProductBundle = (
  productBundle: Partial<ProductBundle>,
  config?: AxiosRequestConfig
) =>
  getApiv14().patch('/product_bundle', [productBundle], {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
export const removeProductBundle = (
  productBundlesId: string,
  config?: AxiosRequestConfig
) =>
  getApiv14().delete('/product_bundle/' + productBundlesId, {
    ...config,
    params: {
      ...(config ? config.params : {}),
    },
  });
