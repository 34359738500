import { AppModule } from '@readcloud/data';
import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export const getAppModules = (config?: AxiosRequestConfig) =>
  getApiv14().get('/app_module/all', config);

export const updateAppModule = (
  uid: string,
  req: Partial<AppModule>,
  config?: AxiosRequestConfig
) => getApiv14().patch('/app_module/' + uid, req, config);
