import { DTO } from '@readcloud/common';
import { getApiv14 } from './apiv14Setup';

export const addComment = (req: DTO.CreateComment) =>
  getApiv14().post('/add/comment', req);

export const listComments = () => getApiv14().get('/list/comment.json');

export const deleteComment = (id: string) =>
  getApiv14().delete('/delete/comment', { params: { id } });

export const updateComment = (id: string, req: DTO.UpdateComment) =>
  getApiv14().put('/update/comment', req, { params: { id } });

export const deltaComments = (millisec: number) =>
  getApiv14().get('/delta/comments', { params: millisec });
