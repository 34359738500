import {
  decodeJwt,
  jwtPayload,
  PasswordTokenRequest,
  RefreshTokenRequest,
} from '@readcloud/common';
import { AxiosPromise, AxiosResponse } from 'axios';
import {
  getAccessToken,
  getClientId,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
} from '../client';
import { isJwtValid } from '../utils';
import { getApiv14 } from './apiv14Setup';
interface TokenResponseBody {
  result: {
    accessToken: string;
    refreshToken: string;
  };
  error?: string;
}

export const getToken = (
  body: PasswordTokenRequest | RefreshTokenRequest
): AxiosPromise<TokenResponseBody> => getApiv14().post('/token', body);

const handleAuthResponse = (response: AxiosResponse<TokenResponseBody>) => {
  //console.log(response);
  if (response.data.error) {
    throw new Error(response.data.error);
  } else {
    const {
      data: {
        result: { accessToken, refreshToken },
      },
    } = response;
    setRefreshToken(refreshToken);
    setAccessToken(accessToken);
    const decoded = decodeJwt(accessToken).payload;
    if (!jwtPayload.is(decoded)) {
      throw new Error('JWT payload is invalid');
    }
    return {
      accessToken,
      refreshToken,
      decoded,
    };
  }
};

export const authenticate = (email, password) => {
  const clientId = getClientId();
  if (clientId) {
    return getToken({
      clientId,
      grantType: 'Password',
      email,
      password,
    }).then(handleAuthResponse);
  } else {
    throw new Error(
      'Client id is not defined. You must set this before authenticating'
    );
  }
};

export const reAuthenticate = (refreshToken) => {
  const clientId = getClientId();
  if (clientId) {
    return getToken({
      clientId,
      grantType: 'RefreshToken',
      refreshToken,
    }).then(handleAuthResponse);
  } else {
    throw new Error(
      'Client id is not defined. You must set this before authenticating'
    );
  }
};

export const assertAuthentication = async () => {
  const accessToken = getAccessToken();
  const refreshToken = getRefreshToken();
  if (!accessToken) {
    throw new Error('Unauthorized - No access token found');
  }
  if (isJwtValid(accessToken)) {
    return;
  }

  if (!refreshToken) {
    throw new Error(
      'Unauthorized - Invalid or expired access token, and no refresh token found in order to attempt reauth'
    );
  }
  return await reAuthenticate(refreshToken);
};

export const isAuthenticated = () => !!getAccessToken();
