import C from 'classnames';
import * as React from 'react';
import { TestingProps } from '../../Types/Testing';

export interface ITextAreaProps extends TestingProps {
  name: string;
  size?: 'sm' | 'lg';
  type?: 'email' | 'password' | 'text' | 'radio' | 'checkbox' | 'number';
  customControl?: boolean;
  rounded?: boolean;
  flush?: boolean;
  auto?: boolean;
  className?: string;
  valid?: boolean;
  invalid?: boolean;
  placeholder?: string;
  value?: string;
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  readOnly?: boolean;
  disabled?: boolean;
  required?: boolean;
  customLabel?: string;
  checked?: boolean;
  elKey?: string;
  defaultValue?: string;
  style?: React.CSSProperties;
  autoCompleteOff?: boolean;
  rows?: number;
  disableSpellcheck?: boolean;
  wrapOff?: boolean;
}

const sizeMapper: { [index: string]: string } = {
  lg: 'form-control-lg',
  sm: 'form-control-sm',
};

export const TextArea = React.forwardRef<HTMLTextAreaElement, ITextAreaProps>(
  (
    {
      children,
      className,
      rounded,
      flush,
      auto,
      customControl,
      size,
      valid,
      invalid,
      placeholder,
      name,
      value,
      readOnly,
      disabled,
      onChange,
      onFocus,
      onBlur,
      testId,
      required,
      customLabel,
      autoCompleteOff,
      elKey,
      defaultValue,
      style,
      rows,
      disableSpellcheck,
      wrapOff
    },
    ref
  ) => {
    return customControl ? (
      <div className="custom-control custom-switch" key={elKey}>
        <textarea
          ref={ref}
          className={C(
            'form-control',
            'custom-control-textarea',
            'p-3',
            size && sizeMapper[size],
            rounded && 'form-control-rounded',
            flush && 'form-control-flush',
            auto && 'form-control-auto',
            valid && 'is-valid',
            invalid && 'is-invlaid',
            className && className
          )}
          rows={rows}
          style={style}
          readOnly={readOnly}
          disabled={disabled}
          id={name}
          name={name}
          placeholder={placeholder}
          value={value}
          defaultValue={defaultValue}
          onChange={onChange}
          onBlur={onBlur}
          onFocus={onFocus}
          data-testid={testId}
          required={required}
          key={elKey + '-textarea'}
          autoComplete={autoCompleteOff && 'off'}
          spellCheck={!disableSpellcheck}
          wrap={wrapOff && "off"}
        >
          {children}
        </textarea>
        <label className="custom-control-label" htmlFor={name}>
          {customLabel}
        </label>
      </div>
    ) : (
      <textarea
        ref={ref}
        className={C(
          'form-control',
          'p-3',
          size && sizeMapper[size],
          rounded && 'form-control-rounded',
          flush && 'form-control-flush',
          auto && 'form-control-auto',
          valid && 'is-valid',
          invalid && 'is-invlaid',
          className && className
        )}
        rows={rows}
        readOnly={readOnly}
        disabled={disabled}
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        data-testid={testId}
        required={required}
        key={elKey + '-textarea'}
        style={style}
        autoComplete={autoCompleteOff && 'off'}
        spellCheck={!disableSpellcheck}
        wrap={wrapOff && "off"}
      >
        {children}
      </textarea>
    );
  }
);
