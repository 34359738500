import { AxiosRequestConfig } from 'axios';
import { getApiv14 } from './apiv14Setup';

export type LogRealm =
  | 'StreamImport'
  | 'StreamImportReport'
  | 'StreamExport'
  | 'ExpiredBooks'
  | 'AdobeAuthSignIn'
  | 'ResellerBulkPurchaseReport'
  | 'ResourceCache'
  | 'Unknown';
export type LogSeverity =
  | 'Emergency'
  | 'Alert'
  | 'Critical'
  | 'Error'
  | 'Warning'
  | 'Notice'
  | 'Informational'
  | 'Debug'
  | 'Unknown';
export interface AddLogRequest {
  clientId: string;
  contentVersion?: string;
  institution: string;
  jobId?: string;
  realm: LogRealm;
  records: AddLogRecord[];
}

export interface AddLogRecord {
  clientDate: string;
  content: any;
  seq?: number;
  severity: LogSeverity;
}

export interface LogSearchRequest {
  institution?: string;
  realm: LogRealm | string;
  jobId?: string;
  minSeverity?: LogSeverity;
  dateFrom?: Date;
  dateTo?: Date;
  sortKey?: string[];
  sortValue?: number[];
  limit?: number;
  skip?: number;
  millisec?: number;
}

export const addLog = (log: AddLogRequest) => getApiv14().post('/add/log', log);

const formatDate = (date: Date) => {
  //return moment(date).format("YYYY-MM-DD");
  let m = (date.getMonth() + 1).toString();
  if (m.length === 1) m = '0' + m;
  let d = date.getDate().toString();
  if (d.length === 1) d = '0' + d;
  return date.getFullYear() + '-' + m + '-' + d;
};

export const searchLog = (
  req: LogSearchRequest,
  config?: AxiosRequestConfig
) => {
  let reqPrm = '/search/log?';
  if (req.institution && req.institution.length > 0)
    reqPrm += 'institution=' + req.institution;
  reqPrm += '&realm=' + req.realm;
  if (req.skip) reqPrm += '&skip=' + req.skip;
  if (req.limit) reqPrm += '&limit=' + req.limit;
  if (req.dateFrom)
    reqPrm += '&dateFrom=' + formatDate(req.dateFrom) + 'T00:00:00Z';
  if (req.dateTo) reqPrm += '&dateTo=' + formatDate(req.dateTo) + 'T00:00:00Z';
  //console.log(reqPrm);
  return getApiv14().get(reqPrm, { params: {}, ...config });
};

export const searchLogAdvanced = (
  req: {
    limit: number;
    offset: number;
    order: string;
    root: any;
  },
  config?: AxiosRequestConfig
) => getApiv14().post('/search/advanced/log', req, config);

/*/api/v14/product_transaction/count_by_jobid?institutionId=55c406631c40e8f2737c2db9\&origJobIds=37f1a51f-acc8-44e5-a54e-76bcb467aa10,37f1a51f-acc8-44e5-a54e-76bcb467aa11*/
export const transactionCount = (
  institutionId: string,
  origJobIds: string[],
  config?: AxiosRequestConfig
) => {
  let reqPrm = 'product_transaction/count_by_jobid?';
  if (institutionId && institutionId.length > 0)
    reqPrm += 'institutionId=' + institutionId;
  if (origJobIds && origJobIds.length > 0)
    reqPrm += '&origJobIds=' + origJobIds.join(',');
  //console.log(reqPrm);
  return getApiv14().get(reqPrm, { params: {}, ...config });
};
