import { RootState } from '../../rootReducer';

export const authSelectors = {
  getUser: (state: RootState) => state.AuthState.user,
  getUserScopeParams: (state: RootState) => state.AuthState.scopeParams,
  getResllerApiKey: (state: RootState) => state.AuthState.reseller,
  getAccessToken: (state: RootState) => state.AuthState.user.access_token,
  getUserPermissions: (state: RootState) => state.AuthState.permissions,
  getUserInstitution: (state: RootState) => state.AuthState.institution,
  getUserResellerId: (state: RootState) => state.AuthState.resellerId,
};
