import { getApiv14 } from './apiv14Setup';
export interface AuditRequest {
  activated: boolean;
  appVersion: string;
  deviceId: string;
  displayResolution: string;
  inetIpAddr: string;
  localIpAddr: string;
  osName: string;
  osVersion: string;
  proxyUsed: boolean;
  ramSize: string;
}
export const audit = (req: AuditRequest) => getApiv14().post('/audit', req);
