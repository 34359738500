import C from 'classnames';
import * as React from 'react';
import { updatedChart } from './charts';
import { convertColorValue } from './colorShading';
import { TestingProps } from '../../Types/Testing';

const ChartJS = updatedChart();
// can't apply classes in here, need to pass colors straight into chart
// need to work out how to include these colors from scss. I think it's possible.
const colorMapper: { [index: string]: string } = {
  primary: '#2c7be5',
  secondary: '#1e3a5c',
  success: '#00d97e',
  info: '#39afd1',
  warning: '#f6c343',
  danger: '#e63757',
  light: '#edf2f9',
  dark: '#12263f',
  white: '#ffffff',
};

interface IChartProps extends TestingProps {
  className?: string;
  type?:
    | 'bar'
    | 'line'
    | 'radar'
    | 'doughnut'
    | 'pie'
    | 'polarArea'
    | 'bubble'
    | 'scatter';
  labels?: string[];
  drawBackground?: boolean;
  drawPoints?: boolean;
  drawBorder?: boolean;
  dataSets: {
    shaded?: boolean;
    color?: string;
    label?: string;
    data: number[] | { x: number; y: number; r: number }[];
  }[];
}

export const Chart: React.FunctionComponent<IChartProps> = ({
  type = 'bar',
  labels,
  drawBackground = true,
  drawPoints,
  drawBorder,
  dataSets,
  className,
  testId,
}) => {
  let chartInstance;
  const chartInstanceRef = React.useRef(chartInstance);
  const chart = React.useRef<HTMLCanvasElement>();

  React.useEffect(() => {
    const generateNewChart = () => {
      const myChartRef = chart.current && chart.current.getContext('2d');
      let chartBackgroundColors: string[][] = [[]];

      // if someone doesnt pass in the right amount of labels,
      // instead of complaining, just add empty ones.
      let tempLabels = labels;

      dataSets.forEach(({ color = 'primary', shaded, data }, i) => {
        chartBackgroundColors[i] = [];

        //make sure there are enough tempLabels for the data.
        if (tempLabels && tempLabels.length < data.length) {
          const labelsLength = tempLabels.length;
          for (let j = 0; j < data.length - labelsLength; j++) {
            tempLabels.push('');
          }
        } else if (!tempLabels) {
          tempLabels = [];
          for (let j = 0; j < data.length; ++j) {
            tempLabels.push('');
          }
        }

        // lighten the color slightly for each new data to help differentiate
        if (shaded) {
          for (let j = 0; j < data.length; j++) {
            chartBackgroundColors[i].push(
              convertColorValue(
                (0.6 / tempLabels.length) * j,
                colorMapper[color]
                  ? colorMapper[color]
                  : color
                  ? color
                  : '#000000'
              ) || '#000000'
            );
          }
        } else {
          for (let j = 0; j < data.length; j++) {
            chartBackgroundColors[i].push(
              colorMapper[color] ? colorMapper[color] : color
            );
          }
        }
      });

      chartInstanceRef.current = new ChartJS(myChartRef, {
        type: type,
        data: {
          labels: tempLabels,

          //we dont need to add color or shaded passed in to each dataset to the actual chart data
          datasets: dataSets.map(({ color, shaded, ...rest }, i) => {
            return {
              ...rest,
              fill: drawBackground,
              backgroundColor: drawBackground && chartBackgroundColors[i],
              pointColor: drawPoints && chartBackgroundColors[i][0],
              borderColor: drawBorder && chartBackgroundColors[i][0],
              hoverBackgroundColor: drawBackground && chartBackgroundColors[i],
            };
          }),
        },
      });
    };

    const updateData = () => {
      const chartBackgroundColors: string[][] = [[]];
      // for each of the datasets passed in.
      dataSets.forEach((newDataset, i) => {
        chartBackgroundColors[i] = [];

        newDataset.data.forEach((data, j) => {
          //set each single data point in each data set.
          chartInstanceRef.current.data.datasets[i].data[j] = data;
        });

        // update the colors.

        if (newDataset.shaded) {
          for (let j = 0; j < newDataset.data.length; j++) {
            chartBackgroundColors[i].push(
              convertColorValue(
                (0.6 / newDataset.data.length) * j,
                newDataset.color && colorMapper[newDataset.color]
                  ? colorMapper[newDataset.color]
                  : newDataset.color
                  ? newDataset.color
                  : '#ffffff'
              ) || '#ffffff'
            );
          }
        } else {
          for (let j = 0; j < newDataset.data.length; j++) {
            chartBackgroundColors[i].push(
              newDataset.color && colorMapper[newDataset.color]
                ? colorMapper[newDataset.color]
                : newDataset.color
                ? newDataset.color
                : '#ffffff'
            ) || '#ffffff';
          }
        }

        chartInstanceRef.current.data.datasets[i].backgroundColor =
          (drawBackground && chartBackgroundColors[i]) || 'rgba(0,0,0,0)';
        chartInstanceRef.current.data.datasets[i].pointColor =
          (drawPoints && chartBackgroundColors[i][0]) || 'rgba(0,0,0,0)';
        chartInstanceRef.current.data.datasets[i].borderColor =
          (drawBorder && chartBackgroundColors[i][0]) || '#ffffff';
        chartInstanceRef.current.data.datasets[i].hoverBackgroundColor =
          (drawBackground && chartBackgroundColors[i]) || 'rgba(0,0,0,0)';
      });

      // update the labels
      labels &&
        labels.forEach((label, i) => {
          chartInstanceRef.current.data.labels[i] = label;
        });
      chartInstanceRef.current.update();
    };

    if (chartInstanceRef.current) {
      if (type === chartInstanceRef.current.config.type) {
        updateData();
      } else {
        chartInstanceRef.current.destroy();
        generateNewChart();
      }
    }
  }, [dataSets, type, drawBackground, drawBorder, drawPoints]);

  React.useEffect(() => {
    const myChartRef = chart.current && chart.current.getContext('2d');
    const chartBackgroundColors: string[][] = [[]];

    // if someone doesnt pass in the right amount of labels,
    // instead of complaining, just add empty ones.
    let tempLabels = labels;

    dataSets.forEach(({ color = 'primary', shaded, data }, i) => {
      chartBackgroundColors[i] = [];

      //make sure there are enough tempLabels for the data.
      if (tempLabels && tempLabels.length < data.length) {
        const labelsLength = tempLabels.length;
        for (let j = 0; j < data.length - labelsLength; j++) {
          tempLabels.push('');
        }
      } else if (!tempLabels) {
        tempLabels = [];
        for (let j = 0; j < data.length; ++j) {
          tempLabels.push('');
        }
      }

      // lighten the color slightly for each new data to help differentiate
      if (shaded) {
        for (let j = 0; j < data.length; j++) {
          chartBackgroundColors[i].push(
            convertColorValue(
              (0.6 / tempLabels.length) * j,
              color && colorMapper[color]
                ? colorMapper[color]
                : color
                ? color
                : '#000000'
            ) || '#000000'
          );
        }
      } else {
        for (let j = 0; j < data.length; j++) {
          chartBackgroundColors[i].push(
            color && colorMapper[color]
              ? colorMapper[color]
              : color
              ? color
              : '#000000'
          );
        }
      }
    });

    chartInstanceRef.current = new ChartJS(myChartRef, {
      type: type,
      data: {
        labels: tempLabels,

        //we dont need to add color or shaded passed in to each dataset to the actual chart data
        datasets: dataSets.map(({ color, shaded, ...rest }, i) => {
          return {
            ...rest,
            fill: drawBackground,
            backgroundColor: drawBackground && chartBackgroundColors[i],
            pointColor: drawPoints && chartBackgroundColors[i][0],
            borderColor:
              (drawBorder && chartBackgroundColors[i][0]) || '#ffffff',
            hoverBackgroundColor: drawBackground && chartBackgroundColors[i],
          };
        }),
      },
    });
  }, []);

  return (
    <div className={C('chart')} data-testid={testId}>
      <canvas
        className={C('canvas-chart')}
        id="myChart"
        ref={chart}
        data-testid={testId + '-canvas'}
      />
    </div>
  );
};
