import { useEffect, useState } from 'react';

/**
 * Use this hook to subscribe to changes in the current window's focus.
 * For example, you can use this to pause realtime syncing when the window is out of focus.
 */
export function useHasWindowFocus(
  /**
   * Callback for when focus changes, that isn't called for the initial value
   */
  onChange?: (focussed: boolean) => void
) {
  // get the initial state
  const [focus, setFocus] = useState(document.hasFocus());
  let cancelled = false;
  useEffect(() => {
    // helper functions to update the status
    const onFocus = () => {
      if (cancelled) return;
      setFocus(true);
      onChange?.(true);
    };
    const onBlur = () => {
      if (cancelled) return;
      setFocus(false);
      onChange?.(false);
    };

    // assign the listener
    // update the status on the event
    window.addEventListener('focus', onFocus);
    window.addEventListener('blur', onBlur);

    // remove the listener
    return () => {
      cancelled = true;
      window.removeEventListener('focus', onFocus);
      window.removeEventListener('blur', onBlur);
    };
  }, []);

  // return the status
  return focus;
}
