import { getAccessToken } from '../client';
import { getApiv14Url } from './apiv14Setup';
export async function tts(text: string): Promise<ReadableStream> {
  const res = await fetch(
    `${getApiv14Url()}/tts?text=${encodeURIComponent(text)}&voiceId=Joanna`,
    {
      headers: {
        Accept: 'application/octet-stream',
        Authorization: `Bearer ${getAccessToken()}`,
      },
      method: 'GET',
    }
  );
  if (!res.ok) {
    console.error(res);
    throw Error('Fetch failed. See logged error.');
  }
  return res.body;
}
