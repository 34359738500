import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GetPublishersThunkAction } from './thunk';
import { PublishersState } from './types';

const initialState: PublishersState = {
  publishers: [],
};

const name = 'publishers';

const asyncActions = {
  getPublishers: createAsyncThunk(
    `${name}/getPublishers`,
    GetPublishersThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setPublishers(state, action: PayloadAction<string[]>) {
      state.publishers = action.payload;
    },
    addPublishers(state, action: PayloadAction<string[]>) {
      state.publishers.push(...action.payload);
    },
  },
  extraReducers: (builder) => {
    builder.addCase(asyncActions.getPublishers.fulfilled, (state, action) => {
      state.publishers = action.payload;
    });
    builder.addCase(asyncActions.getPublishers.rejected, (state) => {
      state.publishers = [];
    });
  },
});

const { actions, reducer } = slice;

export const publishersReducer = reducer;

export const publishersActions = { ...actions, asyncActions };
