import { UserState } from '@readcloud/data';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetResellerApiKeysThunkAction,
  GetUserScopeParamsThunkAction,
  UpdateAuthThunkAction,
} from './thunk';
import { AuthState } from './types';

const initialState: AuthState = {
  user: {
    access_token: null,
    refresh_token: null,
    permissions: [],
    clientId: null,
    id: null,
    email: null,
    role: null,
    firstName: null,
    lastName: null,
    institution: null,
    scope: null,
  },
  scopeParams: null,
  userManager: {},
  permissions: {},
};

const name = 'auth';

const asyncActions = {
  getResellerAPIKey: createAsyncThunk(
    `${name}/getResellerAPIKey`,
    GetResellerApiKeysThunkAction
  ),
  updateAuth: createAsyncThunk(`${name}/updateAuth`, UpdateAuthThunkAction),
  getUserScopeParams: createAsyncThunk(
    `${name}/getUserScopeParams`,
    GetUserScopeParamsThunkAction
  ),
};

const slice = createSlice({
  name,
  initialState,
  reducers: {
    setUser(state, action: PayloadAction<UserState>) {
      state.user = action.payload;
    },
    setAccessToken(state, action: PayloadAction<string>) {
      state.user.access_token = action.payload as string;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      asyncActions.getResellerAPIKey.fulfilled,
      (state, action) => {
        state.reseller = action.payload.resellerApiKey;
      }
    );
    builder.addCase(asyncActions.updateAuth.fulfilled, (state, action) => {
      state.user = { ...action.payload.user, id: action.payload.user.userId };
      state.permissions = action.payload.permissions;
      state.resellerId = action.payload.resellerId;
      state.institution = action.payload.institution;
    });
    builder.addCase(
      asyncActions.getUserScopeParams.fulfilled,
      (state, action) => {
        state.scopeParams = action.payload;
      }
    );
  },
});

const { actions, reducer } = slice;

export const authReducer = reducer;

export const authActions = { ...actions, asyncActions };
